import '../../../../../components/Platform/Table/style.css'

function TableAccess({tableAccessCell}) {
    const Cell = ({cell}) => {
        return (
            <tr>
                <td width="25%">{cell.user_name}</td>
                <td width="25%">{cell.logged_in}</td>
                <td width="25%">{cell.browser}</td>
                <td width="25%">{cell.user_ip}</td>
            </tr>
        )
    }

    return (
        <div className='admin_table_inside'>

            {(tableAccessCell.length === 0) ?
                <p>Nenhum histórico de acessos até o momento!</p>
            :
                <table>
                    <thead>
                        <tr>
                            <th>Usuário(a)</th>
                            <th>Logou em</th>
                            <th>Navegador</th>
                            <th>Ip</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {Object.keys(tableAccessCell).map((keyName, i) => {
                            return(
                                <Cell key={keyName} cell={tableAccessCell[i]} />
                            )
                        })}
                    </tbody>
                </table>
            }

        </div>
    )
}

export default TableAccess