import { useEffect } from "react";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { CustomLoadingButtonOutlined } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined";
import Form from "../../../components/Form";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

function LaboratoryCourseNotAvailable () {
    const { setMetaTagsOfPage } = useContext(Context)
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
        setMetaTagsOfPage('Acesso bloqueado', 'Esse recurso não está disponível no plano Free!')
    }, [])

    function redirectTo(link) {
        if (link) {
            history.push(link)
        }
    }

    return (
        <div className="box_labor_all">
            <div className={`box_pg_contact box_contact`}>
                <div className="contact_content">
                    <div className="contact_inside">
                        <h1>Acesso bloqueado!</h1>
                        <p className="descp_sup_prof">Esse recurso não está disponível no plano <b>Free</b>.</p>
                    
                        <Form>
                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButtonOutlined
                                        onClick={() => redirectTo('/meu-perfil')}
                                        variant="outlined"
                                        size="medium"
                                        color="secondary"
                                    >
                                        <span>Escolher novo Plano</span>
                                    </CustomLoadingButtonOutlined>
                                </Grid>
                            </Grid>
                        </Form>
                    </div>
                </div>
            </div>              
        </div>
    )
}

export default LaboratoryCourseNotAvailable;