import './style.css'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';

function CardSimulatorAdd({onClickPm}) {
    return (
        <div className="card_simulator_add" onClick={() => onClickPm()}>
            <AddCardOutlinedIcon fontSize="large" sx={{marginBottom: '10px'}} />
            <span>Adicionar novo cartão</span>
        </div>
    )
}

export default CardSimulatorAdd;