
import './App.css'
import './ToastCustom.css'
import { BrowserRouter as Router } from "react-router-dom"
import Routes from './routes/Routes'
import { AuthProvider } from "./context/AuthContext"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";

function App() {
  
  // Estilização da paleta de cores do MUI MATERIAL DESIGN
  const theme = createTheme({
    // Override or create new styles, colors, palettes...
    palette: {
      primary: {
        main: '#111111', //var(--plat-one)
      },
      secondary: {
        main: 'rgba(1, 254, 135, 1)', //var(--first-color)
      },
      neutral: {
        main: 'rgba(255, 255, 255, .9)', //var(--plat-six)
      },
      fifthcolor: {
        main: '#faaf00' //var(plat-twelve)
      },
      sixthcolor: {
        main: 'rgba(255, 255, 255, .55)' //var(--plat-seven)
      }
    },
    
    // Override color of component <FormHelperText> when is error...
    components: {
      MuiFormHelperText: {
        styleOverrides: {
            root: {
                color: "rgba(237, 67, 55, .9)",
                fontSize: '13px'
            }
        }
      }
    },

    //Override tooltip zindex
    zIndex: { tooltip: 99999 },
  });

  return (
    <Router>
      <ToastContainer autoClose={3000} position="top-right"/>
      
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes/> 
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
