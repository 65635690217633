import { useParams } from 'react-router-dom';
import { useEffect } from 'react'
import UsuariosForm from '../Usuarios/UsuariosForm';
import PerfisForm from "../Perfis/PerfisForm";
import NewsletterForm from "../NewsLetter/NewsletterForm";
import NewsletterArticlesForm from "../NewsLetterArticles/NewsletterArticlesForm";
import SupportForm from "../Support/SupportForm";
import AlertsForm from "../Alerts/AlertsForm";
import CategorysForm from "../Categorys/CategorysForm";
import SubcategorysForm from "../Subcategorys/SubcategorysForm";
import PublicationsForm from "../Publicacoes/PublicationsForm";
import EnqueteForm from "../Enquete/EnqueteForm";
import PlansForm from "../Plans/PlansForm";
import NotificationsForm from "../Notifications/NotificationsForm";
import CategorysWebsiteForm from "../CategorysWebsite/CategorysWebsiteForm";
import SubcategorysWebsiteForm from "../SubcategorysWebsite/SubcategorysWebsiteForm";
import ProfessoresForm from "../Professores/ProfessoresForm";
import CursosForm from "../Cursos/CursosForm";
import AulasForm from "../Aulas/AulasForm";
import DesafiosForm from '../Desafios/DesafiosForm';
import TopicosForm from '../ForumTopicos/TopicosForm';

function AdminFormTemplate(props) {
    const {pageSlug, regId} = useParams()

    useEffect(() => {
        // setSpinLoadChangeToOtherArticle(true)
        // loadArticle()
        
        window.scrollTo(0, 0)
    }, [pageSlug])

    return (
        <div className='admin_content_center'>
            
            {pageSlug === 'professores' &&
                <ProfessoresForm regId={regId}/>
            }

            {pageSlug === 'alunos' &&
                <UsuariosForm regId={regId}/>
            }

            {pageSlug === 'perfis' &&
                <PerfisForm regId={regId}/>
            }

            {pageSlug === 'newsletter' &&
                <NewsletterForm regId={regId}/>
            }

            {pageSlug === 'newsletter-articles' &&
                <NewsletterArticlesForm regId={regId}/>
            }

            {pageSlug === 'suporte' &&
                <SupportForm regId={regId}/>
            }

            {pageSlug === 'avisos' &&
                <AlertsForm regId={regId}/>
            }

            {pageSlug === 'categorias' &&
                <CategorysForm regId={regId}/>
            }

            {pageSlug === 'subcategorias' &&
                <SubcategorysForm regId={regId}/>
            }

            {pageSlug === 'publicacoes' &&
                <PublicationsForm regId={regId}/>
            }

            {pageSlug === 'enquetes' &&
                <EnqueteForm regId={regId}/>
            }

            {pageSlug === 'planos' &&
                <PlansForm regId={regId}/>
            }

            {pageSlug === 'notificacoes' &&
                <NotificationsForm regId={regId}/>
            }

            {pageSlug === 'categorias-website' &&
                <CategorysWebsiteForm regId={regId}/>
            }

            {pageSlug === 'subcategorias-website' &&
                <SubcategorysWebsiteForm regId={regId}/>
            }

            {pageSlug === 'cursos' &&
                <CursosForm regId={regId}/>
            }

            {pageSlug === 'aulas' &&
                <AulasForm regId={regId}/>
            }

            {pageSlug === 'desafios' &&
                <DesafiosForm regId={regId}/>
            }

            {pageSlug === 'topicos' &&
                <TopicosForm regId={regId}/>
            }
            
        </div>
    )
}

export default AdminFormTemplate