import '../../style.css'
import './style.css'

function FormLoader() {
    return (
        <div className="placeholder">
            <div className='line_loader'>
                <div style={{width:"100%"}}>
                    {/* Formulario campos */}
                    <div className='form_loader pulse'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormLoader;