import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';
import { CustomMuiColorInput } from '../../../components/MaterialCustom/Laboratory/TextField/CustomMuiColorInput';

function SubcategorysForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)

    const [formSubcategorys, setFormSubcategorys] = useState({
        name: '',
        status: '',
        description: '',
        blog_category_id: '',
        bg_color: '#000000'
    })

    const [fieldsError, setFieldsError] = useState({
        name: '',
        status: '',
        description: '',
        blog_category_id: '',
        bg_color: ''
    })

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const [categorysOptions, setCategorysOptions] = useState([])
    const [tableHistrcCell, setTableHistrcCell] = useState([])

    const title = useRef({
        name: 'Subcategorias',
        description_insert: 'Insira novas subcategorias no blog',
        description_update: 'Altere subcategorias do blog',
        link_from: '/admin/subcategorias'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)

            api.get(`admin/subcategorias/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setFormSubcategorys(response.data.data.subcategory)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de alterações
            api.get(`admin/subcategorias/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca categorias do blog para por no select>options
                api.get('admin/subcategorias/categorias')
                    .then((response) => {
                        if (response.data.status === 'success') {
                            setCategorysOptions(response.data.data.categorys)
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            handleLogout()
                        }
                    })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de inserções/remoções
            api.get('admin/subcategorias/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca categorias do blog para por no select>options
            api.get('admin/subcategorias/categorias')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCategorysOptions(response.data.data.categorys)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setName(e) {
        setFormSubcategorys({...formSubcategorys, name: e.target.value})
    }

    function setStatus(e) {
        setFormSubcategorys({...formSubcategorys, status: e.target.value})
    }

    function setDescription(e) {
        setFormSubcategorys({...formSubcategorys, description: e.target.value})
    }

    function setColor(color) {
        setFormSubcategorys({...formSubcategorys, bg_color: color})
    }

    function setBlogCategoryId(e) {
        setFormSubcategorys({...formSubcategorys, blog_category_id: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        await api.post('admin/subcategorias', formSubcategorys)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormSubcategorys({name: '', status: '', description: '', blog_category_id: '', bg_color: '#000000'})

                    history.push("/admin/subcategorias")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({name: '', status: '', description: '', blog_category_id: '', bg_color: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        await api.post(`admin/subcategorias/${regId}`, formSubcategorys)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/subcategorias")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({name: '', status: '', description: '', blog_category_id: '', bg_color: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(1)}  />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Nome"
                                                            onChange={setName}
                                                            value={formSubcategorys.name} 
                                                            name='name'
                                                            autoComplete='off'
                                                            {...(fieldsError.name && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 250 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.name}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Categoria"
                                                            {...(fieldsError.blog_category_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="blog_category_id"
                                                            onChange={setBlogCategoryId} 
                                                            value={formSubcategorys.blog_category_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {categorysOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.blog_category_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formSubcategorys.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomMuiColorInput 
                                                            required
                                                            label="Cor" 
                                                            value={formSubcategorys.bg_color} 
                                                            onChange={setColor} 
                                                            name="bg_color"
                                                            {...(fieldsError.bg_color && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ 
                                                                maxLength: 30
                                                            }}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.bg_color}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Descrição"
                                                            onChange={setDescription}
                                                            value={formSubcategorys.description} 
                                                            name='description'
                                                            autoComplete='off'
                                                            {...(fieldsError.description && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 500 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.description}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default SubcategorysForm;