import '../../style.css'
import './style.css'

function CategoryOrSubcategoryLoader() {
    const ArticleCategSubcat = () => {
        return (
            <div className='loader_articles_b'>
                <div className='loader_article_3 pulse'>
                    <div className="loader_mini_rectangle_cat"></div>
                </div>

                <div className='loader_article_4'>
                    <div className='pulse' style={{
                        display:"flex", justifyContent:"flex-start", alignItems:"center"
                    }}>
                        <div className='loader_title_b'></div>
                    </div>
            
                    <div className='pulse'>
                        <div className='loader_description_c'></div>
                        <div className='loader_description_d'></div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='pulse' style={{width:"100%", height:"30px", marginBottom:"50px"}}>
                    <div style={{height:"30px", borderRadius:"8px"}}></div>
                </div>

                <ArticleCategSubcat />                
                <ArticleCategSubcat />  
                <ArticleCategSubcat />  
                <ArticleCategSubcat />  
                <ArticleCategSubcat />  
                <ArticleCategSubcat />  
                
            </div>
        </div>
    )
}

export default CategoryOrSubcategoryLoader;