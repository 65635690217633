import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Form from "../../../components/Form";
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { FormControl, FormHelperText, Grid, InputAdornment, IconButton } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useParams, useHistory } from "react-router-dom";
import { CustomTooltipDark } from "../../../components/MaterialCustom/General/Tooltip/CustomTooltip";
import { CustomTextField, inputLabelStyles } from "../../../components/MaterialCustom/Laboratory/TextField/CustomTextField";
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";

function ChangePassword () {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const {userKey} = useParams();
    const history = useHistory()
    
    const [formChangePass, setFormChangePass] = useState({
        forget_code_verification: '',
        password: '',
        new_password: '',
    })

    const [fieldsError, setFieldsError] = useState({
        forget_code_verification: '',
        password: '',
        new_password: '',
    })

    useEffect(() => {
        window.scrollTo(0, 0)

        setMetaTagsOfPage('Redefinir senha', 'Redefina sua senha de acesso!')
    }, [])

    function setForgetCodeVerification(e) {
        setFormChangePass({...formChangePass, forget_code_verification: e.target.value})
    }

    async function sendForm(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        await api.post(`redefinir-senha/${userKey}`, formChangePass)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormChangePass({forget_code_verification: '', password: '', new_password: ''})

                    history.push("/");
                } else {
                    toast.error(response.data.message)
                }

                setSpinnerBtnLoader(false)
                setFieldsError({forget_code_verification: '', password: '', new_password: ''})
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                }

                setSpinnerBtnLoader(false)
            })
    }

    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleClickShowPassword2() {
        setShowPassword2(!showPassword2)
    }

    function handleMouseDownPassword(event) {
        event.preventDefault();
    }

    return (
        <div className={`box_contact ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

            <div className="contact_content">
                <div className="contact_inside">
                    <h1>Redefinição de senha</h1>
                    <p className="descp_sup_prof">Redefina sua senha de acesso a plataforma.</p>
                
                    <Form>
                        <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl variant="standard" fullWidth>
                                    <CustomTextField
                                        required
                                        label="Código de verificação"
                                        onChange={setForgetCodeVerification}
                                        value={formChangePass.forget_code_verification} 
                                        name='forget_code_verification'
                                        {...(fieldsError.forget_code_verification && {error: 'true'})}
                                        size="medium"
                                        autoComplete='off'
                                        InputLabelProps={{
                                            sx: {
                                            ...inputLabelStyles,
                                            },
                                        }}
                                        fullWidth
                                        inputProps={{ maxLength: 5 }}
                                    />

                                    <FormHelperText>
                                        {fieldsError.forget_code_verification}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl variant="standard" fullWidth>
                                    <CustomTextField 
                                        id="outlined-adornment-password" 
                                        autoComplete='new-password'
                                        required
                                        type={showPassword ? 'text' : 'password'} 
                                        label="Nova senha"
                                        size="medium"
                                        {...(fieldsError.password && {error: 'true'})}
                                        name='password'
                                        fullWidth
                                        onChange={(e) => setFormChangePass({...formChangePass, password: e.target.value})} 
                                        value={formChangePass.password}
                                        InputLabelProps={{
                                            sx: {
                                            ...inputLabelStyles,
                                            },
                                        }}
                                        InputProps={{ 
                                            maxLength: 200,
                                            endAdornment:
                                                <>
                                                    <CustomTooltipDark
                                                        placement="top-end"
                                                        title={
                                                            <React.Fragment>
                                                                <span className="tooltiptext">
                                                                    <h6>A senha deve ter: </h6>

                                                                    <ul>
                                                                        <li>no mínimo 6 caracteres</li>
                                                                        <li>no mínimo um número</li>
                                                                        <li>letras maiúsculas e minúsculas</li>
                                                                        <li>caractere especial</li>
                                                                    </ul>
                                                                </span>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <IconButton>
                                                            <NotListedLocationOutlinedIcon color="sixthcolor" />
                                                        </IconButton>
                                                    </CustomTooltipDark>

                                                    <InputAdornment position="end">
                                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                            {showPassword ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                </>
                                        }}
                                    />

                                    <FormHelperText>
                                        {fieldsError.password}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl variant="standard" fullWidth>
                                    <CustomTextField 
                                        id="outlined-adornment-password2" 
                                        autoComplete='off'
                                        type={showPassword2 ? 'text' : 'password'} 
                                        label="Repita a nova senha"
                                        size="medium"
                                        {...(fieldsError.new_password && {error: 'true'})}
                                        fullWidth
                                        name='new_password'
                                        onChange={(e) => setFormChangePass({...formChangePass, new_password: e.target.value})} 
                                        value={formChangePass.new_password}
                                        InputLabelProps={{
                                            sx: {
                                            ...inputLabelStyles,
                                            },
                                        }}
                                        InputProps={{ 
                                            maxLength: 200,
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword} edge="end">
                                                        {showPassword2 ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            
                                        }}
                                    />

                                    <FormHelperText>
                                        {fieldsError.new_password}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={sendForm}
                                    endIcon={<SendOutlinedIcon />}
                                    loading={spinnerBtnLoader}
                                    loadingPosition="end"
                                    variant="contained"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Alterar Senha</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                </div>

            </div>
        </div>
    )
}

export default ChangePassword;