import '../../style.css'

function TableLoader() {
    return (
        <div className="placeholder">
            <div className='line_loader'>
                <div style={{width:"100%"}}>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"flex-start", marginBottom:"5px"}}>
                        {/* Canto Superior Esquerdo */}
                        <div className='pulse'>
                            <div style={{width:"80px", height:'18px', marginBottom:'10px'}}></div>
                            <div style={{width:"180px", height:'32px', marginBottom:'5px'}}></div>
                            <div style={{width:"280px", height:'19px', marginBottom:'35px'}}></div>
                        </div>
                        
                        {/* Canto Superior Direito */}
                        <div className='pulse'>
                            <div style={{width:"95px", height:'41px', borderRadius:"3px"}}></div>
                        </div>  
                    </div>

                    {/* Tabela */}
                    <div className='pulse'>
                        <div style={{width:"100%", height:'400px', borderRadius:"3px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableLoader;