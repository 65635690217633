import React, { useEffect, useContext, useState } from 'react'
import { useTheme } from '@mui/material/styles';
// import { useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../assets/imgs/logos/logoeduteka.png'
import { Context } from "../../../context/AuthContext";
import api from '../../../services/api';
import MenuAdminLoader from "../../Loaders/Divs/Platform/MenuAdminLoader";
import { ListSubheader, Typography } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import NavBarProfileAndNotification from '../../MaterialCustom/NavBarProfileAndNotification';
import { AppBar, Drawer, DrawerHeader } from '../../MaterialCustom/Platform/Drawer/CustomDrawer';

function NavBarPlataform({children}) {
    const { handleLogout } = useContext(Context)
    const [menuAllAdmin, setMenuAllAdmin] = useState([]);
    const [menuPlatformAdmin, setMenuPlatformAdmin] = useState([]);
    const [menuWebsiteAdmin, setMenuWebsiteAdmin] = useState([]);
    const [menuBlogAdmin, setMenuBlogAdmin] = useState([]);
    const [menuAdminLoarder, setMenuAdminLoarder] = useState(false);
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = React.useState(false);
    const history = useHistory()

    useEffect(() => {
        async function loadMenu() {
            setMenuAdminLoarder(true)
            
            await api.get(`admin/menu`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setMenuAdminLoarder(false)
                        setMenuAllAdmin(response.data.data.menu_all)
                        setMenuPlatformAdmin(response.data.data.menu_platform)
                        setMenuWebsiteAdmin(response.data.data.menu_website)
                        setMenuBlogAdmin(response.data.data.menu_blog)
                    } else if (response.status === 403) {
                        history.push('/erro/403');
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    } else if (error.response.status === 403) {
                        history.push('/erro/403');
                    }
                })
        }

        loadMenu()
        window.scrollTo(0, 0)
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function redirectTo(linkUrl) {
        if (linkUrl) {
            history.push(linkUrl)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Área do navbar superior */}
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* <img className="logo" style={{width: "88px", cursor:"pointer"}} src={Logo} alt="Eduteka" title="Eduteka" onClick={() => redirectTo('/')} /> */}
                    
                    <Typography variant="overline" component="div" sx={{ flexGrow: 1 /*, marginLeft:"30px"*/ }}>
                        {/* {!isMobile ? 'Plataforma' : ''} */}
                        Gerenciador
                    </Typography>

                    <NavBarProfileAndNotification />
                </Toolbar>
            </AppBar>


            {/* Área do menu lateral esquerdo */}
            <Drawer 
                variant="permanent" 
                open={open}
                PaperProps={{
                    sx: { 
                        backgroundColor: "var(--plat-two)", 
                        color: "var(--plat-six)" 
                    }
                }}
            >
                <DrawerHeader sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <img className="logo" style={{width: "88px", cursor:"pointer", marginLeft:"14px"}} src={Logo} alt="Eduteka" title="Eduteka" onClick={() => redirectTo('/')} />

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon color='neutral' /> : <ChevronLeftIcon color='neutral' />}
                    </IconButton>
                </DrawerHeader>

                <Divider sx={{borderColor: "var(--plat-eight)"}} />

                <List>
                    {menuAdminLoarder ?
                        <MenuAdminLoader quantityItems={[1]} />
                    :
                        menuAllAdmin.map((item, index) => {
                            return (
                                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton 
                                        sx={{ 
                                            minHeight: 48, 
                                            justifyContent: open ? 'initial' : 'center', 
                                            px: 2.5,
                                            "&:hover": {
                                                backgroundColor: "var(--plat-four)",
                                            }
                                        }} 
                                        component={Link} 
                                        to={`/admin/${item.slug}`} 
                                        /*onClick={handleDrawerClose}*/
                                    > 
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                            <GridViewIcon titleAccess={item.title} color='fifthcolor' />
                                        </ListItemIcon>

                                        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>

                <Divider sx={{borderColor: "var(--plat-eight)"}} />

                <List subheader={
                    <ListSubheader component="div" sx={{background:'var(--plat-two)', color: 'var(--plat-twelve)'}}>
                        {open ? 'Administrativo' : ''}
                    </ListSubheader>
                }>
                    {menuAdminLoarder ?
                        <MenuAdminLoader quantityItems={[1, 2, 3, 4, 5, 6 ,7, 8, 9]} />
                    :
                        menuPlatformAdmin.map((item, index) => {
                            return (
                                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton 
                                        sx={{ 
                                            minHeight: 48, 
                                            justifyContent: open ? 'initial' : 'center', 
                                            px: 2.5,
                                            "&:hover": {
                                                backgroundColor: "var(--plat-four)",
                                            }
                                        }} 
                                        component={Link} 
                                        to={`/admin/${item.slug}`} 
                                        /*onClick={handleDrawerClose}*/
                                    > 
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                            {item.slug === 'professores' && <BadgeOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'alunos' && <PeopleAltOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'perfis' && <GroupsOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'avisos' && <FeedbackOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'suporte' && <SupportAgentIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'newsletter' && <RssFeedOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'newsletter-articles' && <RssFeedOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'notificacoes' && <NotificationsOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'planos' && <CardMembershipOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                        </ListItemIcon>

                                        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>

                <Divider sx={{borderColor: "var(--plat-eight)"}} />

                <List subheader={
                    <ListSubheader component="div" sx={{background:'var(--plat-two)', color: 'var(--plat-twelve)'}}>
                        {open ? 'Site/Laboratório' : ''}
                    </ListSubheader>
                }>
                    {menuAdminLoarder ?
                        <MenuAdminLoader quantityItems={[1, 2, 3, 4, 5, 6]} />
                    :
                        menuWebsiteAdmin.map((item, index) => {
                            return (
                                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton 
                                        sx={{ 
                                            minHeight: 48, 
                                            justifyContent: open ? 'initial' : 'center', 
                                            px: 2.5,
                                            "&:hover": {
                                                backgroundColor: "var(--plat-four)",
                                            }
                                        }} 
                                        component={Link} 
                                        to={`/admin/${item.slug}`} 
                                        /*onClick={handleDrawerClose}*/
                                    > 
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                            {item.slug === 'categorias-website' && <AccountTreeOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'subcategorias-website' && <AccountTreeOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'cursos' && <SchoolOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'aulas' && <OndemandVideoOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'desafios' && <QuizOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'topicos' && <TopicOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                        </ListItemIcon>

                                        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>

                <Divider sx={{borderColor: "var(--plat-eight)"}} />

                <List subheader={
                    <ListSubheader component="div" sx={{background:'var(--plat-two)', color: 'var(--plat-twelve)'}}>
                        {open ? 'Blog' : ''}
                    </ListSubheader>
                }>
                    {menuAdminLoarder ?
                        <MenuAdminLoader quantityItems={[1, 2, 3, 4]} />
                    :
                        menuBlogAdmin.map((item, index) => {
                            return (
                                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton 
                                        sx={{ 
                                            minHeight: 48, 
                                            justifyContent: open ? 'initial' : 'center', 
                                            px: 2.5,
                                            "&:hover": {
                                                backgroundColor: "var(--plat-four)",
                                            }
                                        }} 
                                        component={Link} 
                                        to={`/admin/${item.slug}`} 
                                        /*onClick={handleDrawerClose}*/
                                    > 
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                            {item.slug === 'categorias' && <AccountTreeOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'subcategorias' && <AccountTreeOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'publicacoes' && <CreateOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                            {item.slug === 'enquetes' && <QuizOutlinedIcon titleAccess={item.title} color='fifthcolor'/>}
                                        </ListItemIcon>

                                        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Drawer>


            {/* Área do conteúdo do Gerenciar plataforma */}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />

                {children}
            </Box>
        </Box>
    )
}

export default NavBarPlataform;