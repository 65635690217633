import './style.css'
import { Context } from '../../../context/AuthContext'
import { useContext, useState, useEffect, useRef } from 'react'
import ModalForgetPassword from '../../../components/Modal/General/ModalForgetPassword'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Grid, FormControl } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login';
import Form from '../../../components/Form'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/imgs/logos/logoeduteka.png'
import { CustomLoadingButton } from '../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function LaboratoryLogin({}) {
    // const [modalCourseDtlsStatus, setModalCourseDtlsStatus] = useState(false)
    const { setMetaTagsOfPage, handleLogin, loading, authenticated } = useContext(Context)
    const [modalForgetPassStatus, setModalForgetPassStatus] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const history = useHistory()

    let btnLogin = useRef()

    const [userLogin, setUserLogin] = useState({
        username: '',
        password: ''
    })

    useEffect(() => {
        setMetaTagsOfPage('Laboratório', 'Faça login no laboratório da Eduteka!')

        //se já estiver autenticado redireciona pra home do laboratório
        if (authenticated) {
            history.push(`/home`)
        }
    }, []);

    useEffect(() => {
        const keyDownHandler = event => {
            // console.log('User pressed: ', event.key);

            if (event.key === 'Enter') {
                event.preventDefault();

                //Clica no botão de login ao pressionar ENTER
                btnLogin.current.click()
            }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    function openModalForget() {
        setModalForgetPassStatus(!modalForgetPassStatus)
    }

    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleMouseDownPassword(event) {
        event.preventDefault();
    }

    function redirectTo(url) {
        if (url) {
            history.push(url)
        } else {
            history.push(`/home`)
        }
    }

    return (
        <div className="course_box_login">
            <div className='crs_box_logt'>
                <img className="logo" onClick={() => redirectTo('/')} width={106} height={23.67} src={Logo} alt="Eduteka" title="Eduteka" />
            </div>

            <div className="crs_box_intro_lgn">
                <div className='crs_title_lgn'>
                    <SchoolOutlinedIcon color="neutral" sx={{marginRight:"12px"}} fontSize="large" />
                    <h3>Bora estudar?</h3> 
                </div>

                <Form>
                    <Grid container spacing={2} sx={{marginBottom:"20px"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl variant="outlined" fullWidth>
                                <CustomTextField
                                    label="E-mail" 
                                    onChange={(e) => setUserLogin({...userLogin, username: e.target.value})} 
                                    autoComplete='off'
                                    value={userLogin.username}
                                    name='name'
                                    size="medium"
                                    InputLabelProps={{
                                        sx: {
                                          ...inputLabelStyles,
                                        },
                                    }}
                                    // {...(fieldsError.name && {error: 'true'})}
                                    fullWidth
                                    inputProps={{ maxLength: 200 }}
                                />

                                {/* <FormHelperText>
                                    {fieldsError.name}
                                </FormHelperText> */}
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl variant="outlined" fullWidth> 
                                <CustomTextField 
                                    id="outlined-adornment-password4" 
                                    autoComplete='off'
                                    type={showPassword ? 'text' : 'password'} 
                                    label="Senha"
                                    size="medium"
                                    // {...(fieldsError.name && {error: 'true'})}
                                    fullWidth
                                    onChange={(e) => setUserLogin({...userLogin, password: e.target.value})} 
                                    value={userLogin.password}
                                    InputLabelProps={{
                                        sx: {
                                          ...inputLabelStyles,
                                        },
                                    }}
                                    InputProps={{ 
                                        maxLength: 200,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                    {showPassword ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                </IconButton>
                                            </InputAdornment>
                                        
                                    }}
                                />

                                {/* <FormHelperText>
                                    {fieldsError.name}
                                </FormHelperText> */}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Form>

                <div className="box_account_forget">
                    <span className="forget" onClick={() => redirectTo('/matricule-se')}>Assinar</span>
                    <span className="forget" onClick={() => openModalForget()}>Esqueceu sua senha?</span>
                </div>

                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomLoadingButton
                            id="send-btn-login"
                            ref={btnLogin}
                            onClick={(event) => handleLogin(event, userLogin, redirectTo)}
                            endIcon={<LoginIcon />}
                            loading={loading}
                            size="large"
                            loadingPosition="end"
                            variant="contained"
                            color="secondary"
                            fullWidth
                        >
                            <b>Entrar</b>
                        </CustomLoadingButton>
                    </Grid>
                </Grid>
            </div>

            <ModalForgetPassword modalStatus={modalForgetPassStatus} setModalStatus={setModalForgetPassStatus}/>
        </div>
    )
}

export default LaboratoryLogin;