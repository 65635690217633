import styled from "styled-components";
import Rating from '@mui/material/Rating';

const StyledRating = styled(Rating)({
  "& .MuiRating-icon": {
    color: "var(--plat-twelve)"
  },
  "& .MuiRating-iconFilled": {
    color: "var(--plat-twelve)",
  },
  "& .MuiRating-iconHover": {
    color: "var(--plat-twelve)",
  },
});

export { StyledRating }