import './style.css'
import { Context } from '../../../context/AuthContext'
import React, { useContext, useEffect } from 'react'
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Logo from '../../../assets/imgs/logos/logoeduteka.png'

function WebsiteConstruction () {
    const { setMetaTagsOfPage } = useContext(Context)

    useEffect(() => {
        setMetaTagsOfPage('Escola online de programação', 'Uma plataforma de ensino feita para programadores!')

        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="box_construction">
                <div className='construction_alert'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <img src={Logo} alt="Eduteka" title="Eduteka" />

                            <hr/>

                            <h1>Plataforma <span className='alert_important'>em construção!</span></h1>
                            <p>Enquanto estamos trabalhando na construção da plataforma, que tal ler alguns artigos sobre programação no nosso blog?</p>

                            <LoadingButton
                                onClick={() => {window.location.href = process.env.REACT_APP_URL_BASE_BLOG}}
                                variant="outlined"
                                color="secondary"
                                size="large"
                                sx={{
                                    width: '230px'
                                }}
                            >
                                <span>ACESSAR BLOG</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default WebsiteConstruction;