import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function DesafiosForm({regId, dark = true}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)

    const [formCourseQuiz, setFormCourseQuiz] = useState({
        question: '',
        points: '',
        posted_in: '',
        // course_quiz_id: '',
        status: '',
        course_id: ''
    })

    const [fieldsError, setFieldsError] = useState({
        question: '',
        points: '',
        posted_in: '',
        // course_quiz_id: '',
        status: '',
        course_id: ''
    })

    const [responses, setResponses] = useState([])

    const [tableHistrcCell, setTableHistrcCell] = useState([])
    const [coursesOptions, setCoursesOptions] = useState([])

    const idFakeDefaultValue = useRef(1);
    const responsesPendingAdd = useRef({
        id_fake: null, 
        item: null,
        is_right_answer: false
    });

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const [isRightAnswerOptions, setIsRightAnswerOptions] = useState([
        {value: false, name: 'Incorreta'},
        {value: true, name: 'Correta'}
    ])

    const title = useRef({
        name: 'Desafios',
        description_insert: 'Insira novos desafios na plataforma',
        description_update: 'Altere desafios da plataforma',
        link_from: '/admin/desafios'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)

            api.get(`admin/desafios/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setFormCourseQuiz(response.data.data.question)
                        setResponses(response.data.data.responses)
                        idFakeDefaultValue.current = response.data.data.responses.length + 1
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de inserções/remoções
            api.get(`admin/desafios/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca subcategorias do blog para por no select>options
            api.get(`admin/desafios/cursos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCoursesOptions(response.data.data.courses)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de alterações
            api.get('admin/desafios/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
            
            //Busca subcategorias do blog para por no select>options
            api.get('admin/desafios/cursos')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCoursesOptions(response.data.data.courses)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setQuestion(e) {
        setFormCourseQuiz({...formCourseQuiz, question: e.target.value})
    }

    function setPoints(e) {
        setFormCourseQuiz({...formCourseQuiz, points: e.target.value})
    }

    function setPostedIn(e) {
        setFormCourseQuiz({...formCourseQuiz, posted_in: e.target.value})
    }

    function setCourseId(e) {
        setFormCourseQuiz({...formCourseQuiz, course_id: e.target.value})
    }

    function setStatus(e) {
        setFormCourseQuiz({...formCourseQuiz, status: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData();
        formData.append('question', formCourseQuiz.question)
        formData.append('points', formCourseQuiz.points)
        formData.append('status', formCourseQuiz.status)
        formData.append('posted_in', formCourseQuiz.posted_in)
        formData.append('course_id', formCourseQuiz.course_id)

        formData.append('responses', JSON.stringify(responses))

        await api.post('admin/desafios', formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormCourseQuiz({question: '', points: '', posted_in: '', course_id: '', status: ''})

                    history.push("/admin/desafios")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({question: '', points: '', posted_in: '', course_id: '', status: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
        
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData();
        formData.append('question', formCourseQuiz.question)
        formData.append('points', formCourseQuiz.points)
        formData.append('status', formCourseQuiz.status)
        formData.append('posted_in', formCourseQuiz.posted_in)
        formData.append('course_id', formCourseQuiz.course_id)

        formData.append('responses', JSON.stringify(responses))

        await api.post(`admin/desafios/${regId}`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    history.push("/admin/desafios")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({question: '', points: '', posted_in: '', course_id: '', status: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    function addRowOnList() {
        // setResponses([...responses, {id_fake: 1, item: 'teste', is_right_answer: false}])
        if (!responsesPendingAdd.current.item) {
            toast.warning('Informe a resposta!')
            return
        }

        if (!responsesPendingAdd.current.is_right_answer && responsesPendingAdd.current.is_right_answer !== false) {
            toast.warning('Informe a alternativa!')
            return
        }

        setResponses([...responses, responsesPendingAdd.current])
        idFakeDefaultValue.current += 1
        responsesPendingAdd.current = {}
    }

    function rmvRowOnList(idFake) {
        idFakeDefaultValue.current -= 1

        //Remove a resposta em questão
        let newResponses = responses.filter((resp) => idFake !== resp.id_fake)

        //Refaz ordenação/numeração das respostas 
        let reorderId = 1;
        newResponses = newResponses.map((resp) => {
            resp.id_fake = reorderId
            reorderId++

            return resp
        })

        setResponses(newResponses)
    }

    function setResponsesPendingAdd(itemVal, isRightAnswerVal) {
        responsesPendingAdd.current = {
            id_fake: idFakeDefaultValue.current,
            item: itemVal,
            is_right_answer: isRightAnswerVal
        }
    } 

    function setResponsesItem(idFake, itemVal) {
        let newResponses = responses.map((resp) => {
            if (resp.id_fake === idFake) {
                resp.item = itemVal
            }

            return resp
        })

        setResponses(newResponses)
    }

    function setModulesStatus(idFake, isRightAnswerVal) {
        let newResponses = responses.map((resp) => {
            if (resp.id_fake === idFake) {
                resp.is_right_answer = isRightAnswerVal
            }

            return resp
        })

        setResponses(newResponses)
    }

    const ListSmartItem = ({isAddRow}) => {
        return (
            <div className="list_smart list_add">
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
                            <FormControl variant="standard" fullWidth>
                                <CustomTextField
                                    // required
                                    label="Identificador"

                                    //List ADD
                                    // {...(isAddRow && {onChange: (e) => setModulesPendingAdd(orderDefaultValue.current, modulesPendingAdd.current.description)})}
                                    {...(isAddRow && {value: idFakeDefaultValue.current})}

                                    disabled
                                    name={`response_id_fake`}
                                    // {...(fieldsError.name && {error: 'true'})}
                                    size="medium"
                                    fullWidth
                                    inputProps={{ type: 'number', maxLength: 11 }}

                                    InputLabelProps={{
                                        sx: {
                                        ...inputLabelStyles,
                                        },
                                    }}
                                />

                                <FormHelperText>
                                    {/* {fieldsError.name} */}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                            <FormControl variant="standard" fullWidth>
                                <CustomTextField
                                    required
                                    label="Resposta"

                                    //List ADD
                                    {...(isAddRow && {onChange: (e) => setResponsesPendingAdd(e.target.value, responsesPendingAdd.current.is_right_answer)})}
                                    {...(isAddRow && {value: responsesPendingAdd.current.item})}

                                    name={`resp_item`}
                                    // {...(fieldsError.name && {error: 'true'})}
                                    size="medium"
                                    fullWidth
                                    inputProps={{ maxLength: 350 }}

                                    InputLabelProps={{
                                        sx: {
                                        ...inputLabelStyles,
                                        },
                                    }}
                                />

                                <FormHelperText>
                                    {/* {fieldsError.name} */}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                            <FormControl variant="standard" fullWidth>
                                <CustomTextField
                                    required
                                    select
                                    sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                    label="Alternativa"
                                    // {...(fieldsError.status && {error: 'true'})}
                                    size="medium"
                                    fullWidth
                                    name={`resp_is_right_answer`}

                                   //List ADD
                                   {...(isAddRow && {onChange: (e) => setResponsesPendingAdd(responsesPendingAdd.current.item, e.target.value)})}
                                   {...(isAddRow && {value: responsesPendingAdd.current.is_right_answer})}

                                   InputLabelProps={{
                                    sx: {
                                    ...inputLabelStyles,
                                    },
                                }}
                                >
                                    {isRightAnswerOptions.map((stat, index) => (
                                        <MenuItem key={index} value={stat.value}>
                                            {stat.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>

                                <FormHelperText>
                                    {/* {fieldsError.status} */}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Form>

                <div className='box_icons_smart'>
                    <AddOutlinedIcon titleAccess='Adicionar' onClick={addRowOnList} color='fifthcolor' fontSize='large'/>
                </div>
            </div>
        )
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<StyleOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Respostas" {...a11yProps(1)}  />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(2)} />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Pergunta"
                                                            onChange={setQuestion}
                                                            value={formCourseQuiz.question} 
                                                            name='question'
                                                            autoComplete='off'
                                                            {...(fieldsError.question && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 350 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.question}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Curso"
                                                            {...(fieldsError.course_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="course_id"
                                                            onChange={setCourseId} 
                                                            value={formCourseQuiz.course_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {coursesOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.course_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formCourseQuiz.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Pontuação"
                                                            onChange={setPoints}
                                                            value={formCourseQuiz.points} 
                                                            name='points'
                                                            autoComplete='off'
                                                            {...(fieldsError.points && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ type: 'number', maxLength: 11 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.points}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Data de publicação"
                                                            type="datetime-local"
                                                            size="medium"
                                                            name="posted_in"
                                                            // defaultValue="2017-05-24"
                                                            {...(fieldsError.posted_in && {error: 'true'})}
                                                            onChange={setPostedIn}
                                                            value={formCourseQuiz.posted_in}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                sx: {
                                                                    ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.posted_in}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}> {/*Respostas*/}
                            <>
                                {!loaderTab2 ?
                                    <> 
                                        {/* RESULT ITEM */}
                                        {responses.map((resp, index) => {
                                            return (
                                                <div className="list_smart" key={index}>
                                                    <Form>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        // required
                                                                        label="Identificador"
                                    
                                                                        //List RESULT
                                                                        onChange={setResponsesPendingAdd}
                                                                        value={resp.id_fake}
                                    
                                                                        disabled
                                                                        name={`resp_id_fake_${resp.id_fake}`}
                                                                        // {...(fieldsError.name && {error: 'true'})}
                                                                        size="small"
                                                                        fullWidth
                                                                        inputProps={{ type: 'number', maxLength: 11 }}

                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                    
                                                                    <FormHelperText>
                                                                        {/* {fieldsError.name} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        label="Resposta"
                                    
                                                                        //List RESULT
                                                                        onChange={(e) => setResponsesItem(resp.id_fake, e.target.value)}
                                                                        value={resp.item}
                                    
                                                                        name={`resp_item_${resp.order}`}
                                                                        // {...(fieldsError.name && {error: 'true'})}
                                                                        size="small"
                                                                        fullWidth
                                                                        inputProps={{ maxLength: 350 }}

                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                    
                                                                    <FormHelperText>
                                                                        {/* {fieldsError.name} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        select
                                                                        sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                                        label="Alternativa"
                                                                        // {...(fieldsError.status && {error: 'true'})}
                                                                        size="small"
                                                                        fullWidth
                                                                        name={`resp_is_right_answer_${resp.is_right_answer}`}

                                                                        //List RESULT
                                                                        onChange={(e) => setModulesStatus(resp.id_fake, e.target.value)}
                                                                        value={resp.is_right_answer}
                                                                        // onChange={setStatus} 
                                                                        // value={formPerfis.status} 

                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {isRightAnswerOptions.map((stat, index) => (
                                                                            <MenuItem key={index} value={stat.value}>
                                                                                {stat.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </CustomTextField>

                                                                    <FormHelperText>
                                                                        {/* {fieldsError.status} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                    
                                                    <div className='box_icons_smart'>
                                                        <DeleteOutlinedIcon color='neutral' fontSize='large' titleAccess='Remover' onClick={() => rmvRowOnList(resp.id_fake)}/>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {/* ADD ITEM */}
                                        <ListSmartItem isAddRow={true}/>
                                    </>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp={dark === true ? "var(--plat-seven)" : ""} />
                </div>
            }
        </>
    )
}

export default DesafiosForm;