import '../style.css'

function TableHistoric({tableHistrcCell}) {
    const Cell = ({cell}) => {
        return (
            <tr>
                <td width="12%">{cell.changed_in}</td>
                <td width="8%">{cell.type}</td>
                <td width="12%">{cell.name}</td>
                <td width="34%">{cell.old_data}</td>
                <td width="34%">{cell.changed_to}</td>
            </tr>
        )
    }

    return (
        <div className='admin_table_inside'>

            {(tableHistrcCell.length === 0) ?
                <p>Nenhum histórico encontrado até o momento!</p>
            :
                <table>
                    <thead>
                        <tr>
                            <th>Alterado em</th>
                            <th>Operação</th>
                            <th>Usuário(a)</th>
                            <th>Era</th>
                            <th>Alterado para</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {Object.keys(tableHistrcCell).map((keyName, i) => {
                            return(
                                <Cell key={keyName} cell={tableHistrcCell[i]} />
                            )
                        })}
                    </tbody>
                </table>
            }

        </div>
    )
}

export default TableHistoric