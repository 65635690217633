import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';

function StepAlertSuccessfullyPaid ({userName, dark = true}) {
    return (
        <div className='payment_box_big_msg'>
            <div className='payment_box_message'>
                <h1>Parabéns {userName}, sua assinatura foi iniciada!</h1>
                <p>Acesse a área do aluno e aproveite todos os cursos agora mesmo.</p>

                <CustomLoadingButton
                    onClick={() => {window.location.href = `${process.env.REACT_APP_URL_BASE_LABORATORY}`}}
                    variant="contained"
                    fullWidth
                    color='secondary'
                    size="large"
                >
                    <span>Acessar área do aluno</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepAlertSuccessfullyPaid;