import React, { useContext, useState } from 'react'
import { Avatar, Badge, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LinkIcon from '@mui/icons-material/Link';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toast } from 'react-toastify';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SpinnerLoader from '../../Loaders/SpinnerLoader';
import { Context } from '../../../context/AuthContext'
import api from '../../../services/api';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';
import { useMediaQuery, useTheme } from '@material-ui/core';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function NavBarProfileAndNotification({}) {
    const { handleLogout, userPicture, notifications, setNotifications, notificationsNotViewedCount, setNotificationsNotViewedCount } = useContext(Context)
    const history = useHistory()
    const [menuProfile, setMenuProfile] = useState([])
    const [menuProfileLoader, setMenuProfileLoader] = useState(false);
    const [menuNotificationsLoader, setMenuNotificationsLoader] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorEl2, setAnchorEl2] = useState(null)
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    
    const DarkMode = {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        bgcolor: 'var(--plat-two)',
        color: 'var(--plat-six)',
        '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
        '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'var(--plat-two)', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 }
    }

    function doLogout() {
        handleLogout()
        setAnchorEl(null);
    }

    async function loadNotifications() {
        setMenuNotificationsLoader(true)
            
        await api.get(`admin/notificacoes/buscar`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setMenuNotificationsLoader(false)
                    setNotifications(response.data.data.notifications)
                    setNotificationsNotViewedCount(0)
                }
            })
            .catch((error) => {
                setMenuNotificationsLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    history.push('/erro/403');
                }
            })
    }

    async function loadMenuProfile() {
        setMenuProfileLoader(true)
            
        await api.get(`admin/menu-perfil`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setMenuProfileLoader(false)
                    setMenuProfile(response.data.data.menu)
                }
            })
            .catch((error) => {
                setMenuProfileLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    history.push('/erro/403');
                }
            })
    }

    async function hideNotification(notificationId) {
        await api.get(`admin/notificacoes/esconder/${notificationId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success('Notificação removida com sucesso!')

                    //Remove notificação no Front-End/State
                    setNotifications((notif) =>
                        notif.filter((item) => item.id !== notificationId)
                    );
                } else {
                    toast.error('Falha ao remover notificação!')
                }
            })
            .catch((error) => {
                toast.error('Falha ao remover notificação!')

                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    history.push('/erro/403');
                }
            })
    }

    const handleClickProfile = (event) => {
        setAnchorEl(event.currentTarget);
        loadMenuProfile()
    };

    const handleClickNotifications = (event) => {
        setAnchorEl2(event.currentTarget);
        loadNotifications()
    };

    const handleClose = (linkUrl = null) => {
        if (linkUrl) {
            history.push(linkUrl);
        }
        
        setAnchorEl(null);
    };

    const handleClose2 = (linkUrl = null) => {
        if (linkUrl) {
            history.push(linkUrl);
        }
        
        setAnchorEl2(null);
    };

    const openLinkInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const openLinkInSameTab = url => {
        window.location.href = url;
    };

    return (
        <div className='box_profile_lgn'>

            {/* Notificações - Menu notificações */}
            <div>
                <Tooltip title="Notificações" sx={{marginRight:"10px"}}>
                    <IconButton
                        onClick={handleClickNotifications}
                        size="small"
                        // sx={{ ml: 0 }}
                        aria-controls={open2 ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                    >
                        <Badge 
                            color="secondary" 
                            {...(notificationsNotViewedCount !== 0 && {badgeContent: notificationsNotViewedCount})}
                            max={9} 
                            anchorOrigin={{vertical: 'top', horizontal: 'left'}} 
                            // onClick={handleClick} 
                            aria-controls={open2 ? 'basic-menu' : undefined} 
                            aria-haspopup="true"
                            aria-expanded={open2 ? 'true' : undefined}
                        >
                            <NotificationsOutlinedIcon color="neutral" sx={{cursor:"pointer"}} fontSize="medium"/>
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    // onClick={handleClose2} // não fecha ao clicar nos itens
                    PaperProps={{
                        elevation: 0,
                        sx: DarkMode
                    }}
                    disableScrollLock={true}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <Typography 
                        variant="h6" 
                        gutterBottom 
                        color="fifthcolor.main"
                        sx={{ padding: "7.5px 15px", fontSize: "18px" }}
                    >
                        Notificações
                    </Typography>
                    
                    <Divider sx={{borderColor: "var(--plat-eight)"}} />

                    {menuNotificationsLoader ?
                        <SpinnerLoader borderColorImp="var(--plat-seven)" />
                    :
                        <>
                            <List sx={{ 
                                width: '100%', 
                                maxWidth: isMobile ? 290 : 330,
                                bgcolor: 'var(--plat-two)',
                                overflow: 'auto', 
                                maxHeight: isMobile ? 325 : 415 
                            }}>
                                {notifications.length > 0 ?
                                
                                    notifications.map((notification) => {
                                        return( 
                                            <>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar sx={{ minWidth:"50px" }}>
                                                        <Avatar sx={{backgroundColor: "transparent"}}>
                                                            {(parseInt(notification.type) === 1) ?
                                                                <NotificationsActiveOutlinedIcon color="fifthcolor" />
                                                            :
                                                                <CrisisAlertOutlinedIcon color="fifthcolor"/>
                                                            }
                                                            
                                                        </Avatar>
                                                    </ListItemAvatar>

                                                    <ListItemText
                                                        primary={
                                                            <div className="box_notiftit">
                                                                <h1 className='notiftitle dark'>{notification.title}</h1>

                                                                <DeleteOutlineIcon 
                                                                    sx={{cursor:"pointer"}} 
                                                                    color="neutral"
                                                                    titleAccess="Remover Notificação" 
                                                                    onClick={() => hideNotification(notification.id)}
                                                                />
                                                            </div>
                                                        }
                                                        secondary={
                                                            <>
                                                                <p className="notifsubtitle dark">{notification.message}</p>

                                                                {notification.link ?
                                                                    <div className='dtls_of_notification'>
                                                                        <LinkIcon 
                                                                            size="small" 
                                                                            sx={{cursor:"pointer"}} 
                                                                            color="neutral"
                                                                            titleAccess="Link" 
                                                                            onClick={() => openLinkInNewTab(notification.link)}
                                                                        />

                                                                        <p className="dark">{notification.notification_date}</p>
                                                                    </div>
                                                                :
                                                                    <div className='dtls_of_notification_block'>
                                                                        <p>{notification.notification_date}</p>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    />
                                                </ListItem>

                                                <Divider light variant="middle" sx={{borderColor: "var(--plat-eight)"}} />
                                            </>
                                        )
                                    })

                                :
                                    <ListItem alignItems="flex-start">
                                        <p className="notifsubtitle dark">Nenhuma notificação encontrada!</p>
                                    </ListItem>
                                }
                            </List>
                        </>
                    }
                </Menu>
            </div>
            


            {/* Foto de Perfil - Menu Perfil */}
            <div>
                <Tooltip title="Minha Conta">
                    <IconButton
                        onClick={handleClickProfile}
                        size="small"
                        // sx={{ ml: 0 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {userPicture ?
                            <div className='thumb_box_main'>
                                <img src={userPicture} alt={JSON.parse(localStorage?.getItem('user')).name} title={JSON.parse(localStorage?.getItem('user')).name}/>
                            </div>
                        :
                            <Avatar sx={{ width: 32, height: 32 }}>{(JSON.parse(localStorage?.getItem('user')).name).charAt(0).toUpperCase()}</Avatar>
                        }
                    </IconButton>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: DarkMode
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    disableScrollLock={true}
                >
                    <MenuItem onClick={handleClose}>
                        {userPicture ?
                            <div className='thumb_box'>
                                <img src={userPicture} alt={JSON.parse(localStorage?.getItem('user')).name} title={JSON.parse(localStorage?.getItem('user')).name}/>
                            </div>
                        :
                            <Avatar />
                        }
                        
                        <div className='user_description'>
                            <h6 className='dark'>{JSON.parse(localStorage?.getItem('user')).name}</h6>
                            <span className='dark'>Plano {JSON.parse(localStorage?.getItem('user')).plan}</span>
                        </div>
                    </MenuItem>
                    
                    <Divider sx={{borderColor: "var(--plat-eight)"}} />

                    {menuProfileLoader ?
                        <SpinnerLoader borderColorImp="var(--plat-seven)" />
                    :
                        <>
                            {menuProfile.map((item) => {
                                return (
                                    (item.link === '/admin/dashboard' &&
                                        <MenuItem onClick={() => handleClose(item.link)}>
                                            <ListItemIcon>
                                                <ManageAccountsOutlinedIcon fontSize="small" color='neutral' />
                                            </ListItemIcon>
                                            
                                            {item.title}
                                        </MenuItem>
                                    )
                                )
                            })}

                            <MenuItem onClick={() => openLinkInSameTab(process.env.REACT_APP_URL_BASE_LABORATORY)}>
                                <ListItemIcon>
                                    <SchoolOutlinedIcon fontSize="small" color='neutral' />
                                </ListItemIcon>
                                Área do aluno
                            </MenuItem> 

                            <MenuItem onClick={() => openLinkInSameTab(`${process.env.REACT_APP_URL_BASE_LABORATORY}cursos-favoritos`)}>
                                <ListItemIcon>
                                    <FavoriteBorderIcon fontSize="small" color='neutral' />
                                </ListItemIcon>
                                Cursos favoritos
                            </MenuItem> 

                            <MenuItem onClick={() => handleClose('/meu-perfil')}>
                                <ListItemIcon>
                                    <PersonOutlineOutlinedIcon fontSize="small" color='neutral' />
                                </ListItemIcon>
                                Meu perfil
                            </MenuItem>

                            <MenuItem onClick={() => handleClose('/meus-pontos')}>
                                <ListItemIcon>
                                    <EmojiEventsOutlinedIcon fontSize="small" color='neutral' />
                                </ListItemIcon>
                                Meus pontos
                            </MenuItem>

                            <MenuItem onClick={()=> doLogout()}>
                                <ListItemIcon>
                                    <Logout fontSize="small" color='neutral' />
                                </ListItemIcon>
                                Sair
                            </MenuItem>
                        </>
                    }
                </Menu>
            </div>
        </div>
    );
}

export default NavBarProfileAndNotification;