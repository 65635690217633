import { useState, useEffect, useRef } from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { CustomThumb } from '../../MaterialCustom/Laboratory/CourseItem/CustomThumb';

function RowCourseList({redirectTo, arrowsDisplay, rowTitle, rowId, courses, courseMouseHover, modalBlock, modalCourseDtlsStatus, setModalCourseDtlsStatus}) {
    const [screenWidth, setScreenWidth] = useState()

    let modalToVisibleTimeout = useRef(false);

    //Captura Elementos HTML
    let carousel = useRef('')
    let arrowLeft = useRef('')
    let arrowRight = useRef('')
    let crsGItem = useRef('')

    let isDragStart = useRef(false);
    let isDragging = useRef(false);
    let prevPageX = useRef();
    let prevScrollLeft = useRef();
    let positionDiff = useRef();

    useEffect(() => {
        // console.log('render component RowCourseList ' + rowId)
        
        resetArrows()
        window.addEventListener('resize', resetArrows);

        return () => {
            window.removeEventListener('resize', resetArrows);
        }
    }, [])

    const resetArrows = () => {
        let screenWidthVal = window.innerWidth;
        setScreenWidth(screenWidthVal)

        if (arrowLeft.current) {
            arrowLeft.current.style.display = 'none' //Esconde arrow left
        }

        if (arrowRight.current && courses?.length > 1) {
            arrowRight.current.style.display = 'flex' //Mostra arrow right
        }
    }

    useEffect(() => {
        if (arrowsDisplay) { //Mostra arrow left ou right (quando a modal thumb é fechada)

            if (arrowLeft.current) {
                if (carousel.current.scrollLeft === 0) {
                    arrowLeft.current.style.display = 'none'
                } else {
                    arrowLeft.current.style.display = 'flex'
                }
            }
    
            if (arrowRight.current && courses?.length > 1) {
                if (carousel.current.scrollWidth === carousel.current.clientWidth + carousel.current.scrollLeft) {
                    arrowRight.current.style.display = 'none'
                } else {
                    arrowRight.current.style.display = 'flex'
                }
            }

        } else { //Esconde arrow left e right (quando a modal thumb é aberta)
            if (arrowLeft.current && arrowRight.current) {
                arrowLeft.current.style.display = 'none'
                arrowRight.current.style.display = 'none'
            }
        }
    }, [arrowsDisplay])

    const showModalDetails = (e, course) => {
        // mando o event (elemento do curso clicado para a modal usar as infos)
        course.event = e
        courseMouseHover.current = course

        if (modalBlock.current === false) {
            // console.log('show', e.target)
            modalToVisibleTimeout.current = setTimeout(() => {
                setModalCourseDtlsStatus(true)
            }, 350)
        }

        modalBlock.current = true
    }

    const abortModalDetails = (e) => {
        // if (modalCourseDtlsStatus === false) {
            // console.log('abort')
            clearTimeout(modalToVisibleTimeout.current);
            modalBlock.current = false
        // }
    }

    // const hideModalDetails = (e) => {
    //     abortModalDetails(e)
    //     setModalCourseDtlsStatus(false)
    // }

    const dragStart = (e) => {
        isDragStart.current = true
        prevPageX.current = e.pageX || e.touches[0].pageX
        prevScrollLeft.current = carousel.current.scrollLeft
    }

    const dragging = (e) => {
        if (!isDragStart.current) {  // só faz scroll se clicou e arrastou
            return;
        }

        e.preventDefault()

        isDragging.current = true

        carousel.current.classList.add("dragging")
        let movePositionMouse = e.pageX || e.touches[0].pageX //Posição horizontal do mouse quando ele entra no elemento "row-group"

        positionDiff.current = movePositionMouse - prevPageX.current
        // carousel.current.scrollLeft = prevScrollLeft.current - positionDiff.current
    }

    const dragStop = (e) => {
        isDragStart.current = false
        carousel.current.classList.remove("dragging")

        if (!isDragging.current) {
            return;
        }

        isDragging.current = false
        autoSlide()
    }

    const autoSlide = (e) => {
        // let scrollWidth = carousel.current.scrollWidth - carousel.current.clientWidth
       
        // if (carousel.current.scrollLeft === scrollWidth) {
        //     return;
        // }
        
        // positionDiff.current = Math.abs(positionDiff.current)

        // let thumbSize = crsGItem.clientWidth + 20;
        // let valDifference = thumbSize - positionDiff.current

        if (positionDiff.current > 0) { //scroll pra esquerda
            handleArrowLeftClick(e)
            // carousel.current.scrollLeft += positionDiff.current > thumbSize / 3 ? valDifference : -positionDiff.current
        } else { //scroll pra direita
            handleArrowRightClick(e)
            // carousel.current.scrollLeft -= positionDiff.current > thumbSize / 3 ? valDifference : -positionDiff.current
        }
    }

    const handleArrowLeftClick = (e) => {
        setModalCourseDtlsStatus(false)
       
        let thumbSize = crsGItem.current.clientWidth + 2 + 20; //Tamanho do Thumb + 2px de borda + Margem
        carousel.current.scrollLeft -= thumbSize

        arrowRight.current.style.display = 'flex' //Mostra arrow right

        if (carousel.current.scrollLeft <= thumbSize) { //Esconde arrow left
            arrowLeft.current.style.display = 'none'
        }
    }

    const handleArrowRightClick = (e) => {
        setModalCourseDtlsStatus(false)

        let thumbSize = crsGItem.current.clientWidth + 2 + 20; //Tamanho do Thumb + 2px de borda + Margem
        carousel.current.scrollLeft += thumbSize

        let scrollWidth = carousel.current.scrollWidth - carousel.current.clientWidth

        arrowLeft.current.style.display = 'flex' //Mostra arrow left

        if (scrollWidth <= carousel.current.scrollLeft + thumbSize) {
            arrowRight.current.style.display = 'none'
        }
    }

    const forceCloseModal = (e) => {
        if (modalCourseDtlsStatus === true && modalBlock.current === true) {
            // console.log('force cancel')
            setTimeout(() => {
                setModalCourseDtlsStatus(false)
            }, 150)

            modalBlock.current = false
            // clearTimeout(modalToVisibleTimeout.current);
        }
    }

    return (
        <>
            <div className="row_course_list" onMouseMove={(e) => forceCloseModal(e)}> {/* Wrapper */}
                <h2>{rowTitle}</h2>

                <div className="row_group" ref={carousel} id={`row-group-carousel-${rowId}`} 
                    // DESKTOP
                    // onMouseDown={(e) => dragStart(e)} 
                    // onMouseUp={(e) => dragStop(e)} 
                    // onMouseLeave={(e) => dragStop(e)} 
                    // onMouseMove={(e) => dragging(e)}

                    // MOBILE
                    onTouchStart={(e) => dragStart(e)}
                    onTouchMove={(e) => dragging(e)}
                    onTouchEnd={(e) => dragStop(e)}
                > {/* Carousel */}

                    <div className='row_arrow_left' ref={arrowLeft} id={`row-arrow-left-${rowId}`} onClick={(e) => handleArrowLeftClick(e)}>
                        <ArrowBackIosOutlinedIcon color="fifthcolor" fontSize='large' />
                    </div>

                    {courses.map((course, index) => {
                        return (
                            <div key={index} ref={crsGItem} className='crs_g_item' draggable="false"
                                onMouseLeave={(e) => abortModalDetails(e)} 
                                onMouseOver={(e) => showModalDetails(e, course)}
                                onClick={() => redirectTo(
                                    courseMouseHover.current.available === true ? 
                                        `curso/${course.link_category}/${course.link_subcategory}/${course.slug}`
                                    :
                                        '/contratar-plano' 
                                )}
                            >
                                <div className='crs_container' style={{pointerEvents: 'none'}}>
                                    <CustomThumb className="item_thumb_inside" thumb={course.thumb} isAvailable={course.available}>
                                        {course.available === false &&
                                            <>
                                                <LockOutlinedIcon color='fifthcolor' fontSize='medium'/>
                                                <p className='blocked_plan_current'>Bloqueado</p>
                                            </>
                                        }
                                    </CustomThumb>

                                    <div className='item_thumb_outside'>
                                        <h3>Curso de {course.subcategory}</h3>
                                        <span>{course.description}</span>
                                        {/*<span>Aula {course.current_class}</span>*/}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {(
                        (screenWidth < 750 && courses?.length > 1) ||
                        ((screenWidth >= 750 && screenWidth < 990) && courses?.length > 2) ||
                        ((screenWidth >= 990 && screenWidth < 1350) && courses?.length > 3) ||
                        ((screenWidth >= 1350 && screenWidth < 1700) && courses?.length > 4) ||
                        ((screenWidth >= 1700 && screenWidth < 2200) && courses?.length > 5) ||
                        (screenWidth >= 2200 && courses?.length > 6)
                    ) && 
                        <div className='row_arrow_right' ref={arrowRight} id={`row-arrow-right-${rowId}`} onClick={(e) => handleArrowRightClick(e)}>
                            <ArrowForwardIosOutlinedIcon color="fifthcolor" fontSize='large'/>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default RowCourseList