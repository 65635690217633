import '../../../style.css'
import './style.css'

function CategoryOrSubcategoryLoader() {
    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='pulse loader_box_cat_subc'>
                    <div className='loader_crs_cat_title'></div>
                    <div className='loader_crs_cat_description'></div>
                </div>
                
            </div>
        </div>
    )
}

export default CategoryOrSubcategoryLoader;