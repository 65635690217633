import { Link } from "react-router-dom";
import Form from "../../Form";
import { useState, useEffect } from 'react';
import './style.css'
import api from "../../../services/api";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SidebarLoader from '../../Loaders/Divs/Blog/SidebarLoader'
import { CustomLoadingButtonOutlined } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined'

function Sidebar(props) {
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [spinnerLoaderBtn, setSpinnerLoaderBtn] = useState(false)
    const [articlesMoreViewed, setArticlesMoreViewed] = useState([])
    const [quiz, setQuiz] = useState([])
    const [quizResponses, setQuizResponses] = useState([])
    const [showQuizResults, setShowQuizResults] = useState(false)
    const [quizResults, setQuizResults] = useState([])
    const [quizVote, setQuizVote] = useState()
    const [blogSidebarLoader, setBlogSidebarLoader] = useState(true)

    useEffect(() => {
        function loadCategorys() {
            api.get('menu-sidebar')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setQuiz(response.data.data.quiz)
                        setArticlesMoreViewed(response.data.data.blog_articles_most_viewed)
                        setQuizResponses(response.data.data.quiz.responses)

                        setBlogSidebarLoader(false)
                    }
                }).catch((error) => {
                })
        }

        loadCategorys()
    }, [])

    function loadQuizResults(e) {
        e.preventDefault();
        setSpinnerLoader(true)

        api.get('blog/quiz')
            .then((response) => {
                if (response.data.status === 'success') {
                    setQuizResults(response.data.data.results)
                    setShowQuizResults(true)
                } else {
                    toast.error(response.data.data.message)
                }

                setSpinnerLoader(false)
            })
            .catch((error) => {
                toast.error(error.response.data.data.message)
                setSpinnerLoader(false)
            })
    }

    function closeQuizResults(e) {
        e.preventDefault()
        setShowQuizResults(false)
    }

    function setVote(e) {
        let voteValue = parseInt(e.target.value)
        setQuizVote(voteValue)
    }

    function sendVote(e) {
        e.preventDefault()

        setSpinnerLoaderBtn(true)

        api.post('blog/quiz', {vote:quizVote})
            .then((response) => {
                if (response.data.status === 'success') {
                    setQuizResults(response.data.data.results)
                    setShowQuizResults(true)

                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                setSpinnerLoaderBtn(false)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setSpinnerLoader(false)
                setSpinnerLoaderBtn(false)
            })
    }

    const LittleArticle = (props) => {
        return (
            <div className="little_article">
                <div className="lit_art_thumb">
                    <Link to={`/${props.link}`}>
                        {/* <img src={props.thumb} alt={props.title} title={props.title}/> */}
                        <div className='lit_article_bg_img' style={{backgroundImage: `url(${props.thumb})`}}></div>
                    </Link>
                </div>
                
                <div className="lit_art_title">
                    <h2>
                        <Link to={`/${props.link}`}>{props.title}</Link>
                    </h2>
                </div>
            </div>
        )
    }

    const QuizResponse = (props) => {
        return (
            <div className="quiz_opts">
                {!showQuizResults ? 
                    <FormControlLabel 
                        name='vote' 
                        value={props.id} 
                        onChange={setVote} 
                        control={<Radio
                            sx={{
                                '&, &.Mui-checked': {
                                    color: 'var(--plat-twelve)',
                                },
                            }}
                        />} 
                        label={props.item} 
                    />
                : 
                    <span className="opt-quiz">
                        {props.item} 

                        <span className="quiz-rsp-percent">
                            {quizResults.map((resp) => {
                                if (resp.id === props.id) {
                                    return ` (${resp.percent})`
                                }

                                return ''
                            })}
                        </span>
                    </span>
                }
            </div>
        )
    }
    
    return (
        <aside>
            <div className="box_merchan">
                <div className="box_merchandise">
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4035362438974963" crossOrigin="anonymous"></script>
                    <ins className="adsbygoogle" style={{display:'block'}} data-ad-client="ca-pub-4035362438974963" data-ad-slot="1527393339" data-ad-format="auto" data-full-width-responsive="true"></ins>
                    <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
                </div>
            </div>

            <div className="box_little_articles">
                <div className="quiz_tit_ic">
                    <RemoveRedEyeOutlinedIcon color="fifthcolor"/>
                    <h1>Mais vistos recentemente</h1>
                </div>
                
                {blogSidebarLoader ?
                    <SidebarLoader/>
                : 
                    articlesMoreViewed.map((item, index) => {
                        return <LittleArticle key={index} title={item.title} link={item.link} thumb={item.thumb}/>
                    })
                }
                
            </div>

            <div className="box_quiz_blog">
                {!blogSidebarLoader &&
                    <>
                        <div className="quiz_tit_ic">
                            <QuizOutlinedIcon color="fifthcolor"/>
                            <h1>Enquete</h1>
                        </div>
                        
                        <h2 data-id={quiz.id}>{quiz.question}</h2>

                        <Form>
                            {!spinnerLoader ?
                                <FormControl>
                                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="" name="radio-buttons-group">
                                        {quizResponses.map((rsp, index) => {
                                            return <QuizResponse key={index} id={rsp.id} item={rsp.item} />
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            :
                                <SpinnerLoader borderColorImp='var(--plat-seven)'/>
                            }
                            
                            <div className="quiz_results">
                                {!showQuizResults ?
                                    <>
                                        <Link to="/" className="res_quiz" onClick={(e) => loadQuizResults(e)}>Ver Resultados</Link> 
                                        <CustomLoadingButtonOutlined
                                            onClick={(e) => sendVote(e)}
                                            endIcon={<PsychologyAltOutlinedIcon />}
                                            loading={spinnerLoaderBtn}
                                            loadingPosition="end"
                                            color="secondary"
                                            sx={{width:"110px"}}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <span>Votar</span>
                                        </CustomLoadingButtonOutlined>
                                    </>
                                :
                                    <Link to="/" className="res_quiz" onClick={(e) => closeQuizResults(e)}>Voltar</Link> 
                                }
                            </div>
                        </Form>
                    </>
                }
            </div>
        </aside>
    )
}

export default Sidebar;