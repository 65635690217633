import {useState, useEffect} from "react"

export default function useMetaTags() {
    const [title, setTitle] = useState('Eduteka')
    const [description, setDescription] = useState('Plataforma completa para designers e programadores!')

    useEffect(() => {
        //Meta Title
        document.title = title

        //Meta Description
        const metaDescriptionElem = document.querySelector("meta[name='description']");
        metaDescriptionElem.setAttribute('content', description)
    }, [title, description])

    function setMetaTagsOfPage(metaTitle, metaDescription) {
        setTitle(`${metaTitle} | Eduteka`)
        // setTitle(`${metaTitle} | Eduteka`)
        setDescription(metaDescription)
    }

    return { setMetaTagsOfPage }
}