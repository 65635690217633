import '../../style.css'
import './style.css'

function SidebarLoader() {
    const SidebarArticle = () => {
        return (
            <div className='loader_sidebar_article pulse'>
                <div className="loader_side_rectangle"></div>
    
                <span className='loader_text_ret'>
                    <div className="loader_m_rectangle" style={{width:"100%", marginBottom:'8px', height:'20px'}}></div>
                    <div className="loader_m_rectangle" style={{width:"55%", height:'20px'}}></div>
                </span>
            </div>
        )
    }

    return (
        <div className="placeholder">
            <div className='line_loader'>

                <SidebarArticle/>
                <SidebarArticle/>
                <SidebarArticle/>

            </div>
        </div>
    )
}

export default SidebarLoader;