import './style.css'
import Logo from '../../../assets/imgs/logos/logoeduteka.png'
import { LoadingButton } from '@mui/lab';
import { Context } from '../../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import api from '../../../services/api';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import StepAlertHelloUser from '../../../components/PaymentStripeSteps/StepAlertHelloUser';
import StepShowPlans from '../../../components/PaymentStripeSteps/StepShowPlans';
import StepAlertPayment from '../../../components/PaymentStripeSteps/StepAlertPayment';
import StepReactivateDefaultPayment from '../../../components/PaymentStripeSteps/StepReactivateDefaultPayment';
import StepReactivateChangeDefaultPayment from '../../../components/PaymentStripeSteps/StepReactivateChangeDefaultPayment';
import StepAlertSuccessfullyPaid from '../../../components/PaymentStripeSteps/StepAlertSuccessfullyPaid';

function WebsiteReactivateSubscription() {
    const { setMetaTagsOfPage, handleLogout } = useContext(Context)
    const history = useHistory()
    const [plans, setPlans] = useState([])
    const [planSelected, setPlanSelected] = useState()
    const [defaultCard, setDefaultCard] = useState({})
    const [currentStep, setCurrentStep] = useState(1)

    //stripe
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)

    const [mainLoader, setMainLoader] = useState(true)

    function loadPublishableKey() {
        api.get(`/stripe/config`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setStripePromise(loadStripe(response.data.data.publishable_key))
                } else {
                    history.push("/erro/404")
                }
            })
            .catch((error) => {
                // if (error.response.status === 401) {
                //     handleLogout()
                // }
            })
    }

    function recreatePaymentIntent() {
        //Cria uma intenção de pagamento no servidor
        api.post('create-payment-intent-customer', {})
            .then((response) => {
                    if (response.data.status === 'success') {
                        // passing the client secret obtained from the server
                        setClientSecret(response.data.data.client_secret);
                    }
            }).catch((error) => {
                // if (error.response.status === 401) {
                //     handleLogout()
                // }
            })
    }
    //STRIPE FIM
    
    useEffect(() => {
        //Verifica se o usuário pode acessar essa tela, por que se estiver em dia com os pagamentos ou for professor ele não pode
        checkIfIsAllowed()

        setMetaTagsOfPage('Renove sua assinatura', 'Renove sua assinatura na Eduteka!')
        loadData()

        //Stripe
        loadPublishableKey()
        recreatePaymentIntent()
    }, [])

    async function checkIfIsAllowed() {
        await api.get('reactivate-permission')
            .then((response) => {
                if (response.data.status === 'success') {
                    setMainLoader(false)
                    return;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 301) {
                    redirectTo('/')
                }
            })
    }

    function redirectTo(linkUrl) {
        if (linkUrl) {
            history.push(linkUrl)
        }
    }

    async function loadData() {
        await api.get('plans')
            .then((response) => {
                if (response.data.status === 'success') {
                    setPlans(response.data.data.plans)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    const MainLoader = () => {
        return(
            <div className='payment_box_small_msg'>
                <div className='payment_box_message'>
                    <CircularProgress sx={{width:"34px !important", height:"34px !important", color: "var(--plat-seven)"}} />
                    <p style={{marginTop: '20px'}}>Carregando...</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='payment_header'>
                <div className='website_pay_header_container'>
                    {/* <Link to="/"> */}
                        <img className="logo" src={Logo} width={98} alt="Eduteka" title="Eduteka"/>
                    {/* </Link> */}

                    <LoadingButton
                        onClick={() => handleLogout()}
                        variant="outlined"
                        color="secondary"
                    >
                        <span>Sair</span>
                    </LoadingButton>
                </div>
            </div>

            {mainLoader ?
                <MainLoader />
            :
                <>
                    {currentStep === 1 &&
                        <StepAlertHelloUser 
                            stepNow={1} 
                            stepTotals={4} 
                            nextStep={() => setCurrentStep(2)}
                            userName={JSON.parse(localStorage?.getItem('user')).name.split(' ')[0]}
                        />
                    }

                    {currentStep === 2 &&
                        <StepShowPlans 
                            plans={plans}
                            setPlans={setPlans}
                            planSelected={planSelected}
                            setPlanSelected={setPlanSelected}
                            stepNow={2} 
                            stepTotals={4} 
                            nextStep={() => setCurrentStep(3)} 
                        />
                    }

                    {currentStep === 3 &&
                        <StepAlertPayment 
                            stepNow={3} 
                            stepTotals={4} 
                            typeMsg='reactivate'
                            setDefaultCard={setDefaultCard}
                            nextStep={() => setCurrentStep(4)} 
                        />
                    }

                    {currentStep === 4 &&
                        <StepReactivateDefaultPayment
                            stepNow={4} 
                            stepTotals={4} 
                            defaultCard={defaultCard}
                            plans={plans}
                            planSelected={planSelected}
                            setPlanSelected={setPlanSelected}
                            nextStep={() => setCurrentStep(6)}
                            changeDefaultPayStep={() => setCurrentStep(5)}
                        />
                    }

                    {currentStep === 5 &&
                        <StepReactivateChangeDefaultPayment 
                            backToBeforeStep={() => setCurrentStep(4)}
                            nextStep={() => setCurrentStep(6)}
                            clientSecret={clientSecret}
                            stripePromise={stripePromise}
                            // planSelected={planSelected}
                        />
                    }

                    {currentStep === 6 &&
                        <StepAlertSuccessfullyPaid
                            userName={JSON.parse(localStorage?.getItem('user')).name.split(' ')[0]}
                        />
                    }
                </>
            }
        </>
    )
}

export default WebsiteReactivateSubscription;