import axios from "axios";
import {getCookie, setCookie} from '../helpers'
// import { useContext } from "react";
// import { Context } from '../context/AuthContext'
// import { useEffect } from "react";

let baseUrlApi = process.env.REACT_APP_URL_BACKEND

const api = axios.create({
  baseURL: baseUrlApi,
  // withCredentials: true, //se precisar enviar cookies do front pro back
});

let isRefreshingToken = false
let requestsToRefresh = []
let countErrorsOnRefresh = 0

function doSomethingBeforeSendRequest(config) {
    // let accessToken = localStorage.getItem('access_token');
    let accessToken = getCookie('access_token')
  
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
  
    return config;
}

function doSomethingIfRequestError(error) {
  return Promise.reject(error);
}

function doSomethingWithResponseData(response) {
  return response;
}

function doSomethingIfResponseError(error) {
  const { response, config } = error
  
  // let refreshToken = localStorage.getItem('refresh_token')
  // let access_token = localStorage.getItem('access_token')
  // let expiresIn = localStorage.getItem('expires_in')
  let refreshToken = getCookie('refresh_token')
  let expiresIn = getCookie('expires_in')
  let access_token = getCookie('access_token')
  let user = localStorage.getItem('user')

  if (response.status !== 401 || !refreshToken || !expiresIn || !access_token || !user || response.config.url === '/logout') {
    console.log('caiu', response.status, refreshToken, expiresIn, access_token, user)
    return Promise.reject(error)
  }

  console.log('1 - Inicia o refresh do token se ' + isRefreshingToken + ' for igual a false')

  if (!isRefreshingToken) {
    //let timeToExpireToken = Math.round((expiresIn - Date.now()) / 1000)
    //console.log(timeToExpireToken)

    // if (timeToExpireToken < 1) {
      isRefreshingToken = true
      countErrorsOnRefresh++
      //console.log('Request for a new token')

      axios.create({
        baseURL: baseUrlApi, 
        // withCredentials: true, //se precisar enviar cookies do front pro back
      })
        .post('refresh-token', {
          grant_type : "refresh_token",
          client_id : 1,
          client_secret : "TDtRyvVtunbUdCylrGtvjNfZOvVVSmsHnUGZ3348",
          refresh_token : refreshToken,
          scope : "" 
        }).then((response) => {
            if (response.data.status === 'success') {
                //console.log('New token received', response.data.data.access_token)
                let accessTokenExpireIn = (Date.now() + (response.data.data.expires_in * 1000))

                setCookie("access_token", response.data.data.access_token, 30);
                setCookie("expires_in", accessTokenExpireIn, 30);
                setCookie("refresh_token", response.data.data.refresh_token, 30);

                // localStorage.setItem('access_token', response.data.data.access_token)
                // localStorage.setItem('expires_in', accessTokenExpireIn)
                // localStorage.setItem('refresh_token', response.data.data.refresh_token)

                console.log('4 - Token atualizado com sucesso, será executado as requisições pendentes da fila')

                requestsToRefresh.forEach((cb) => cb(response.data.data.access_token))
            } else if (response.data.status === 'error') {
              console.log('5 - Houve um erro ao atualizar token')
              //Cai no catch da pagina que é responsavel por deslogar
              return Promise.reject(error);
            }
        }).catch((err) => { //quando do F5 no meio do refresh da problema, ver com fiz na includer para corrigir isso
          // console.log('An error occurred while getting a new token', err)

          console.log('6 - Houve um erro ao atualizar token3')
          requestsToRefresh.forEach((cb) => cb(null))

          if (countErrorsOnRefresh > 1) {
            console.log('88')
            //Cai no catch da pagina que é responsavel por deslogar
            return Promise.reject(err);
          } else {
            console.log('99')
            isRefreshingToken = false
            // return Promise.reject(err); //add
          }
        }).finally(() => {
          console.log('7 - Finalmente limpa a fila de req pendentes e libera o isRefreshingToken')
         // console.log('Clear queue of failed requests')
          requestsToRefresh = []
          isRefreshingToken = false
        });
    // }
  }

  console.log('2 - A requisição' + response.config.url + ' será colocada na fila')
  // console.log(`The request is waiting for a new token.. [${response.config.url}]`)

  return new Promise((resolve, reject) => {
    requestsToRefresh.push((refreshedToken) => {
      if (refreshedToken) {
        console.log('3 - Refazendo requisição da fila: ' + response.config.url + ' com o token novo')
        config.headers.Authorization = 'Bearer ' + refreshedToken
        // config.status = 200 //mudo para não dar refresh duas vezes seguidas na requisição
        resolve(axios(config))
      }

      reject(error)
    })
  })
}

api.interceptors.request.use(doSomethingBeforeSendRequest, doSomethingIfRequestError);
api.interceptors.response.use(doSomethingWithResponseData, doSomethingIfResponseError);

export default api;