import '../style.css'
import './style.css'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import api from '../../../services/api';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AddingPaymentMethodForm from '../../PaymentStripeSteps/AddingPaymentMethodForm'
import {Elements} from '@stripe/react-stripe-js';
import SpinnerLoader from '../../Loaders/SpinnerLoader'

function ModalAddPaymentMethod({modalStatus, setModalStatus, stripePromise, setCards}) {
    const { authenticated, handleLogout } = useContext(Context)
    const [isLoading, setIsLoading] = useState(true);

    //Stripe
    const [clientSecret, setClientSecret] = useState(null)

    const appearance = {
        theme: 'night',
        variables: { 
            borderRadius: '4px',
            colorBackground: '#1e1e1e', //var(--plat-two)
            fontFamily: 'AlbertSans, Roboto, Segoe UI, Arial',
            colorPrimary: '#2A2A2A', //var(--plat-eight)
            colorText: 'rgba(255, 255, 255, .9)', //var(--plat-six)
            colorTextPlaceholder: 'rgba(255, 255, 255, .55)',  //var(--plat-seven)
        },
        rules: {
            '.Input': {
                padding: '16.5px 14px',
                boxShadow: 'none'
            },
            '.Input:focus': {
                boxShadow: 'none',
                backgroundColor: "rgba(255, 255, 255, .04)",  //var( --plat-four)
            },
            '.Label': {
                color: 'rgba(255, 255, 255, .55)' //var(--plat-seven)
            },
            '.Error': {
                color: 'rgba(237, 67, 55, .9)', //var(--error-color)
            },
            '.Input--invalid': {
                color: 'rgba(237, 67, 55, .9)',  //var(--error-color)
                borderColor: 'rgba(237, 67, 55, .9)'  //var(--error-color)
            }
        }
    };

    const options = {
        clientSecret,
        appearance
    };

    useEffect(() => {
        if (modalStatus) {
            if (authenticated) {
                //Cria uma intenção de pagamento no servidor (Disparar toda vez que abre a modal, pois o intent só funciona uma vez por requisição)
                recreatePaymentIntent()
            }
        } else {
            setIsLoading(true)
        }
    }, [modalStatus])

    //Cria uma intenção de pagamento no servidor
    function recreatePaymentIntent() {
        api.post('create-payment-intent-customer', {
            added_card: true
        }).then((response) => {
            if (response.data.status === 'success') {
                setClientSecret(response.data.data.client_secret);
                setIsLoading(false)
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout()
            }
        })
    }

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal modal_payment mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <AddCardOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3 className='mdl_blk'>Adicionar novo cartão</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        <p className='mdl_blk'>Ao adicionar um novo cartão, ele será considerado o seu cartão padrão.</p>
                    
                        {isLoading ?
                            <SpinnerLoader borderColorImp="var(--plat-seven)" />
                        :
                            <>
                                {clientSecret && stripePromise && (
                                    <Elements options={options} stripe={stripePromise}>
                                        <AddingPaymentMethodForm clientSecret={clientSecret} modalStatus={modalStatus} setModalStatus={setModalStatus} setCards={setCards} />
                                    </Elements>
                                )}
                            </>
                        }
                        
                    </div>
                </div>
            </div>

        </div> 
    )
}

export default ModalAddPaymentMethod;