import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { useHistory } from "react-router-dom";
import React, { useContext, useState } from 'react'
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { Context } from '../../../../context/AuthContext';

function TopicCommentMenu({comment, setComments, comments, setLoadingAllComments, setTopicClicked, topicClicked, setCommentEditing, forumTopics, setForumTopics}) {
    const { handleLogout } = useContext(Context)
    // const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //Alterar Comentário
    async function handleEditComment() {
        handleClose()

        let newComments = comments.map((comt) => {
            if (comt.id === comment.id) {
                comt.edit_enabled = true
                
                setCommentEditing({
                    id: comt.id,
                    comment: comt.comment
                })
            } else {
                comt.edit_enabled = false
            }

            return comt;
        })

        setComments(newComments)
    }

    //Remove comentário
    async function handleDeleteComment() {
        handleClose()
        setLoadingAllComments(true)

        await api.delete(`delete-topic-comment/${comment.id}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setComments(comments.filter((comm) => comment.id !== comm.id))
                    setTopicClicked({...topicClicked, ...{total_comments: topicClicked.total_comments - 1}})

                    //Aumenta na tabela tbem o num. total de comentarios
                    let newForumTopics = forumTopics.map((forum) => {
                        if (forum.id === topicClicked.id) {
                            forum.total_comments = topicClicked.total_comments - 1
                        }

                        return forum
                    })
                    setForumTopics(newForumTopics)
                } else {
                    toast.error(response.data.message)
                }

                setLoadingAllComments(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    toast.error(error.response.data.message)
                }

                setLoadingAllComments(false)
            })
    }

    return (
        <>
            <IconButton
                aria-label="more"
                id={`long-button-${comment.id}`}
                color='neutral'
                aria-controls={open ? `long-menu-${comment.id}` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            
            <Menu
                id={`long-menu-${comment.id}`}
                MenuListProps={{'aria-labelledby': `long-button-${comment.id}`}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                        boxShadow: '0px 3px 6px rgba(0,0,0,0.05)',
                    },
                    sx: {
                        overflow: 'visible',
                        mt: 1.5,
                        bgcolor: 'var(--plat-two)',
                        color: 'var(--plat-six)',
                        '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={(e) => handleEditComment()}>Editar</MenuItem>
                <MenuItem onClick={(e) => handleDeleteComment()}>Excluir</MenuItem>
            </Menu>
        </>
    )
}

export default TopicCommentMenu;