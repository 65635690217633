import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltipDark = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--plat-two)',
      color: 'var(--plat-seven)',
      maxWidth: 225,
      borderRadius:"4px",
      padding:"12px",
      fontSize: theme.typography.pxToRem(12.5),
      border: '1px solid var(--plat-eight)',
    },
}));

export { CustomTooltipDark }