import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import './style.css'
import Form from "../../../components/Form";
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { CustomTextField, inputLabelStyles } from "../../../components/MaterialCustom/Laboratory/TextField/CustomTextField";
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";
import SectionBeStudent from "../../../components/Website/SectionBeStudent";

function Support ({whatPage}) {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed, authenticated } = useContext(Context)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [categorysSupport, setCategorysSupport] = useState([])
    
    const [formSupport, setFormSupport] = useState({
        email: '',
        name: '',
        category_support_id: '',
        message: ''
    })

    const [fieldsError, setFieldsError] = useState({
        email: '',
        name: '',
        category_support_id: '',
        message: ''
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        setMetaTagsOfPage('Suporte', 'Entre em contato com o nosso suporte em caso de dúvidas, parcerias ou sugestões!')

        async function getData() {
            await api.get('support')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCategorysSupport(response.data.data.categorys_support)
                    } 
                }).catch((error) => {
                })
        }

        getData()
    }, [])

    function setName(e) {
        setFormSupport({...formSupport, name: e.target.value})
    }

    function setEmail(e) {
        setFormSupport({...formSupport, email: e.target.value})
    }

    function setCategorySupport(e) {
        setFormSupport({...formSupport, category_support_id: e.target.value})
    }

    function setMessage(e) {
        setFormSupport({...formSupport, message: e.target.value})
    }

    async function sendForm(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        let routeUrl = '';

        if (authenticated) {
            routeUrl = 'support-lab'
        } else {
            routeUrl = whatPage === 'website' ? 
                'support-website' 
            : 
                'support-blog'
        }

        await api.post(routeUrl, formSupport)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormSupport({email: '', name: '', category_support_id: '', message: ''})
                } else {
                    toast.error(response.data.message)
                }

                setSpinnerBtnLoader(false)
                setFieldsError({email: '', name: '', category_support_id: '', message: ''})
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                }

                setSpinnerBtnLoader(false)
            })
    }

    return (
        <>
            <div className={`box_pg_contact box_contact ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>
            
                <div className="contact_content">
                    <div className="contact_inside">
                        <h1>Suporte!</h1>
                        <p className="descp_sup_prof">Nosso time de suporte irá sanar suas dúvidas!</p>
                    
                        <Form>
                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                {authenticated === false &&
                                    <>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <CustomTextField
                                                    required
                                                    label="Nome Completo"
                                                    onChange={setName}
                                                    autoComplete='off'
                                                    value={formSupport.name} 
                                                    name='name'
                                                    {...(fieldsError.name && {error: 'true'})}
                                                    InputLabelProps={{
                                                        sx: {
                                                        ...inputLabelStyles,
                                                        },
                                                    }}
                                                    size="medium"
                                                    fullWidth
                                                    inputProps={{ maxLength: 200 }}
                                                />

                                                <FormHelperText>
                                                    {fieldsError.name}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <CustomTextField
                                                    required
                                                    label="E-mail"
                                                    name='email'
                                                    autoComplete='off'
                                                    {...(fieldsError.email && {error: 'true'})}
                                                    onChange={setEmail} 
                                                    value={formSupport.email}
                                                    size="medium"
                                                    fullWidth
                                                    inputProps={{ maxLength: 200 }}
                                                    InputLabelProps={{
                                                        sx: {
                                                        ...inputLabelStyles,
                                                        },
                                                    }}
                                                />

                                                <FormHelperText>
                                                    {fieldsError.email}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <CustomTextField
                                            required
                                            select
                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                            label="Objetivo do Contato"
                                            {...(fieldsError.category_support_id && {error: 'true'})}
                                            size="medium"
                                            name="category_support_id"
                                            onChange={setCategorySupport} 
                                            value={formSupport.category_support_id} 
                                            InputLabelProps={{
                                                sx: {
                                                ...inputLabelStyles,
                                                },
                                            }}
                                            fullWidth
                                            inputProps={{
                                                MenuProps: {
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor: 'var(--plat-two)',
                                                            color: 'var(--plat-seven)'
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            {categorysSupport.map((categ) => (
                                                <MenuItem 
                                                    key={categ.id} 
                                                    value={categ.id} 
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "var(--plat-four)",
                                                        }
                                                    }}
                                                >
                                                    {categ.name}
                                                </MenuItem>
                                            ))}
                                        </CustomTextField>

                                        <FormHelperText>
                                            {fieldsError.category_support_id}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <CustomTextField
                                            required
                                            label="Mensagem"
                                            name='message'
                                            {...(fieldsError.message && {error: 'true'})}
                                            multiline
                                            rows={5}
                                            maxRows={8}
                                            onChange={setMessage} 
                                            value={formSupport.message} 
                                            InputLabelProps={{
                                                sx: {
                                                ...inputLabelStyles,
                                                },
                                            }}
                                            fullWidth
                                            inputProps={{ maxLength: 2000 }}
                                        />

                                        <FormHelperText>
                                            {fieldsError.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={sendForm}
                                        endIcon={<SendOutlinedIcon />}
                                        loading={spinnerBtnLoader}
                                        loadingPosition="end"
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                        color="secondary"
                                    >
                                        <span>Enviar Mensagem</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    </div>
                </div>
            </div>

            {whatPage !== 'laboratorio' &&
                <SectionBeStudent />
            }
        </>
    )
}

export default Support;