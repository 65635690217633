import './style.css';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef, useContext } from 'react'
import Logo from '../../../assets/imgs/logos/edutekablog.png'
import { Context } from '../../../context/AuthContext'
import api from '../../../services/api';
import ModalLogin from '../../Modal/General/ModalLogin';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { LoadingButton } from '@mui/lab';
import { useMediaQuery, useTheme } from '@material-ui/core';
import NavBarBlogMobile from '../../NavBar/Blog/Mobile';
import NavBarBlogDesktop from '../../NavBar/Blog/Desktop';
import NavBarProfileAndNotification from '../../MaterialCustom/NavBarProfileAndNotification';
import NavBarSearchArea from '../../MaterialCustom/NavBarSearchArea';
import { getCookie } from '../../../helpers';

function HeaderBlog () { 
    const { showMenuMain, setShowMenuMain, showSearchBar, setShowSearchBar, authenticated, showAlert, setShowAlert, alertInTopClosed, setAlertInTopClosed } = useContext(Context)
    const [blogCategorys, setBlogCategorys] = useState([])
    const [modalLoginStatus, setModalLoginStatus] = useState(false)
    const history = useHistory()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const scrollTop = useRef('')
    const showAlertCopy = useRef('')

    useEffect(() => { 
        function loadCategorys() {
            api.get('menu-blog')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setBlogCategorys(response.data.data.blog_categorys)
                        setShowAlert(response.data.data.main_alert)
                    }
                }).catch((error) => {
                })
        }

        loadCategorys()
    }, [])

    useEffect(() => {
        const handleScroll = (event) => {
            function hideOrShowAlertOnScroll() {
                if (showAlert) {
                    showAlertCopy.current = showAlert
                }

                //Quando a página é rolada para baixo ele some o alerta que fica no topo, se é rolada novamente pro topo reaparece
                if (window.scrollY > 300 && scrollTop.current < window.scrollY) {
                    setShowAlert('')
                } else {
                    setShowAlert(showAlertCopy.current)
                }

                scrollTop.current = window.scrollY;
            }

            hideOrShowAlertOnScroll()
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [showAlert]);

    function hideAlertInTop(e) {
        e.preventDefault()

        setAlertInTopClosed(true)
        setShowAlert('')
    }

    function redirectTo(link) {
        if (link) {
            history.push(link)
        }
    }

    return (
        <>
            {!alertInTopClosed &&
                <div id="box-alert-important" className='box_alert_important'>
                    {/* {showAlert !== '' &&
                        <> */}
                            <div>
                                {/* testmode no futuro poderá ser apagado */}
                                {(getCookie('testmode') === 'EDK_ENABLE_TESTMODE') &&
                                    <span>MODO TESTE: ATIVADO | </span>
                                }
     
                                <span>{showAlert}</span>
                            </div>
                            
                            <button onClick={(e) => hideAlertInTop(e)}>X</button>
                        {/* </>
                    }  */}
                </div>
            }

            <div className={`header_only_brand logo_bar ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`} onClick={() => redirectTo('/')} style={{cursor:"pointer"}}>
                <img className="logo logo_blog" width={128} height={21.67} src={Logo} alt="Eduteka" title="Eduteka" />
            </div>

            <header className={`short ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

                <div className='top_main_left'>
                    {/* Menu pizza (somente mobile) */}
                    <MenuOutlinedIcon className='show_on_md_screen' onClick={() => setShowMenuMain(!showMenuMain)} color="neutral" fontSize="large"/>

                    {/* LOGO */}
                    <div className='hide_on_mobscr' onClick={() => redirectTo('/')} style={{cursor:"pointer"}}>
                        <img className="logo logo_blog" width={128} height={21.67} src={Logo} alt="Eduteka" title="Eduteka" />
                    </div>
                </div>

                <div className='content_top_area'>
                    {/* NavBar - Menu */}
                    {isMobile ? 
                        <NavBarBlogMobile showMenuMain={showMenuMain} setShowMenuMain={setShowMenuMain} blogCategorys={blogCategorys} />
                    : 
                        <NavBarBlogDesktop blogCategorys={blogCategorys} />
                    }


                    {/* FORMULÁRIO DE PESQUISA */}
                    <NavBarSearchArea searchMode='blog' />

                                        
                    {/* BOTÕES DE LOGAR E ASSINAR AGORA (se não estiver logado) */}
                    {!authenticated ?
                        <div className="btns_on_top">
                            <SearchOutlinedIcon className='hide_on_desktop2' color="neutral" onClick={() => setShowSearchBar(!showSearchBar)} sx={{cursor:"pointer"}}/>

                            <LoadingButton
                                onClick={() => setModalLoginStatus(!modalLoginStatus)}
                                sx={{marginRight:"8px"}}
                                variant="contained"
                                color="secondary"
                            >
                                <span>Logar</span>
                            </LoadingButton>

                            <LoadingButton
                                onClick={() => redirectTo('/matricule-se')}
                                variant="outlined"
                                color="secondary"
                            >
                                <span>Matricule-se</span>
                            </LoadingButton>
                        </div>
                    :
                        <div className="btns_on_top">
                            <SearchOutlinedIcon className='hide_on_desktop2' color="neutral" onClick={() => setShowSearchBar(!showSearchBar)} sx={{cursor:"pointer"}}/>
                            <NavBarProfileAndNotification />
                        </div>
                    }
                </div>

            </header>

            <ModalLogin modalStatus={modalLoginStatus} setModalStatus={setModalLoginStatus} />
        </>
    );
}

export default HeaderBlog;