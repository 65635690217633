import './style.css'
import Logo from '../../../assets/imgs/logos/logoeduteka.png'

function Main() {
    return (
        <div style={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} className='beating'>
            <img className="logo_main" width={98} src={Logo} alt="Eduteka" title="Eduteka" />
        </div>
    )
}

export default Main;