import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { LoadingButton } from '@mui/lab';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { Grid } from '@mui/material';
import '../style.css'
import { useHistory } from 'react-router-dom';

function BannerWebsite() {     
    const { authenticated, showAlert, alertInTopClosed } = useContext(Context)
    const history = useHistory()

    function redirectTo(link) {
        if (link) {
            history.push(link)
        }
    }

    const openLinkInSameTab = url => {
        window.location.href = url;
    };

    return (
        <div className={`main_merchan ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>
            <div className='mer_content'>
                <div className='merconttop'>
                    <h1>Escola online para <span className='color_green'>programadores</span></h1>
                    
                    <p>Somos apaixonados por tecnologia.</p> 
                    <p className='mrg_bot'>Temos diversos cursos para você que também é apaixonado como a gente!</p>
                </div>

                <div className='mercontbottom'>
                    <Grid container spacing={2} sx={{width:"100%"}}>
                        <Grid item xs={12} sm={11} md={10} lg={8} xl={6}>
                            {authenticated ?
                                <LoadingButton
                                    onClick={() => openLinkInSameTab(process.env.REACT_APP_URL_BASE_LABORATORY)}
                                    endIcon={<SchoolOutlinedIcon color="neutral" fontSize="large" />}
                                    loadingPosition="end"
                                    fullWidth
                                    className="btn_student_header"
                                    variant="outlined"
                                    color="neutral"
                                    size="large"
                                    sx={{
                                        border: "1px solid var(--first-color)", 
                                        marginRight:"12px",
                                        ':hover': {
                                            bgcolor: 'var(--first-color)',
                                            color: 'var(--plat-two)',
                                            border: "1px solid var(--first-color)", 
                                        },
                                    }}
                                >
                                    <span>Área do Aluno</span>
                                </LoadingButton>
                            :
                                <LoadingButton
                                    onClick={() => redirectTo('/matricule-se')}
                                    endIcon={<SchoolOutlinedIcon color="neutral" fontSize="large" />}
                                    loadingPosition="end"
                                    fullWidth
                                    className="btn_student_header"
                                    variant="outlined"
                                    color="neutral"
                                    size="large"
                                    sx={{
                                        border: "1px solid var(--first-color)", 
                                        marginRight:"12px",
                                        ':hover': {
                                            bgcolor: 'var(--first-color)',
                                            color: 'var(--plat-two)',
                                            border: "1px solid var(--first-color)", 
                                        },
                                    }}
                                >
                                    <span>Quero ser Aluno</span>
                                </LoadingButton>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default BannerWebsite;