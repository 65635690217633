import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import { validateDataBack } from '../../../helpers'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import ListComment from '../../../components/Platform/ListComment';
import Comment from '../../../components/Platform/ListComment/Comment';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function TopicosForm({regId, dark = true}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)

    const [formTopics, setFormTopics] = useState({
        subject: '',
        message: '',
        status: '',
        class_id: '',
        publication_date: '',
        author_id: '',
    })

    const [fieldsError, setFieldsError] = useState({
        subject: '',
        message: '',
        status: '',
        class_id: '',
        publication_date: '',
        author_id: '',
    })

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Pendente'},
        {id: 2, name: 'Resolvido'}
    ])

    const [listComments, setListComments] = useState([])
    const [tableHistrcCell, setTableHistrcCell] = useState([])
    const [courseClassOptions, setCourseClassOptions] = useState([])
    const [authorsOptions, setAuthorsOptions] = useState([])

    const title = useRef({
        name: 'Tópicos',
        description_insert: 'Insira novos tópicos nas aulas',
        description_update: 'Altere tópicos das aulas',
        link_from: '/admin/topicos'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)

            api.get(`admin/topicos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setFormTopics(response.data.data.course_topic)
                        setListComments(response.data.data.comments)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de inserções/remoções
            api.get(`admin/topicos/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca subcategorias do blog para por no select>options
            api.get(`admin/topicos/infos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCourseClassOptions(response.data.data.courses_classes)
                        setAuthorsOptions(response.data.data.authors)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de alterações
            api.get('admin/topicos/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
            
            //Busca autores e aulas dos cursos para por no select>options
            api.get('admin/topicos/infos')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCourseClassOptions(response.data.data.courses_classes)
                        setAuthorsOptions(response.data.data.authors)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setSubject(e) {
        setFormTopics({...formTopics, subject: e.target.value})
    }

    function setMessage(e) {
        setFormTopics({...formTopics, message: e.target.value})
    }

    function setStatus(e) {
        setFormTopics({...formTopics, status: e.target.value})
    }

    function setClassId(e) {
        setFormTopics({...formTopics, class_id: e.target.value})
    }

    function setPublicationDate(e) {
        setFormTopics({...formTopics, publication_date: e.target.value})
    }

    function setAuthorId(e) {
        setFormTopics({...formTopics, author_id: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData()
        formData.append('subject', formTopics.subject)
        formData.append('message', formTopics.message)
        formData.append('status', formTopics.status)
        formData.append('class_id', formTopics.class_id)
        // formData.append('publication_date', formTopics.publication_date)
        formData.append('author_id', formTopics.author_id)

        await api.post('admin/topicos', formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormTopics({subject: '', message: '', status: '', class_id: '', publication_date: '', author_id: ''})
                    // setListPerfis([])

                    history.push("/admin/topicos")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({subject: '', message: '', status: '', class_id: '', publication_date: '', author_id: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
        
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData()
        formData.append('subject', formTopics.subject)
        formData.append('message', formTopics.message)
        formData.append('status', formTopics.status)
        // formData.append('class_id', formTopics.class_id)
        // formData.append('publication_date', formTopics.publication_date)
        // formData.append('author_id', formTopics.author_id)

        await api.post(`admin/topicos/${regId}`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/topicos")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({subject: '', message: '', status: '', class_id: '', publication_date: '', author_id: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleListDelete(e, commentId) {
        e.preventDefault()
        setLoading(true)

        await api.delete(`admin/topicos/${regId}/comment/${commentId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setListComments(listComments.filter(item => item.id !== commentId))
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    validateDataBack(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(1)}  />
                               
                                {regId &&
                                    <CustomTab icon={<CommentOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Comentários (últ. 50)" {...a11yProps(2)}  />
                                }
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Assunto"
                                                            onChange={setSubject}
                                                            value={formTopics.subject} 
                                                            name='subject'
                                                            autoComplete='off'
                                                            {...(fieldsError.subject && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.subject}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Curso/Aula"
                                                            {...(regId && {disabled: 'true'})}
                                                            {...(fieldsError.class_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="class_id"
                                                            onChange={setClassId} 
                                                            value={formTopics.class_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {courseClassOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.class_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formTopics.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Autor"
                                                            {...(fieldsError.author_id && {error: 'true'})}
                                                            {...(regId && {disabled: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="author_id"
                                                            onChange={setAuthorId} 
                                                            value={formTopics.author_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {authorsOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.author_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Mensagem"
                                                            onChange={setMessage}
                                                            multiline
                                                            maxRows={10}
                                                            value={formTopics.message} 
                                                            name='message'
                                                            autoComplete='off'
                                                            {...(fieldsError.message && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 2500 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.message}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                
                                                {regId &&
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                label="Data de publicação"
                                                                type="datetime-local"
                                                                size="medium"
                                                                name="publication_date"
                                                                // defaultValue="2017-05-24"
                                                                {...(fieldsError.publication_date && {error: 'true'})}
                                                                onChange={setPublicationDate}
                                                                value={formTopics.publication_date}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.publication_date}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_list'>
                                        <ListComment>
                                            {(listComments.length >= 1) ?
                                                <>
                                                    {listComments.map((item, index) => {
                                                        return <Comment key={index} publicationDate={item.date} comment={item.comment} name={item.author.name} id={item.id} handleListDelete={handleListDelete} />
                                                    })}
                                                </>
                                            :
                                                <p className='top_comm_plat'>Nenhum comentário encontrado nesse tópico!</p>
                                            }
                                        </ListComment>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp={dark === true ? "var(--plat-seven)" : ""} />
                </div>
            }
        </>
    )
}

export default TopicosForm;