import React, { useEffect, useContext } from "react";
import { Context } from '../../../context/AuthContext'

function MyPoints ({}) {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    
    useEffect(() => {
        window.scrollTo(0, 0)
        setMetaTagsOfPage('Meus pontos', 'Confira sua pontuação na plataforma!')
    }, [])

    return (
        <div className="box_labor_all">
            <div className={`box_profile ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

                <div className="profile_content">
                    <div className="contact_inside">
                        <h1>Meus Pontos</h1>
                        <p className="descp_sup_prof">Confira sua pontuação na plataforma!</p>

                        <p style={{color: 'var(--plat-twelve)', fontSize: '18px', marginTop: '50px'}}>Página em construção, em breve novidades...</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MyPoints;