import './style.css'

function SpinnerLoader({heightVal, justifyContentVal, borderColorImp, widthVal}) {
    return (
        <div style={{width: widthVal ?? '100%', height: heightVal ?? '100px', display:'flex', justifyContent: justifyContentVal ?? 'center', alignItems:'center'}}>
            {borderColorImp ?
                <div className="spinner" style={{borderColor:`${borderColorImp}`}}></div>
            :
                <div className="spinner"></div>
            }
            
        </div>
    )
}

export default SpinnerLoader;