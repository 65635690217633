import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';
import { CircularProgress, FormControl, MenuItem } from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from '../CheckoutForm'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useState } from 'react';

function StepFirstPayment ({clientSecret, stripePromise, plans, planSelected, setPlanSelected, nextStep, userId, stepNow = null, stepTotals = null/*, dark = true*/}) {
    const [isSetPlanLoading, setIsSetPlanLoading] = useState(false)

    const appearance = {
        theme: 'night',
        variables: { 
            borderRadius: '4px',
            colorBackground: '#1e1e1e', //var(--plat-two)
            fontFamily: 'AlbertSans, Roboto, Segoe UI, Arial',
            colorPrimary: '#2A2A2A', //var(--plat-eight)
            colorText: 'rgba(255, 255, 255, .9)', //var(--plat-six)
            colorTextPlaceholder: 'rgba(255, 255, 255, .55)',  //var(--plat-seven)
        },
        rules: {
            '.Input': {
                padding: '16.5px 14px',
                boxShadow: 'none'
            },
            '.Input:focus': {
                boxShadow: 'none',
                backgroundColor: "rgba(255, 255, 255, .04)",  //var( --plat-four)
            },
            '.Label': {
                color: 'rgba(255, 255, 255, .55)' //var(--plat-seven)
            },
            '.Error': {
                color: 'rgba(237, 67, 55, .9)', //var(--error-color)
            },
            '.Input--invalid': {
                color: 'rgba(237, 67, 55, .9)',  //var(--error-color)
                borderColor: 'rgba(237, 67, 55, .9)'  //var(--error-color)
            }
        }
    };

    const options = {
        clientSecret,
        appearance
    };

    async function onChangePlan(newPlan) {
        setIsSetPlanLoading(true)

        await api.post(`new-user-set-plan`, {
            user_id: userId,
            plan_id: newPlan.id
        }).then((response) => {
            if (response.data.status === 'success') {
                setPlanSelected(newPlan)
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }

            setIsSetPlanLoading(false)
        }).catch((error) => {
            toast.error(error.response.data.message)
            setIsSetPlanLoading(false)
        })
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>Informe os dados do seu cartão!</h1>
                <p>Confira tudo antes de iniciar a assinatura.</p>

                <div className="website_payment_new">
                    <div className="website_pay_container_new">
                        <div className='payment_content_new'>
                            <div className='big_cont_left_new'>
                                <div className='pay_cont_left_new'>
                                    <div className='pay_content_new opened'>
                                        {clientSecret ?
                                            <>
                                                <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                                                    <CustomTextField
                                                        required
                                                        select
                                                        sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                        label="Plano selecionado"
                                                        size="medium"
                                                        fullWidth
                                                        name="plan_id"
                                                        {...(isSetPlanLoading && {disabled: 'true'})}
                                                        onChange={(e) => onChangePlan(e.target.value)} 
                                                        // onChange={(e) => setPlanSelected(e.target.value)} 
                                                        value={planSelected} 
                                                        InputLabelProps={{
                                                            sx: {
                                                            ...inputLabelStyles,
                                                            },
                                                        }}
                                                        inputProps={{
                                                            MenuProps: {
                                                                MenuListProps: {
                                                                    sx: {
                                                                        backgroundColor: 'var(--plat-two)',
                                                                        color: 'var(--plat-seven)'
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {plans.map((plan) => (
                                                            <MenuItem 
                                                                key={plan.id} 
                                                                value={plan}
                                                                sx={{
                                                                    "&:hover": {
                                                                        backgroundColor: "var(--plat-four)",
                                                                    }
                                                                }}
                                                            >
                                                                {plan.name}
                                                            </MenuItem>
                                                        ))}
                                                    </CustomTextField>
                                                </FormControl>

                                                <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                                                    <CustomTextField
                                                        label="Preço"
                                                        // onChange={setName}
                                                        disabled
                                                        value={`R$ ${planSelected.price} / Mês`} 
                                                        name='price'
                                                        InputLabelProps={{
                                                            sx: {
                                                                ...inputLabelStyles,
                                                            },
                                                        }}
                                                        size="medium"
                                                        fullWidth
                                                        inputProps={{ maxLength: 200 }}
                                                    />
                                                </FormControl>

                                                <div className='card_pay_line'></div>

                                                {clientSecret && stripePromise && (
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <CheckoutForm 
                                                            typeCheckout='first-payment' 
                                                            clientSecret={clientSecret} 
                                                            // planSelectedId={planSelected?.id} 
                                                            nextStep={nextStep} 
                                                            // userId={userId} 
                                                        />
                                                    </Elements>
                                                )}
                                            </>
                                        :
                                            <div className='box_plan_loader'>
                                                <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepFirstPayment;