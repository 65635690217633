import '../../../style.css'
import './style.css'

function CourseLoaderContent() {
    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='loader_box_crs_all'>
                    <div className='loader_box_crs_in_left pulse'>
                        <div className='loader_dscp_crs'></div>
                        <div className='loader_dscp_crs_b'></div>
                        <div className='loader_dscp_crs_c'></div>

                        <br/><br/><br/><br/>

                        <div className='loader_dscp_crs'></div>
                        <div className='loader_dscp_crs_b'></div>
                        <div className='loader_dscp_crs_c'></div>
                    </div>
                    
                    <div className='pulse'>
                        <div className='loader_box_crs_in_right'></div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default CourseLoaderContent;