import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function AulasForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)

    const [formClass, setFormClass] = useState({
        title: '',
        description: '',
        order: '',
        url_video: '',
        course_module_id: '',
        duration_seconds: '',
        status: '',
        course_id: ''
    })

    const [formGalleryDidacticFiles, setFormGalleryDidacticFiles] = useState([
        // {id: 4, name: 'Original name'}
    ])

    const [fieldsError, setFieldsError] = useState({
        title: '',
        description: '',
        order: '',
        url_video: '',
        course_module_id: '',
        duration_seconds: '',
        status: '',
        course_id: ''
    })

    const [tableHistrcCell, setTableHistrcCell] = useState([])
    const [coursesOptions, setCoursesOptions] = useState([])
    const [modulesOptions, setModulesOptions] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const title = useRef({
        name: 'Aulas',
        description_insert: 'Insira novas aulas na plataforma',
        description_update: 'Altere aulas da plataforma',
        link_from: '/admin/aulas'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //Captura Elementos HTML
    let inputListFile = useRef('')

    function clickOnInputFile() {
        inputListFile.current.click()
    }

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)

            api.get(`admin/aulas/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setModulesOptions(response.data.data.modules)
                        setFormClass(response.data.data.course_class)
                        setFormGalleryDidacticFiles(response.data.data.files_didactic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de inserções/remoções
            api.get(`admin/aulas/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca subcategorias do blog para por no select>options
            api.get(`admin/aulas/cursos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCoursesOptions(response.data.data.courses)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de alterações
            api.get('admin/aulas/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
            
            //Busca subcategorias do blog para por no select>options
            api.get('admin/aulas/cursos')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCoursesOptions(response.data.data.courses)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setTitle(e) {
        setFormClass({...formClass, title: e.target.value})
    }

    function setDescription(e) {
        setFormClass({...formClass, description: e.target.value})
    }

    function setUrlVideo(e) {
        setFormClass({...formClass, url_video: e.target.value})
    }

    function setDurationSeconds(e) {
        setFormClass({...formClass, duration_seconds: e.target.value})
    }

    function setCourseModuleId(e) {
        setFormClass({...formClass, course_module_id: e.target.value})
    }

    function setStatus(e) {
        setFormClass({...formClass, status: e.target.value})
    }

    async function setCourseId(e) {
        //Carrega módulos do curso selecionado
        await loadModulesOfCourseId(e.target.value)

        setFormClass({...formClass, course_id: e.target.value, course_module_id: ''})
    }

    async function loadModulesOfCourseId(courseId){
        await api.get(`admin/aulas/cursos/${courseId}/modulos`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setModulesOptions(response.data.data.modules)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    function setOrder(e) {
        setFormClass({...formClass, order: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData();
        formData.append('title', formClass.title)
        formData.append('description', formClass.description)
        formData.append('status', formClass.status)
        formData.append('order', formClass.order)
        formData.append('url_video', formClass.url_video)
        formData.append('course_module_id', formClass.course_module_id)
        formData.append('duration_seconds', formClass.duration_seconds)
        formData.append('course_id', formClass.course_id)

        for (let i = 0 ; i < formGalleryDidacticFiles.length ; i++) {
            formData.append("files", formGalleryDidacticFiles[i]);
        }

        await api.post('admin/aulas', formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormClass({title: '', description: '', order: '', url_video: '', course_module_id: '', duration_seconds: '', status: '', course_id: ''})

                    history.push("/admin/aulas")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({title: '', description: '', order: '', url_video: '', course_module_id: '', duration_seconds: '', status: '', course_id: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
        
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData();
        formData.append('title', formClass.title)
        formData.append('description', formClass.description)
        formData.append('status', formClass.status)
        formData.append('order', formClass.order)
        formData.append('url_video', formClass.url_video)
        formData.append('course_module_id', formClass.course_module_id)
        formData.append('duration_seconds', formClass.duration_seconds)
        formData.append('course_id', formClass.course_id)

        await api.post(`admin/aulas/${regId}`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/aulas")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({title: '', description: '', order: '', url_video: '', course_module_id: '', duration_seconds: '', status: '', course_id: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    function loadImage(e) {
        if (e.target.files.length === 0) {
            return;
        }

        let file = e.target.files[0]

        let reader = new FileReader()
        reader.readAsDataURL(file)

        //Validações de Front
        const allowedExtensions = ['application/pdf'];
        if (!allowedExtensions.includes(file.type)) {
            toast.warning('Formato inválido! Formatos permitidos: pdf')
            return
        }
 
        reader.onload = () => {
            // let imgBlob = URL.createObjectURL(file)

            if (regId) {
                //Salva foto adicionada no back-end instantaneamente (se der certo acrescenta na tela)
                saveImage(file/*, imgBlob*/)
            } else {
                setFormGalleryDidacticFiles([...formGalleryDidacticFiles, ...[file]])
            }
        }
    }

    async function saveImage(imgObj/*, imgBlob*/) {
        let formData = new FormData()
        formData.append('file_document', imgObj)

        setLoading(true)

        await api.post(`admin/aulas/${regId}/send-image`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    let fileObj = response.data.data.file
                    //Adiciona foto na galeria do front-end
                    setFormGalleryDidacticFiles([...formGalleryDidacticFiles, ...[{id: fileObj.id, name: imgObj.name}]])
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function deleteImage(e, fileIndex, fileId = null) {
        if (!regId) {
            setFormGalleryDidacticFiles(formGalleryDidacticFiles.filter((cell, index) => index !== fileIndex))
        } else {
            setLoading(true)

            await api.delete(`admin/aulas/${regId}/remove-image/${fileId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message)

                        //remove foto da galeria do front-end
                        setFormGalleryDidacticFiles(formGalleryDidacticFiles.filter((img) => img.id !== fileId))
                    } else {
                        toast.error(response.data.message)
                    }

                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)

                    if (error.response.status === 422) {
                        setFieldsError(error.response.data.data)
                        toast.error(error.response.data.message)
                    } else if (error.response.status === 401) {
                        handleLogout()
                    } else {
                        toast.error(error.response.data.message)
                    }
                })
        }
    }

    const ListSmartItem = ({isAddRow}) => {
        return (
            <div className="list_smart list_add">
                <div className='list_icon_center' htmlFor='file-upload-list' onClick={() => clickOnInputFile()}>
                    <span className="list_icon_txt"><span className='lit_dstq'>Clique aqui</span> para fazer Upload (extensões permitidas: pdf)</span>
                    <FileDownloadOutlinedIcon color='fifthcolor'/>

                    <input type="file" ref={inputListFile} style={{display:"none"}} onChange={(e) => loadImage(e)}></input>
                </div>
            </div>
        )
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<FileDownloadOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Material Didático" {...a11yProps(1)}  />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(2)} />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Título"
                                                            onChange={setTitle}
                                                            value={formClass.title} 
                                                            name='title'
                                                            autoComplete='off'
                                                            {...(fieldsError.title && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.title}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Numeração"
                                                            onChange={setOrder}
                                                            value={formClass.order} 
                                                            name='order'
                                                            autoComplete='off'
                                                            {...(fieldsError.order && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ type: 'number', maxLength: 11 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.order}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={8} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Descrição"
                                                            onChange={setDescription}
                                                            value={formClass.description} 
                                                            name='description'
                                                            autoComplete='off'
                                                            {...(fieldsError.description && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 300 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.description}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Curso"
                                                            {...(fieldsError.course_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="course_id"
                                                            onChange={setCourseId} 
                                                            value={formClass.course_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {coursesOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.course_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Módulo"
                                                            {...(fieldsError.course_module_id && {error: 'true'})}
                                                            {...((formClass.course_id !== '') && {disabled: false})}
                                                            {...((formClass.course_id === '') && {disabled: true})}
                                                            size="medium"
                                                            fullWidth
                                                            name="course_module_id"
                                                            onChange={setCourseModuleId} 
                                                            value={formClass.course_module_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {modulesOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    Módulo {stat.order}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.course_module_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Duração da vídeo aula em segundos"
                                                            onChange={setDurationSeconds}
                                                            value={formClass.duration_seconds} 
                                                            name='duration_seconds'
                                                            autoComplete='off'
                                                            {...(fieldsError.duration_seconds && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ type: 'number', maxLength: 11 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.duration_seconds}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formClass.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={8} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Url da vídeo aula"
                                                            onChange={setUrlVideo}
                                                            value={formClass.url_video} 
                                                            name='url_video'
                                                            {...(!regId && {helperText: "Exemplo: https://player.vimeo.com/video/509774252"})}
                                                            autoComplete='off'
                                                            {...(fieldsError.url_video && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    color: 'var(--plat-twelve)'
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 600 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.url_video}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}> {/* Material Didático */}
                            <>
                                {!loaderTab2 ?
                                    <>
                                        {/* ADD ITEM */}
                                        <ListSmartItem isAddRow={true}/>

                                        {formGalleryDidacticFiles.length ?
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead sx={{background: 'var(--plat-two)'}}>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, color: 'var(--plat-six)' } }}>
                                                            <TableCell>Arquivo</TableCell>
                                                            {/* <TableCell align="right">Extensão</TableCell>
                                                            <TableCell align="right">Tamanho</TableCell> */}
                                                            <TableCell align="right">Remover</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody sx={{background:'var(--plat-eight)'}}>
                                                        {formGalleryDidacticFiles.map((fileObj, index) => {
                                                            return (
                                                                <TableRow key={index} sx={{ '& td, & th': { border: 0, color: 'var(--plat-seven)', borderBottom: '1px solid var(--plat-two)'} }}>
                                                                    <TableCell>{fileObj.name}</TableCell>
                                                                    {/* <TableCell align="right">{fileObj.type}</TableCell>
                                                                    <TableCell align="right">{parseFloat((fileObj.size / 1024).toFixed(2))} Kb</TableCell> */}
                                                                    <TableCell align="right">
                                                                        <DeleteOutlineOutlinedIcon color='fifthcolor' sx={{cursor: "pointer"}} onClick={(e) => deleteImage(e, index, fileObj?.id)}/>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        :
                                            <p className='mdit'>Nenhum material didático foi enviado!</p>
                                        }
                                    </>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default AulasForm;