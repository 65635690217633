import { useRef } from 'react';
import '../style.css'
// import {validateDataFront} from '../../../helpers'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';

function InputFileImage(props) {

    //Captura Elementos HTML
    let inputFile = useRef('')

    function clickOnInputFile() {
        inputFile.current.click()
    }

    // function handleFileImg() {
    //     alert('imagem alterada')
    // }

    // function setThumb2(e) {
    //     // const reader = new FileReader()
    //     // // let a = reader.readAsDataURL(e.target.files[0])
    //     // // console.log('caiuu', a, e.target.files[0])

    //     // reader.onload = (e) => {
    //     //     const data = e.target.result;
    //     //     console.log(data)
    //     //     // props.onChange(data)
    //     // }

        

    //     // reader.onload = (e) => {
    //     //     console.log('onload', reader, reader.readyState)

    //     //     if (reader.readyState === 2) {
    //     //         console.log(reader.result)
    //     //         // setFormPublicationsFile({thumb: reader.result})
    //     //     }

    //     //     reader.readAsDataURL(e.target.files[0])
    //     // }
    // }

    return (
        <div className='input_text_box'>
            <span className="inpdescpt">{props.description}</span>
            <span id={props.name} className='showErrorMessage'></span>
            
            {/* {props.disableBlur ?
                <input type="file" alt={props.placeholder} title={props.placeholder} className={props.className} autoComplete={props.autoComplete ? props.autoComplete : 'off'} name={props.name} placeholder={props.placeholder} maxLength={props.maxLength} onChange={(e) => props.onChange(e)} value={props.value}/>
            :
                <input type="file" alt={props.placeholder} title={props.placeholder} className={props.className} autoComplete={props.autoComplete ? props.autoComplete : 'off'} name={props.name} placeholder={props.placeholder} maxLength={props.maxLength} onChange={(e) => props.onChange(e)} value={props.value} onBlur={(e) => validateDataFront(props.validations, props.name, e.target.value)}/>
            } */}

            <div className='inp_image_box' onClick={() => clickOnInputFile()}>
                <input type="file" ref={inputFile} id='file_image' name={props.name} onChange={(e) => props.onChange(e)} style={{display:'none'}}/>

                {props.value ?
                    <img src={props.value} alt={props.placeholder} title={props.placeholder}/>
                :
                    <InsertPhotoOutlinedIcon fontSize="large" color='fifthcolor'/>
                } 
            </div>
            
        </div>
    )
}

export default InputFileImage;