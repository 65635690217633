import { CircularProgress } from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import CheckoutForm from '../CheckoutForm'

function StepReactivateChangeDefaultPayment ({clientSecret, stripePromise, /*planSelected,*/ nextStep, backToBeforeStep /*, dark = true*/}) {
    const appearance = {
        theme: 'night',
        variables: { 
            borderRadius: '4px',
            colorBackground: '#1e1e1e', //var(--plat-two)
            fontFamily: 'AlbertSans, Roboto, Segoe UI, Arial',
            colorPrimary: '#2A2A2A', //var(--plat-eight)
            colorText: 'rgba(255, 255, 255, .9)', //var(--plat-six)
            colorTextPlaceholder: 'rgba(255, 255, 255, .55)',  //var(--plat-seven)
        },
        rules: {
            '.Input': {
                padding: '16.5px 14px',
                boxShadow: 'none'
            },
            '.Input:focus': {
                boxShadow: 'none',
                backgroundColor: "rgba(255, 255, 255, .04)",  //var( --plat-four)
            },
            '.Label': {
                color: 'rgba(255, 255, 255, .55)' //var(--plat-seven)
            },
            '.Error': {
                color: 'rgba(237, 67, 55, .9)', //var(--error-color)
            },
            '.Input--invalid': {
                color: 'rgba(237, 67, 55, .9)',  //var(--error-color)
                borderColor: 'rgba(237, 67, 55, .9)'  //var(--error-color)
            }
        }
    };

    const options = {
        clientSecret,
        appearance
    };

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <div className='step_lk_back' onClick={() => backToBeforeStep()}>
                    <KeyboardBackspaceOutlinedIcon sx='color: var(--first-color)'/>
                    <span className='step_link'>Voltar na forma de pagamento padrão</span>
                </div>
                
                <h1>Informe os dados do seu novo cartão</h1>
                <p>Altere ou cancele seu plano quando quiser.</p>

                <div className="website_payment_new">
                    <div className="website_pay_container_new">
                        <div className='payment_content_new'>
                            <div className='big_cont_left_new'>
                                <div className='pay_cont_left_new'>
                                    <div className='pay_content_new opened'>
                                        {clientSecret ?
                                            <>
                                                {clientSecret && stripePromise && (
                                                    <Elements options={options} stripe={stripePromise}>
                                                        <CheckoutForm typeCheckout='reactivate-subscription' clientSecret={clientSecret} /*planSelectedId={planSelected.id}*/ nextStep={() => nextStep()}/>
                                                    </Elements>
                                                )}
                                            </>
                                        :
                                            <div className='box_plan_loader'>
                                                <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepReactivateChangeDefaultPayment;