import NavBarPlataform from '../../../components/NavBar/Platform';
import './styles.css'
import { Context } from '../../../context/AuthContext'
import React, { useEffect, useContext } from 'react'

function AdminTemplate({children}) {
    const { setMetaTagsOfPage } = useContext(Context)

    useEffect(() => {
        setMetaTagsOfPage('Dashboard', 'Gerencie a plataforma da Eduteka!')
    }, [])

    return (
        <div className='admin_content'>
            <NavBarPlataform children={children} />
        </div>
    )
}

export default AdminTemplate;