import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { styled/*, alpha*/ } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import api from '../../../services/api';
import { useState, useContext } from 'react';
import { Context } from '../../../context/AuthContext'
import './style.css'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: 'auto',
    backgroundColor: "var(--plat-two)",
    color:'var(--plat-six)',
    '&:hover': {
        backgroundColor: "var(--plat-five)",
    },

    [theme.breakpoints.down('md')]: { //abaixo de 900px
        margin:'20px 15px',
    },

    [theme.breakpoints.up('md')]: { //acima de 900px
        width: '100%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        // border:'1px solid var(--plat-two)',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '205px',
            '&:focus': {
                width: '300px',
            },
        },
    },

    color: 'var(--plat-six)',

    // [theme.breakpoints.down('md')]: {
    //     color: 'var(--first-color)',
    // },

    // [theme.breakpoints.up('md')]: {
    //     color: 'var(--plat-six)',
    // },
}));

function NavBarSearchArea({searchMode='blog'}) {
    const { showSearchBar, setShowSearchBar } = useContext(Context)
    const [searchText, setSearchText] = useState({search: ''})
    const [searchResults, setSearchResults] = useState([])

    //Sistema de Pesquisa (exibe resultados)
    function showListCoursesOnSearch(e) {
        let letters = e.target.value
        setSearchText({search:e.target.value})

        if (letters.length > 0) {
            let routeUrl = (searchMode === 'blog') ? 'pesquisa-blog' : '';
            routeUrl = (searchMode === 'website') ? 'pesquisa-site' : routeUrl;
            routeUrl = (searchMode === 'laboratory') ? 'pesquisa-site' : routeUrl;

            //Busca resultados das letras digitadas
            api.get(routeUrl, {params:{search: letters}})
                .then(async (response) => {
                    if (response.data.status === 'success') {
                        setSearchResults(response.data.data.results)
                    }
                })
                .catch((error) => {
                })
        } else {
            setTimeout(() => {
                setSearchResults([])
            }, 1000)
        }
    }

    function closeSearchBarBox() {
        setShowSearchBar(!showSearchBar)
        setSearchResults([])
        setSearchText({search: ''})
    }

    // function redirectToSearchResultsPage(e) {
    //     e.preventDefault()

    //     setShowSearchBar(!showSearchBar)
        
    //     let searchText = document.getElementById('search-courses').value //usar useRef
    //     history.push(`/pesquisa?search=${searchText}`)
    // }

    return (
        <div className='search_box' style={{display: showSearchBar ? "flex" : "none", background: 'var(--plat-one)', borderBottom: '1px solid var(--plat-two)'}}>
            <div className='box_close_srch_bar' onClick={() => closeSearchBarBox()}>
                <KeyboardBackspaceOutlinedIcon />
                <span className='close_search_bar'>Voltar</span>
            </div>
            
            <div className='search_inside_area'>
                <Search>
                    <SearchIconWrapper /*onClick={(e) => redirectToSearchResultsPage(e)}*/>
                        <SearchIcon />
                    </SearchIconWrapper>

                    {searchMode === 'blog' &&
                        <StyledInputBase placeholder="Pesquisar por Artigo..." inputProps={{ 'aria-label': 'search' }} onChange={showListCoursesOnSearch} value={searchText.search} /*maxLength="100"*/ />
                    }

                    {searchMode === 'website' &&
                        <StyledInputBase placeholder="Pesquisar por Curso..." inputProps={{ 'aria-label': 'search' }} onChange={showListCoursesOnSearch} value={searchText.search} /*maxLength="100"*/ />
                    }

                    {searchMode === 'laboratory' &&
                        <StyledInputBase placeholder="Pesquisar por Curso..." inputProps={{ 'aria-label': 'search' }} onChange={showListCoursesOnSearch} value={searchText.search} /*maxLength="100"*/ />
                    }

                    <div className="search-results" style={{display: `${searchResults.length >= 1 ? 'flex': 'none'}`}}>
                        <ul>
                            {searchResults.map((item, index) => {
                                return(
                                    <li key={index} onClick={() => setShowSearchBar(false)}>
                                        {searchMode === 'blog' &&
                                            <Link to={`/${item.link}`} onClick={() => closeSearchBarBox()}>
                                                <SearchOutlinedIcon color="neutral" sx={{marginRight:"12px"}} fontSize="small"/> {item.title}
                                            </Link>
                                        }
                                        
                                        {searchMode === 'website' &&
                                            <Link to={`/cursos/${item.link}`} onClick={() => closeSearchBarBox()}>
                                                <SearchOutlinedIcon color="neutral" sx={{marginRight:"12px"}} fontSize="small"/> {item.title}
                                            </Link>
                                        }

                                        {searchMode === 'laboratory' &&
                                            <Link to={`/curso/${item.link}`} onClick={() => closeSearchBarBox()}>
                                                <SearchOutlinedIcon color="neutral" sx={{marginRight:"12px"}} fontSize="small"/> {item.title}
                                            </Link>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Search>
            </div>
        </div>
    )
}

export default NavBarSearchArea