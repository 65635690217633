import { useEffect, useState, useRef, useContext } from 'react';
import './style.css';
import { Link, useParams, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Blog";
import api from "../../../services/api";
import Form from "../../../components/Form";
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import InputHidden from "../../../components/Form/InputHidden";
import { toast } from "react-toastify";
import { Context } from '../../../context/AuthContext'
import { Avatar, FormControl, FormHelperText, Grid, Box } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import './prismThemeDarcula.css'
import SingleLoader from '../../../components/Loaders/Divs/Blog/SingleLoader';
import { CustomLoadingButton } from '../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';
import SectionBeStudent from '../../../components/Website/SectionBeStudent'

function BlogSingle () {
    const { setMetaTagsOfPage, authenticated, handleLogout, showAlert, alertInTopClosed } = useContext(Context)
    const {articleSlug} = useParams()
    const pageCommNumber = useRef(1);
    const [isLastCommPage, setIsLastCommPage] = useState(true)
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [spinnerBtn2Loader, setSpinnerBtn2Loader] = useState(false)
    const [spinLoadChangeToOtherArticle, setSpinLoadChangeToOtherArticle] = useState(false)
    const [blogSingleLoader, setBlogSingleLoader] = useState(true)
    const [article, setArticle] = useState({})
    const [totalComments, setTotalComments] = useState(0)
    const [comments, setComments] = useState([])
    const history = useHistory()

    //Número de comentários inseridos pelo usuario ativo
    let numCommentsInserted = useRef(0)

    const [formComment, setFormComment] = useState({
        comment: '',
        blog_article_id: ''
    })

    const [fieldsError, setFieldsError] = useState({
        comment: '',
        blog_article_id: ''
    })

    function setComment(e) {
        setFormComment({...formComment, comment: e.target.value})
    }

    function setBlogArticleId(blogArticleId) {
        setFormComment({...formComment, blog_article_id: blogArticleId})
    }

    useEffect(() => {
        setSpinLoadChangeToOtherArticle(true)
        loadArticle()
        
        window.scrollTo(0, 0)
    }, [articleSlug])
    

//  Quando estamos no blog dentro de um artigo e clicamos em outro artigo na barrar lateral seria interessante colocar uma barra superior que mostre uma linha indo do canto esquero pro direito igual o youtube
//  quando insiro novos comentarios ele duplica se clico em ver mais....
    function loadArticle() {
        setSpinnerLoader(true)

        api.get(`blog/article/${articleSlug}`, {params:{page_comm_number:pageCommNumber.current}})
            .then((response) => {
                if (response.data.status === 'success') {
                    setMetaTagsOfPage(response.data.data.article.title, response.data.data.article.meta_description)
                    setArticle(response.data.data.article)
                    setBlogArticleId(response.data.data.article.id)
                    setComments(response.data.data.article.comments)
                    setTotalComments(response.data.data.total_comments)

                    pageCommNumber.current = pageCommNumber.current + 1;

                    if (response.data.data.article.last_comm_page === true) {
                        setIsLastCommPage(true)
                    } else {
                        setIsLastCommPage(false)
                    }

                    setBlogSingleLoader(false)
                    setSpinnerLoader(false)
                    setSpinLoadChangeToOtherArticle(false)
                } else {
                    history.push("/erro/404")
                }
            }).catch((error) => {
            })
    }

    function loadMoreComments() {
        setSpinnerBtn2Loader(true)

        api.get(`blog/article/comments/${articleSlug}`, {params:{page_comm_number:pageCommNumber.current, num_comments_inserted:numCommentsInserted.current}})
            .then((response) => {
                if (response.data.status === 'success') {
                    let commentsSt = comments.concat(response.data.data.comments);
                    setComments(commentsSt)

                    pageCommNumber.current = pageCommNumber.current + 1;

                    if (response.data.data.last_comm_page === true) {
                        setIsLastCommPage(true)
                    } else {
                        setIsLastCommPage(false)
                    }
                }

                setSpinnerBtn2Loader(false)
            }).catch((error) => {
                setSpinnerBtn2Loader(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function sendForm(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        await api.post('blog/article/comments', formComment)
            .then((response) => {
                if (response.data.status === 'success') {
                    numCommentsInserted.current++;
                    
                    toast.success(response.data.message)
                    
                    let commentsSt = [response.data.data.comment].concat(comments);
                    setComments(commentsSt)

                    setTotalComments(totalComments + 1)

                    setFormComment({...formComment, comment: ''});
                } else {
                    toast.error('Falha ao enviar o comentário!')
                }

                setSpinnerBtnLoader(false)
                setFieldsError({comment: '', blog_article_id: ''})
            }).catch((error) => {
                setSpinnerBtnLoader(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    const CommentItem = (props) => {
        return (
            <div className="blog_comm">
                <div className="b_comm_title">
                    {props.picture ?
                        <img src={props.picture} alt={props.name} title={props.name}/>
                    :
                        <Avatar sx={{ width: 40, height: 40, marginRight:'15px', boxShadow:"0px 3px 6px var(--shadow-color)" }} title={props.name}>{props.name.charAt(0).toUpperCase()}</Avatar>
                    }

                    <div className='b_comm_tit_cont'>
                        <div className='b_comm_tit_inside'>
                            <h5 className='usrname'>{props.name}</h5>
                            <span className="b_comm_date">{props.publication_date}</span>
                        </div>

                        <p className="b_comm_txt">{props.comment}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={`box_blog_art ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

                {blogSingleLoader ?
                    <SingleLoader/> 
                :    
                    <div className="blog_content_article">
                        {(article && !spinLoadChangeToOtherArticle) ?
                            <>
                                <div className='article_content'>
                                    <h1>{article.title}</h1>

                                    <div className='box_details_single'>
                                        <div className='box_author'>
                                            {article.author.picture ?
                                                <img src={article.author.picture} alt={article.author.name} title={article.author.name}/>
                                            :
                                                <Avatar sx={{ width: 40, height: 40, marginRight:'10px', boxShadow:"0px 3px 6px var(--shadow-color)" }} title={article.author.name}>{article.author.name.charAt(0).toUpperCase()}</Avatar>
                                            }
                                            
                                            <div>
                                                <h5>{article.author.name}</h5>
                                                <p className='author_descp'>{article.author.profession}</p>
                                            </div>
                                        </div>

                                        <span className='categbox'>
                                            <span className='pub_date'>{article.publication_date}</span>
                                            
                                            <Link className='categlk' to={`/${article.category_slug}/${article.subcategory_slug}`} style={{background:article.subcat_bg_color}}>{article.subcategory}</Link>
                                        </span>
                                    </div>
                                </div>

                                <div className="content_blog_html" dangerouslySetInnerHTML={{ __html: article.description}} />

                                <div className="box_single_merchan">
                                    <div className="box_merchandise">
                                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4035362438974963" crossOrigin="anonymous"></script>
                                        <ins className="adsbygoogle" style={{display:'block'}} data-ad-client="ca-pub-4035362438974963" data-ad-slot="1527393339" data-ad-format="auto" data-full-width-responsive="true"></ins>
                                        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
                                    </div>
                                </div>

                                <div className="blog_comments">
                                    <div className="blog_comm_form">
                                        <h2>O que você achou do artigo?</h2>
                                        <p>Ficou com alguma dúvida? Compartilhe ela aqui.</p>

                                        {authenticated ? 
                                            <Form>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                required
                                                                label="Deixe seu comentário"
                                                                name='comment'
                                                                {...(fieldsError.comment && {error: 'true'})}
                                                                multiline
                                                                rows={3}
                                                                maxRows={5}
                                                                onChange={setComment} 
                                                                value={formComment.comment} 
                                                                InputLabelProps={{
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                                fullWidth
                                                                inputProps={{ maxLength: 500 }}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.comment}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <InputHidden maxLength='15' name='blog_article_id' value={formComment.blog_article_id} />

                                                    <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                                        <CustomLoadingButton
                                                            onClick={sendForm}
                                                            endIcon={<SendOutlinedIcon />}
                                                            loading={spinnerBtnLoader}
                                                            loadingPosition="end"
                                                            variant="outlined"
                                                            color="secondary"
                                                            fullWidth
                                                        >
                                                            <span>Enviar</span>
                                                        </CustomLoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        :
                                            <div className='box_comments_form'>
                                                <div className='box_alert_comments'>
                                                    <p>Faça login para enviar seu comentário</p>
                                                </div>
                                            </div>
                                        }

                                        <div className='box_comments_form'>
                                            <h5>{totalComments} Comentário(s)</h5>

                                            <div className="blog_comm_all">
                                                {comments.map((item, index) => {
                                                    return <CommentItem key={index} name={item.name} picture={item.picture} publication_date={item.publication_date} comment={item.comment} />
                                                })}
                                            </div>

                                            {!spinnerLoader ?
                                                !isLastCommPage &&
                                                    <Box display="flex" justifyContent="flex-start" alignItems="center" marginTop="30px">
                                                        <CustomLoadingButton
                                                            onClick={() => loadMoreComments()}
                                                            endIcon={<MoreHorizIcon />}
                                                            loading={spinnerBtn2Loader}
                                                            loadingPosition="end"
                                                            variant="outlined"
                                                            size="medium"
                                                            color="secondary"
                                                        >
                                                            <span>Carregar Mais</span>
                                                        </CustomLoadingButton>
                                                    </Box> 
                                            :
                                                <Box display="flex" justifyContent="flex-start" alignItems="center" marginTop="30px">
                                                    <SpinnerLoader heightVal="auto" justifyContentVal="flex-start" borderColorImp='var(--plat-seven)'/>
                                                </Box> 
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                            </>
                        :
                            <SpinnerLoader borderColorImp='var(--plat-seven)'/>
                        }
                    </div>
                }
                
                <Sidebar/>
            </div>
            
            <SectionBeStudent/>
        </>
    )
}

export default BlogSingle;