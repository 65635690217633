import styled from "styled-components";
import { TextField } from '@mui/material'

const CustomTextField = styled(TextField)({
    backgroundColor: "var(--plat-two)",
    borderRadius: "4px",
    "& .MuiOutlinedInput-notchedOutline": {
        //border: "none",
        borderColor: "var(--plat-eight)"
    },
    "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            //border: "none",
            borderColor: "var(--plat-eight)"
        }
    },
    '& label.Mui-focused': {
        color: 'var(--first-color)',
    },
    '& label.Mui-disabled': {
        color: 'var(--plat-seven)'
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "var(--plat-seven)",
        background: "var(--plat-one)"
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        backgroundColor: "var(--plat-four)",
    },
    "& .MuiOutlinedInput-root": {
        color: 'var(--plat-six)',
    }
});

const inputLabelStyles = {
    color: 'var(--plat-seven)'
};

export { CustomTextField, inputLabelStyles }