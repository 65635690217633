import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import { validateDataBack } from '../../../helpers'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import InputFile from '../../../components/Form/InputFile';
import { Editor } from '@tinymce/tinymce-react';
import ListComment from '../../../components/Platform/ListComment';
import Comment from '../../../components/Platform/ListComment/Comment';
import InputFileImage from '../../../components/Form/InputFile';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, MenuItem, TextField } from "@mui/material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function PublicationsForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const [loaderTab3, setLoaderTab3] = useState(false)
    const [loaderTab4, setLoaderTab4] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)
    const editorRef = useRef(null);
    let fileImageTwo = useRef('')

    const [formPublicationsFile, setFormPublicationsFile] = useState({thumb:''})
    const [formPublications, setFormPublications] = useState({
        title: '',
        description: '',
        status: '',
        thumb: '',
        blog_subcategory_id: '',
        publication_date: '',
        author_user_id: '',
        meta_description: ''
    })

    const [formGalleryImages, setFormGalleryImages] = useState([
        // {id: 1, url:'http://localhost:8005/storage/images/users/thumb/jhEZAye308MAyg7iYcPeLFuiSahZt67QcWwYNdCS.jpg', copied: false}
    ])

    const [fieldsError, setFieldsError] = useState({
        title: '',
        description: '',
        status: '',
        thumb: '',
        blog_subcategory_id: '',
        publication_date: '',
        author_user_id: '',
        meta_description: ''
    })

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const initialFormPublicDescription = useRef('')

    const [listComments, setListComments] = useState([])
    const [tableHistrcCell, setTableHistrcCell] = useState([])
    const [subcategorysOptions, setSubcategorysOptions] = useState([])
    const [authorsOptions, setAuthorsOptions] = useState([])

    const thumbValidations = useRef({
        required: {message:'Informe um thumb!'},
    })

    const title = useRef({
        name: 'Publicações',
        description_insert: 'Insira novas publicacões no blog',
        description_update: 'Altere publicações do blog',
        link_from: '/admin/publicacoes'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)
            setLoaderTab3(true)
            setLoaderTab4(true)

            api.get(`admin/publicacoes/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setLoaderTab3(false)
                        setLoaderTab4(false)
                        setFormPublications(response.data.data.blog_article)
                        initialFormPublicDescription.current = response.data.data.blog_article.description
                        setFormGalleryImages(response.data.data.files)
                        setListComments(response.data.data.comments)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de inserções/remoções
            api.get(`admin/publicacoes/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca subcategorias do blog para por no select>options
            api.get(`admin/publicacoes/subcategorias/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setSubcategorysOptions(response.data.data.subcategorys)
                        setAuthorsOptions(response.data.data.authors)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de alterações
            api.get('admin/publicacoes/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
            
            //Busca subcategorias do blog para por no select>options
            api.get('admin/publicacoes/subcategorias')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setSubcategorysOptions(response.data.data.subcategorys)
                        setAuthorsOptions(response.data.data.authors)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setTitle(e) {
        setFormPublications({...formPublications, title: e.target.value})
    }

    function setDescription(e) {
        initialFormPublicDescription.current = e.target.getContent()
        // setFormPublications({...formPublications, description: e.target.getContent()})
    }

    function setStatus(e) {
        setFormPublications({...formPublications, status: e.target.value})
    }

    function setMetaDescription(e) {
        setFormPublications({...formPublications, meta_description: e.target.value})
    }

    function setThumb(e) {
        if (e.target.files.length === 0) {
            return;
        }

        let file = e.target.files[0]

        let reader = new FileReader()
        reader.readAsDataURL(file)
 
        reader.onload = () => {
            setFormPublications({...formPublications, thumb: reader.result})
            setFormPublicationsFile({thumb: file})
        }
    }

    function setBlogSubcategoryId(e) {
        setFormPublications({...formPublications, blog_subcategory_id: e.target.value})
    }

    function setPublicationDate(e) {
        setFormPublications({...formPublications, publication_date: e.target.value})
    }

    function setAuthorUserId(e) {
        setFormPublications({...formPublications, author_user_id: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()

        // if (!formPublications.description || formPublications.description === '') {
        if(!initialFormPublicDescription.current || initialFormPublicDescription.current === '') {
            toast.error('Informe o conteúdo da publicação!')
        // } else if (formPublications.description.length < 30) {
        } else if (initialFormPublicDescription.current.length < 30) {
            toast.error('O conteúdo da publicação deve ter no mínimo 30 caracteres!')
        // } else if (formPublications.description.length > 30000) {
        } else if (initialFormPublicDescription.current.length > 30000) {
            toast.error('O conteúdo da publicação deve ter no máximo 30000 caracteres!')
        } else {
            setLoading(true)

            let formData = new FormData()
            formData.append('thumb', formPublicationsFile.thumb)
            formData.append('title', formPublications.title)
            formData.append('description', initialFormPublicDescription.current)
            // formData.append('description', formPublications.description)
            formData.append('status', formPublications.status)
            formData.append('blog_subcategory_id', formPublications.blog_subcategory_id)
            formData.append('publication_date', formPublications.publication_date)
            formData.append('author_user_id', formPublications.author_user_id)
            formData.append('meta_description', formPublications.meta_description)

            await api.post('admin/publicacoes', formData)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message)
                        setFormPublications({title: '', description: '', status: '', thumb: '', blog_subcategory_id: '', publication_date: '', author_user_id: '', meta_description: ''})
                        setFormPublicationsFile({thumb:''})
                        // setListPerfis([])

                        history.push("/admin/publicacoes")
                    } else {
                        toast.error(response.data.message)
                    }

                    setFieldsError({title: '', description: '', status: '', thumb: '', blog_subcategory_id: '', publication_date: '', author_user_id: '', meta_description: ''})
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)

                    if (error.response.status === 422) {
                        setFieldsError(error.response.data.data)
                        toast.error(error.response.data.message)
                    } else if (error.response.status === 401) {
                        handleLogout()
                    } else {
                        toast.error(error.response.data.message)
                    }
                })
        }
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData()
       
        if (formPublicationsFile?.thumb) {
            formData.append('thumb', formPublicationsFile.thumb)
        }
        
        formData.append('title', formPublications.title)
        formData.append('description', initialFormPublicDescription.current)
        // formData.append('description', formPublications.description)
        formData.append('status', formPublications.status)
        formData.append('blog_subcategory_id', formPublications.blog_subcategory_id)
        formData.append('publication_date', formPublications.publication_date)
        formData.append('author_user_id', formPublications.author_user_id)
        formData.append('meta_description', formPublications.meta_description)

        await api.post(`admin/publicacoes/${regId}`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/publicacoes")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({title: '', description: '', status: '', thumb: '', blog_subcategory_id: '', publication_date: '', author_user_id: '', meta_description: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleListDelete(e, commentId) {
        e.preventDefault()
        setLoading(true)

        await api.delete(`admin/publicacoes/${regId}/comment/${commentId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    setListComments(listComments.filter(item => item.id !== commentId))
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    validateDataBack(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    function loadImage(e) {
        if (e.target.files.length === 0) {
            return;
        }

        let file = e.target.files[0]

        let reader = new FileReader()
        reader.readAsDataURL(file)

        //Validações de Front
        const allowedExtensions = ['image/jpg', 'image/jpeg', 'image/png'];
        if (!allowedExtensions.includes(file.type)) {
            toast.warning('Formato inválido! Formatos permitidos: jpg, jpeg, png')
            return
        }
 
        reader.onload = () => {
            // let imgBlob = URL.createObjectURL(file)

            //Salva foto adicionada no back-end instantaneamente (se der certo acrescenta na tela)
            saveImage(file/*, imgBlob*/)
        }
    }

    function setCopied(imageId) {
        let galleryStateChanged = formGalleryImages.map((img) => {
            if (img.id === imageId) {
                img.copied = true;
                return img
            } else {
                img.copied = false;
                return img
            }
        })

        setFormGalleryImages(galleryStateChanged)
    }

    async function saveImage(imgObj/*, imgBlob*/) {
        let formData = new FormData()
        formData.append('file_image', imgObj)

        setLoading(true)

        await api.post(`admin/publicacoes/${regId}/send-image`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    let fileObj = response.data.data.file
                    //Adiciona foto na galeria do front-end
                    setFormGalleryImages([...formGalleryImages, ...[{id: fileObj.id, url: fileObj.url, copied: false}]])
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function deleteImage(fileId) {
        setLoading(true)

        await api.delete(`admin/publicacoes/${regId}/remove-image/${fileId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    //remove foto da galeria do front-end
                    setFormGalleryImages(formGalleryImages.filter((img) => img.id !== fileId))
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    function clickOnInputFile() {
        fileImageTwo.current.click()
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<SourceOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Conteúdo" {...a11yProps(1)} />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(2)} />
                                
                                {regId &&
                                    <CustomTab icon={<BrokenImageOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Imagens" {...a11yProps(3)} />
                                }

                                {regId &&
                                    <CustomTab icon={<CommentOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Comentários" {...a11yProps(4)} />
                                }
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Título"
                                                            onChange={setTitle}
                                                            value={formPublications.title} 
                                                            name='title'
                                                            autoComplete='off'
                                                            {...(fieldsError.title && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.title}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Categoria/Subcategoria"
                                                            {...(fieldsError.blog_subcategory_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="blog_subcategory_id"
                                                            onChange={setBlogSubcategoryId} 
                                                            value={formPublications.blog_subcategory_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {subcategorysOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.blog_subcategory_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formPublications.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Autor"
                                                            {...(fieldsError.author_user_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="author_user_id"
                                                            onChange={setAuthorUserId} 
                                                            value={formPublications.author_user_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {authorsOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.author_user_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            label="Data de publicação"
                                                            type="datetime-local"
                                                            size="medium"
                                                            name="publication_date"
                                                            // defaultValue="2017-05-24"
                                                            {...(fieldsError.publication_date && {error: 'true'})}
                                                            onChange={setPublicationDate}
                                                            value={formPublications.publication_date}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.publication_date}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Meta descrição"
                                                            onChange={setMetaDescription}
                                                            {...(!regId && {helperText: "Descrição exibida nas pesquisas do google (SEO)"})}
                                                            value={formPublications.meta_description} 
                                                            name='meta_description'
                                                            autoComplete='off'
                                                            {...(fieldsError.meta_description && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    color: 'var(--plat-twelve)'
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 300 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.meta_description}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                                    <FormControl variant="standard" fullWidth>
                                                        {(!regId || !formPublications.thumb) ?
                                                            <InputFile 
                                                                name='thumb' 
                                                                description='Thumb' 
                                                                onChange={setThumb} 
                                                                value={formPublications.thumb} 
                                                                validations={thumbValidations.current}
                                                            />
                                                        :
                                                            <InputFileImage 
                                                                value={formPublications.thumb} 
                                                                name='thumb' 
                                                                description='Thumb' 
                                                                onChange={setThumb}
                                                            />
                                                        }

                                                        <FormHelperText>
                                                            {fieldsError.thumb}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab4 ?
                                    <div className='editor_rich_text'>
                                        <span className="inpdescpt">Conteúdo</span>

                                        {/* No help do editor de texto tem a documentação com todos os plugins gratuitos, nao apliquei todos que existem, apenas os principais (https://www.tiny.cloud/docs/tinymce/6/image/) */}
                                        <Editor
                                            apiKey='zzj6wp4jzuygxnabbci5mqkzqwwce1ju40x7kzle01w00rxc'
                                            name='description'
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            initialValue={initialFormPublicDescription.current}
                                            onChange={(e) => setDescription(e)} 
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'help', 'wordcount', 'codesample',
                                                    'emoticons'
                                                ],
                                                toolbar: 'undo redo | link image media | codesample blockquote | emoticons | blocks fontsize | ' +
                                                    'bold italic underline strikethrough forecolor | alignleft aligncenter alignright alignjustify | ' +
                                                    'bullist numlist outdent indent | ' +
                                                    'table tabledelete | ' + 
                                                    'charmap | preview | anchor | searchreplace | visualblocks | ' +
                                                    //'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol
                                                    'removeformat | wordcount | insertdatetime | code | help',
                                                a11y_advanced_options: true,
                                                content_style: 'body { font-family:AlbertSans, Roboto, Arial; font-size:18px } p{margin-bottom: 20px; font-size: 18px; color: rgba(0, 0, 0, 0.75);} h1, h2, h3, h4, h5, h6{margin-bottom: 30px; margin-top: 60px;} p a{margin-bottom: 10px !important; color: rgba(4, 170, 96, 1); text-decoration: underline;} ul{margin-left: 35px;} ul li{margin: 10px 0px;font-size: 18px;} img{height: auto;} table.tbl_imgs{background-color: rgba(18,18,18,.05); border: 1px solid #2A2A2A;} img.sizexs_1{width: 20%;} img.sizexs_2{ width: 40%; } img.sizexs_3{ width: 60%; } img.sizexs_4{ width: 80%; } img.sizexs_5{ width: 100%; } blockquote{padding: 10px 20px; border-left: 5px solid #EEEEEE;} blockquote p{margin-bottom: 0 !important;} pre[class*="language-"]{padding: 25px; border-left: 5px solid rgba(1, 254, 135, 1);} :not(pre) > code[class*="language-"], pre[class*="language-"]{background: rgba(39, 41, 62, 1);} code[class*="language-"], pre[class*="language-"] {color: #f8f8f2;} @media (min-width: 600px) {img.sizesm_1{ width: 20%; } img.sizesm_2{ width: 40%; } img.sizesm_3{ width: 60%; } img.sizesm_4{ width: 80%; } img.sizesm_5{ width: 100%; }} @media (min-width: 900px) {img.sizemd_1{ width: 20%; } img.sizemd_2{ width: 40%; } img.sizemd_3{ width: 60%; } img.sizemd_4{ width: 80%; } img.sizemd_5{ width: 100%; }} @media (min-width: 1200px) {img.sizelg_1{ width: 20%; } img.sizelg_2{ width: 40%; } img.sizelg_3{ width: 60%; } img.sizelg_4{ width: 80%; } img.sizelg_5{ width: 100%; }} @media (min-width: 1536px) {img.sizexl_1{ width: 20%; } img.sizexl_2{ width: 40%; } img.sizexl_3{ width: 60%; } img.sizexl_4{ width: 80%; } img.sizexl_5{ width: 100%; }}'
                                                // cloudChannel='dev' 
                                                

                                                // UPLOAD DE IMAGEM VIA BLOB (MAS NAO VOU FAZER ASSIM PQ SE NAO FICA MUITO PESADO O ARMAZENAMENTO DE BANCO DE DADOS)
                                                // image_title: true,
                                                // automatic_uploads: true,
                                                // file_picker_types: 'image',
                                                // file_picker_callback: (cb, value, meta) => {
                                                //     const input = document.createElement('input');
                                                //     input.setAttribute('type', 'file');
                                                //     input.setAttribute('accept', 'image/*');

                                                //     input.addEventListener('change', (e) => {
                                                //         const file = e.target.files[0];

                                                //         const reader = new FileReader();
                                                //         reader.addEventListener('load', () => {
                                                //             const id = 'blobid' + (new Date()).getTime();
                                                //             const blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
                                                //             const base64 = reader.result.split(',')[1];
                                                //             const blobInfo = blobCache.create(id, file, base64);
                                                //             blobCache.add(blobInfo);

                                                //             cb(blobInfo.blobUri(), { title: file.name });
                                                //         });
                                                        
                                                //         reader.readAsDataURL(file);
                                                //     });

                                                //     input.click();
                                                // },   
                                            }}
                                        />
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <>
                                {!loaderTab3 ?
                                    <div className='gallery_publications'>

                                        {formGalleryImages.map((image, index) => {
                                            return (
                                                <div key={index} className='box_gallery_img'>
                                                    <div className='box_inside_gall'>
                                                        <div className='icons_gall_pub'>
                                                            {!image?.copied ?
                                                                <CopyToClipboard text={image.url} onCopy={() => setCopied(image.id)}> 
                                                                    <ContentCopyOutlinedIcon titleAccess='Copiar endereço' fontSize='medium' color='fifthcolor'/>
                                                                </CopyToClipboard>
                                                            :
                                                                <CheckCircleOutlinedIcon titleAccess='Copiado' fontSize='medium' color='fifthcolor'/>
                                                            }

                                                            <DeleteOutlinedIcon titleAccess='Remover' fontSize='medium' color='fifthcolor' onClick={() => deleteImage(image.id)} />
                                                        </div>
                                                    </div>
                                                    
                                                    <img src={image.url} alt='Imagem' title='Imagem'/>
                                                </div>
                                            )
                                        })}

                                        <div className='box_gallery_img send_image_pub' onClick={() => clickOnInputFile()}>
                                            <input type="file" ref={fileImageTwo} id='file_image_2' /*name='thumb'*/ onChange={loadImage} style={{display:'none'}}/>

                                            {/* <label for="file_image_2"> */}
                                                <AddPhotoAlternateOutlinedIcon titleAccess='Inserir imagem' fontSize='large' color='fifthcolor'/>
                                            {/* </label> */}
                                        </div>
                                        
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={4}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_list'>
                                        <ListComment>
                                            {(listComments.length >= 1) ?
                                                <>
                                                    {listComments.map((item, index) => {
                                                        return <Comment key={index} publicationDate={item.publication_date} comment={item.comment} name={item.name} id={item.id} handleListDelete={handleListDelete} />
                                                    })}
                                                </>
                                            :
                                                <p className='mdit'>Nenhum comentário encontrado nessa publicação!</p>
                                            }
                                        </ListComment>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default PublicationsForm;