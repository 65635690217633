import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import UsuariosTable from '../Usuarios/UsuariosTable'
import ProfessoresTable from '../Professores/ProfessoresTable'
import api from "../../../services/api"
import PerfisTable from "../Perfis/PerfisTable"
import NewsletterTable from "../NewsLetter/NewsletterTable"
import SupportTable from "../Support/SupportTable"
import AlertsTable from "../Alerts/AlertsTable"
import CategorysTable from "../Categorys/CategorysTable"
import SubcategorysTable from "../Subcategorys/SubcategorysTable"
import PublicationsTable from "../Publicacoes/PublicationsTable"
import TableLoader from "../../../components/Loaders/Divs/Platform/TableLoader"
import EnqueteTable from "../Enquete/EnqueteTable"
import PlansTable from "../Plans/PlansTable"
import { Context } from '../../../context/AuthContext'
import { useContext } from 'react'
import NotificationsTable from "../Notifications/NotificationsTable"
import CategorysWebsiteTable from "../CategorysWebsite/CategorysWebsiteTable"
import SubcategorysWebsiteTable from "../SubcategorysWebsite/SubcategorysWebsiteTable"
import CursosTable from "../Cursos/CursosTable"
import AulasTable from "../Aulas/AulasTable"
import NewsletterArticlesTable from '../NewsLetterArticles/NewsletterArticlesTable'
import DesafiosTable from '../Desafios/DesafiosTable'
import TopicosTable from '../ForumTopicos/TopicosTable'

function AdminTableTemplate(props) {
    const {pageSlug} = useParams()
    const history = useHistory()
    const [tableCells, setTableCells] = useState([])
    const [tableColls, setTableColls] = useState([])
    const [tableCollsMobile, setTableCollsMobile] = useState([])
    const [changePageLoader, setChangePageLoader] = useState(false)
    const { handleLogout } = useContext(Context)

    function redirectTo(e, link){
        e.preventDefault();

        history.push(link);
    }

    useEffect(() => {
        async function loadResults() {
            setChangePageLoader(true)
            
            await api.get(`admin/${pageSlug}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setChangePageLoader(false)

                        setTableColls(response.data.data.collumns)
                        setTableCollsMobile(response.data.data.collumns_mobile)
                        setTableCells(response.data.data.cells)
                    } else if (response.status === 403) {
                        history.push('/erro/403');
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    } else if (error.response.status === 403) {
                        history.push('/erro/403');
                    }
                })
        }

        loadResults()
        
        window.scrollTo(0, 0)
    }, [pageSlug])

    return (
        <div className='admin_content_center'>

            {changePageLoader ?
                <TableLoader/>
            :
                <>
                    {/* {pageSlug === 'dashboard' &&
                        <UsuariosTable tableColls={tableColls} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    } */}

                    {pageSlug === 'professores' &&
                        <ProfessoresTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'alunos' &&
                        <UsuariosTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'perfis' &&
                        <PerfisTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'newsletter' &&
                        <NewsletterTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'newsletter-articles' &&
                        <NewsletterArticlesTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'suporte' &&
                        <SupportTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'avisos' &&
                        <AlertsTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'categorias' &&
                        <CategorysTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'subcategorias' &&
                        <SubcategorysTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'publicacoes' &&
                        <PublicationsTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'enquetes' &&
                        <EnqueteTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'planos' &&
                        <PlansTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'notificacoes' &&
                        <NotificationsTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'categorias-website' &&
                        <CategorysWebsiteTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'subcategorias-website' &&
                        <SubcategorysWebsiteTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'cursos' &&
                        <CursosTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'aulas' &&
                        <AulasTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'desafios' &&
                        <DesafiosTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }

                    {pageSlug === 'topicos' &&
                        <TopicosTable tableColls={tableColls} tableCollsMobile={tableCollsMobile} tableCells={tableCells} setTableCells={setTableCells} redirectTo={redirectTo}/>
                    }
                </>
            }
        
        </div>
    )
}

export default AdminTableTemplate