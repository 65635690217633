import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { CustomTooltipDark } from '../../MaterialCustom/General/Tooltip/CustomTooltip';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import { FormControl, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import api from '../../../services/api';
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { toast } from 'react-toastify';

function StepCreateUser ({setUser, planId, nextStep, stepNow = null, stepTotals = null, dark = true}) {
    const [userLogin, setUserLogin] = useState({
        account_name: '',
        account_email: '',
        account_email_repeat: '',
        account_password: ''
    })

    const [fieldsErrorStepOne, setFieldsErrorStepOne] = useState({
        account_name: '',
        account_email: '',
        account_email_repeat: '',
        account_password: ''
    })

    const [showPassword, setShowPassword] = useState(false)
    const [stepLoader, setStepLoader] = useState(false)

    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleMouseDownPassword(event) {
        event.preventDefault();
    }

    async function sendStep() {
        setStepLoader(true)
        await createNewUser()
    }

    async function createNewUser() {
        if (userLogin.account_email && userLogin.account_email_repeat && (userLogin.account_email !== userLogin.account_email_repeat )) {
            setFieldsErrorStepOne({...fieldsErrorStepOne, ...{
                account_email: ['Os campos de e-mail devem ser iguais!'], 
                account_email_repeat: ['Os e-mails devem ser iguais!']
            }})

            setStepLoader(false)
            return
        }

        let formData = new FormData();
        formData.append('account_name', userLogin.account_name)
        formData.append('account_email', userLogin.account_email)
        formData.append('account_email_repeat', userLogin.account_email_repeat)
        formData.append('account_password', userLogin.account_password)
        formData.append('plan_id', planId)

        await api.post('matricule-se/criar-login', formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    setUser({
                        id: response.data.data.id,
                        name: userLogin.account_name
                    })

                    nextStep()
                }

                setFieldsErrorStepOne({account_name: '', account_email: '', account_email_repeat: '', account_password: ''});
                setStepLoader(false)
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    setFieldsErrorStepOne(error.response.data.data);
                    toast.error(error.response.data.message)
                }

                setStepLoader(false)
            })
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>Crie um login de acesso!</h1>
                <p>Use esses dados para acessar a área do aluno.</p>

                <div className='payment_select_pay'>
                    <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                        <CustomTextField
                            required
                            label="Nome completo"
                            onChange={(e) => setUserLogin({...userLogin, account_name: e.target.value})}
                            value={userLogin.account_name} 
                            name='account_name'
                            autoComplete='off'
                            defaultValue=""
                            {...(fieldsErrorStepOne.account_name && {error: 'true'})}
                            InputLabelProps={{
                                sx: {
                                ...inputLabelStyles,
                                },
                            }}
                            size="medium"
                            fullWidth
                            inputProps={{ maxLength: 200}}
                        />

                        {fieldsErrorStepOne.account_name &&
                            <FormHelperText>
                                {fieldsErrorStepOne.account_name}
                            </FormHelperText>
                        }
                    </FormControl>

                    <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                        <CustomTextField
                            required
                            label="E-mail"
                            name='account_email'
                            // onCopy={(e)=>{
                            //     e.preventDefault()
                            //     return false;
                            // }}
                            // disabled
                            {...(fieldsErrorStepOne.account_email && {error: 'true'})}
                            onChange={(e) => setUserLogin({...userLogin, account_email: e.target.value})} 
                            value={userLogin.account_email}
                            autoComplete='off'
                            size="medium"
                            fullWidth
                            inputProps={{ maxLength: 200 }}
                            InputLabelProps={{
                                sx: {
                                ...inputLabelStyles,
                                },
                            }}
                        />

                        {fieldsErrorStepOne.account_email &&
                            <FormHelperText>
                                {fieldsErrorStepOne.account_email}
                            </FormHelperText>
                        }
                    </FormControl>

                    <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                        <CustomTextField
                            required
                            label="Confirme seu e-mail"
                            name='account_email_repeat'
                            onPaste={(e)=>{
                                e.preventDefault()
                                return false;
                            }}
                            autoComplete='off'
                            // disabled
                            {...(fieldsErrorStepOne.account_email_repeat && {error: 'true'})}
                            onChange={(e) => setUserLogin({...userLogin, account_email_repeat: e.target.value})} 
                            value={userLogin.account_email_repeat}
                            size="medium"
                            fullWidth
                            inputProps={{ maxLength: 200 }}
                            InputLabelProps={{
                                sx: {
                                ...inputLabelStyles,
                                },
                            }}
                        />

                        {fieldsErrorStepOne.account_email_repeat &&
                            <FormHelperText>
                                {fieldsErrorStepOne.account_email_repeat}
                            </FormHelperText>
                        }
                    </FormControl>

                    <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}> 
                        <CustomTextField 
                            id="outlined-adornment-password4" 
                            type={showPassword ? 'text' : 'password'} 
                            label="Senha"
                            size="medium"
                            name='account_password'
                            autoComplete="new-password"
                            {...(fieldsErrorStepOne.account_password && {error: 'true'})}
                            fullWidth
                            onChange={(e) => setUserLogin({...userLogin, account_password: e.target.value})} 
                            value={userLogin.account_password}
                            InputLabelProps={{
                                sx: {
                                ...inputLabelStyles,
                                },
                            }}
                            InputProps={{ 
                                maxLength: 200,
                                endAdornment:
                                    <>
                                        <CustomTooltipDark
                                            placement="top-end"
                                            title={
                                                <>
                                                    <span className="tooltiptext">
                                                        <h6>A senha deve ter: </h6>

                                                        <ul>
                                                            <li>no mínimo 6 caracteres</li>
                                                            <li>no mínimo um número</li>
                                                            <li>letras maiúsculas e minúsculas</li>
                                                            <li>caractere especial</li>
                                                        </ul>
                                                    </span>
                                                </>
                                            }
                                        >
                                            <IconButton>
                                                <NotListedLocationOutlinedIcon color="sixthcolor" />
                                            </IconButton>
                                        </CustomTooltipDark>

                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                {showPassword ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                            </IconButton>
                                        </InputAdornment>
                                    </>
                            }}
                        />

                        {fieldsErrorStepOne.account_password &&
                            <FormHelperText>
                                {fieldsErrorStepOne.account_password}
                            </FormHelperText>
                        }
                    </FormControl>
                </div>

                <CustomLoadingButton
                    onClick={() => sendStep()}
                    loading={stepLoader}
                    // loadingPosition="end"
                    // endIcon=""
                    variant="contained"
                    sx={{width: '350px'}}
                    color='secondary'
                    size="large"
                >
                    <span>Próximo</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepCreateUser;