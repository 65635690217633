import './style.css'
import RowCourseList from '../../../components/Laboratory/RowCourseList';
import { useEffect, useRef, useState } from 'react';
import ModalCourseDtls from '../../../components/Modal/Laboratory/ModalCourseDtls';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import FavoriteLoader from '../../../components/Loaders/Divs/Laboratory/FavoriteLoader';
import { useContext } from 'react';
import { Context } from '../../../context/AuthContext';

function LaboratoryFavorite() {
    const { setMetaTagsOfPage, handleLogout } = useContext(Context)
    const history = useHistory()
    const [favLoader, setFavLoader] = useState(true)
    const [favoriteCourses, setFavoriteCourses] = useState([])
    const [modalCourseDtlsStatus, setModalCourseDtlsStatus] = useState(false)
    const [arrowsDisplay, setArrowDisplay] = useState(true)

    let courseMouseHover = useRef('')
    let modalBlock = useRef(false);

    useEffect(() => {
        setMetaTagsOfPage('Laboratório', 'Uma plataforma de ensino feita para designers e programadores!')
        // console.log('render component LaboratoryHome')
        loadFavorites()

        window.scrollTo(0, 0)

        resetCarousel()
        window.addEventListener('resize', resetCarousel);

        return () => {
            window.removeEventListener('resize', resetCarousel);
        }
    }, [])

    const resetCarousel = () => {
        let carousels = document.getElementsByClassName('row_group')

        if (carousels?.length) {
            for(let i = 0; i <= carousels?.length; i++) {
                if (carousels[i]) {
                    carousels[i].scrollLeft = 0
                }
            }
        }
    }

    async function loadFavorites() {
        await api.get('favorite-lab')
            .then((response) => {
                if (response.data.status === 'success') {
                    setFavoriteCourses(response.data.data.favorite_courses)
                    setFavLoader(false)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    function redirectTo(linkUrl) {
        if (linkUrl) {
            history.push(linkUrl)
        }
    }

    return (
        <div className="course_videos">    
            {favLoader ?
                <FavoriteLoader/>
            :
                <div className='crs_vid_content_all'>
                    {(!favoriteCourses.length || favoriteCourses.length === 0) ?
                        <div className="row_course_list">
                            <h2>Meus cursos favoritos</h2>
                            <p className='fav_courses_null'>Nenhum curso foi adicionado aos favoritos!</p>
                        </div>
                    :
                        <RowCourseList redirectTo={redirectTo} arrowsDisplay={arrowsDisplay} rowTitle="Meus cursos favoritos" rowId="1" courses={favoriteCourses} modalCourseDtlsStatus={modalCourseDtlsStatus} setModalCourseDtlsStatus={setModalCourseDtlsStatus} courseMouseHover={courseMouseHover} modalBlock={modalBlock} />
                    }
                </div>
            }

            <ModalCourseDtls setArrowDisplay={setArrowDisplay} redirectTo={redirectTo} modalStatus={modalCourseDtlsStatus} setModalStatus={setModalCourseDtlsStatus} modalBlock={modalBlock} courseMouseHover={courseMouseHover} />
        </div>
    )
}

export default LaboratoryFavorite;