import { useContext, useState } from 'react';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import api from '../../../services/api';
import { Context } from '../../../context/AuthContext';

function StepAlertPayment ({nextStep, stepNow = null, stepTotals = null, dark = true, typeMsg = null, setDefaultCard = null}) {
    const [stepLoader, setStepLoader] = useState(false)
    const { handleLogout } = useContext(Context)

    async function retrieveDefaultCard() {
        await api.get('card-default')
            .then((response) => {
                if (response.data.status === 'success') {
                    setDefaultCard(response.data.data.default_card)

                    nextStep()
                }

                setStepLoader(false)
            }).catch((error) => {
                setStepLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function sendStep() {
        setStepLoader(true)

        if (typeMsg === 'reactivate') {
            await retrieveDefaultCard()
        } else {
            setTimeout(() => {
                nextStep()
                setStepLoader(false)
            }, 300)
        }
    }

    return (
        <div className='payment_box_big_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>Falta bem pouquinho!</h1>
                <p>Faça o pagamento para {typeMsg === 'reactivate' ? 'reativar' : 'iniciar'} sua assinatura, lembrando que você pode alterar ou cancelar o plano quando quiser.</p>

                <CustomLoadingButton
                    onClick={() => sendStep()}
                    loading={stepLoader}
                    variant="contained"
                    fullWidth
                    color='secondary'
                    size="large"
                >
                    <span>Próximo</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepAlertPayment;