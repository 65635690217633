import '../../../style.css'
import './style.css'

function CategoryOrSubcategoryLoaderContent() {
    const Article = () => {
        return (
            <div className='loader_course_catsubc'>
                <div className='pulse'>
                    <div className="loader_rectangle_crs_catsubc"></div>
                </div>
            </div>
        )
    }

    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='loader_box_cat_subc_ct'>
                    <div className='pulse'>
                        <div className='loader_box_in_left'></div>
                    </div>
                    
                    <div className='loader_box_in_right'>
                        <Article />
                        <Article />
                        <Article />
                        <Article />
                        <Article />
                        <Article />
                        <Article />
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default CategoryOrSubcategoryLoaderContent;