import styled from "styled-components";
import { TextField } from '@mui/material'

const CustomTextFieldStandard = styled(TextField)({
    '& .MuiInput-underline:before': { borderBottomColor: 'var(--plat-seven);' },
    // '& .MuiInput-underline:after': { borderBottomColor: 'orange' },
    '&& .MuiInput-root:hover::before' : {
        borderColor: 'var(--first-color)'
    },
    "& .MuiInputBase-root": {
        color: '#8c8c8c',
    },

    '& label.Mui-disabled': {
        color: '#8c8c8c'
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "var(--plat-six)",
        background: "#111"
    },
});

const inputLabelStylesB = {
    color: '#8c8c8c'
};

export { CustomTextFieldStandard, inputLabelStylesB }