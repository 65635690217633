import '../../style.css'
import './style.css'
import CircularProgress from '@mui/material/CircularProgress';

function BannerLoader() {

    return (
        <div className="placeholder">
            <div className='line_loader'>
                <div className='loader_box_home_banner_labor'>
                    <CircularProgress sx={{width:"24px !important", height:"24px !important", color: "var(--plat-seven)"}} />
                </div> 
            </div>
        </div>
    )
}

export default BannerLoader;