import styled from "styled-components";
import LoadingButton from '@mui/lab/LoadingButton';

const CustomLoadingButtonOutlined = styled(LoadingButton)({
    "&:disabled": {
        borderColor: "var(--first-transparency)",
        color: "var(--first-transparency)"
    }
})

export { CustomLoadingButtonOutlined }