import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

function NavBarLaboratoryMobile({ showMenuMain, setShowMenuMain, categorys }) {
    const [openCollapse, setOpenCollapse] = useState(false)
    const [openCollapseA, setOpenCollapseA] = useState(false)
    const [openCollapseB, setOpenCollapseB] = useState(false)
    const [openCollapseC, setOpenCollapseC] = useState(false)

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowMenuMain(!showMenuMain)
    };

    const showCollapse = (event, categSlug) => {
        if (categSlug === 'cursos') {
            setOpenCollapse(!openCollapse)
        } else if (categSlug === 'front-end') {
            setOpenCollapseA(!openCollapseA)
        } else if  (categSlug === 'back-end') {
            setOpenCollapseB(!openCollapseB)
        } else if  (categSlug === 'devops') {
            setOpenCollapseC(!openCollapseC)
        }
    }

    const getOpenCollapse = (categSlug) => {
        if (categSlug === 'cursos') {
            return openCollapse
        } else if (categSlug === 'front-end') {
            return openCollapseA
        } else if  (categSlug === 'back-end') {
            return openCollapseB
        } else if  (categSlug === 'devops') {
            return openCollapseC
        }
    }

    return (
        <Drawer 
            anchor={'left'} 
            open={showMenuMain} 
            onClose={toggleDrawer()} 
            PaperProps={{
                sx: {backgroundColor: "var(--plat-two)", color: "var(--plat-six)"}
            }}
            disableScrollLock={true}
        >
             <Box
                sx={{ width: 250 }}
                role="presentation"
                // onClick={toggleDrawer()}
                // onKeyDown={toggleDrawer()}
            >
                <List
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >
                    <ListItemButton 
                        component={Link} 
                        to="/home"
                        sx={{
                            "&:hover": {
                                backgroundColor: "var(--plat-four)",
                            }
                        }}
                    >
                        <ListItemIcon>
                            <HomeOutlinedIcon color="neutral" />
                        </ListItemIcon>
                        
                        <ListItemText primary={'Início'} />
                    </ListItemButton>
                     
                    <ListItemButton 
                        onClick={(e) => showCollapse(e, 'cursos')}
                        sx={{
                            "&:hover": {
                                backgroundColor: "var(--plat-four)",
                            }
                        }}
                    >
                        <ListItemIcon>
                            <SchoolOutlinedIcon color="neutral" />
                        </ListItemIcon>
                        
                        <ListItemText primary={'Cursos'} />

                        {getOpenCollapse('cursos') ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
 
 
                    <Collapse in={getOpenCollapse('cursos')} timeout="auto" unmountOnExit>
                        {categorys.map((category, index) => (
                            <>
                                <List key={index} component="div" disablePadding>
                                    <ListItemButton 
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "var(--plat-four)",
                                            }
                                        }}
                                        onClick={(e) => showCollapse(e, category.slug)}
                                    >
                                        <ListItemIcon>
                                            {category.slug === 'front-end' && <ViewQuiltOutlinedIcon color="neutral" />}
                                            {category.slug === 'back-end' && <IntegrationInstructionsOutlinedIcon color="neutral" />}
                                            {category.slug === 'devops' && <SettingsSuggestOutlinedIcon color="neutral" />}
                                        </ListItemIcon>

                                        <ListItemText primary={category.name} />

                                        {getOpenCollapse(category.slug) ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>

                                    <Collapse in={getOpenCollapse(category.slug)} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {category.subcategorys.map((subcateg, index) => {
                                                return (
                                                    <ListItemButton 
                                                        key={subcateg.id} 
                                                        sx={{
                                                            pl: 4,
                                                            "&:hover": {
                                                                backgroundColor: "var(--plat-four)",
                                                            }
                                                        }}
                                                        component={Link} 
                                                        to={`/cursos/${category.slug}/${subcateg.slug}`} 
                                                        onClick={() => setShowMenuMain(!showMenuMain)}
                                                    >
                                                        <ListItemText primary={subcateg.subcategory} />
                                                    </ListItemButton>
                                                )
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                            </>
                        ))}
                    </Collapse>

                    <ListItemButton 
                        component={Link} 
                        to="/cursos-favoritos"
                        sx={{
                            "&:hover": {
                                backgroundColor: "var(--plat-four)",
                            }
                        }}
                    >
                        <ListItemIcon>
                            <FavoriteBorderOutlinedIcon color="neutral" />
                        </ListItemIcon>
                        
                        <ListItemText primary={'Favoritos'} />
                    </ListItemButton>
                </List>
            </Box>
        </Drawer>
    );
}

export default NavBarLaboratoryMobile;