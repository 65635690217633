import '../../style.css'

function HistoricLoader() {
    return (
        <div className="placeholder">
            <div className='line_loader'>
                <div style={{width:"100%"}}>
                    {/* Tabela */}
                    <div className='pulse'>
                        <div style={{width:"100%", height:'300px', borderRadius:"3px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoricLoader;