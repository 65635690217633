import './style.css'

function ListComment({children}) {
    return (
        <div className='admin_list_results2'>
            {children}
        </div>
    )
}

export default ListComment