import { FormControl, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import api from '../../../services/api';
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { Context } from '../../../context/AuthContext';
import { toast } from 'react-toastify';

function StepReactivateDefaultPayment ({defaultCard, plans, nextStep, changeDefaultPayStep, planSelected, setPlanSelected, stepNow = null, stepTotals = null, dark = true}) {
    const [stepLoader, setStepLoader] = useState(false)
    const [isSetPlanLoading, setIsSetPlanLoading] = useState(false)
    const { handleLogout } = useContext(Context)

    async function sendStep() {
        setStepLoader(true)
        await restartSignatureWithDefaultPaymentMethod()
    }

    async function restartSignatureWithDefaultPaymentMethod()
    {
        await api.post('restart-signature-pm-default', {
            plan_id: planSelected.id
        }).then((response) => {
            if (response.data.status === 'success') {
                nextStep()
            }

            setStepLoader(false)
        }).catch((error) => {
            setStepLoader(false)

            if (error.response.status === 401) {
                handleLogout()
            }
        })
    }

    async function onChangePlan(newPlanId) {
        setIsSetPlanLoading(true)

        await api.post(`user-set-plan`, {
            plan_id: newPlanId
        }).then((response) => {
            if (response.data.status === 'success') {
                setPlanSelectHere(newPlanId)
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }

            setIsSetPlanLoading(false)
        }).catch((error) => {
            toast.error(error.response.data.message)
            setIsSetPlanLoading(false)

            if (error.response.status === 401) {
                handleLogout()
            }
        })
    }

    function setPlanSelectHere(planId) {
        let planSelectedHere = plans.filter(plan => plan.id === planId)
        setPlanSelected(planSelectedHere[0])
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>Revise as informações!</h1>
                <p>Confira tudo antes de reativar a assinatura.</p>

                <div className='payment_select_pay'>
                    <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                        <CustomTextField
                            required
                            select
                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                            label="Plano selecionado"
                            size="medium"
                            {...(isSetPlanLoading && {disabled: 'true'})}
                            fullWidth
                            name="plan_id"
                            onChange={(e) => onChangePlan(e.target.value)} 
                            // onChange={(e) => setPlanSelectHere(e.target.value)} 
                            value={planSelected.id} 
                            InputLabelProps={{
                                sx: {
                                ...inputLabelStyles,
                                },
                            }}
                            inputProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: 'var(--plat-two)',
                                            color: 'var(--plat-seven)'
                                        }
                                    }
                                }
                            }}
                        >
                            {plans.map((plan) => (
                                <MenuItem 
                                    key={plan.id} 
                                    value={plan.id}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "var(--plat-four)",
                                        }
                                    }}
                                >
                                    {plan.name}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </FormControl>

                    <FormControl variant="standard" fullWidth sx={{marginBottom: '20px'}}>
                        <CustomTextField
                            label="Preço"
                            disabled
                            value={`R$ ${planSelected.price} / Mês`} 
                            name='price'
                            InputLabelProps={{
                                sx: {
                                    ...inputLabelStyles,
                                },
                            }}
                            size="medium"
                            fullWidth
                            inputProps={{ maxLength: 200 }}
                        />
                    </FormControl>
                </div>

                <div className='box_resume_payment'>
                    <div className='resume_p_top'>
                        <h5>Forma de pagamento</h5>
                    </div>

                    <div className='resume_p_bottom'>
                        <div className='resume_data_pay'>
                            <h6>{defaultCard.brand}, final {defaultCard.last_numbers}</h6>
                            <p>{defaultCard.name}</p>
                        </div>

                        <div>
                            <span className='link_pay'>{defaultCard.exp_month} / {defaultCard.exp_year}</span>
                        </div>
                    </div>

                    <div className='resume_p_bottom_add_card' onClick={() => changeDefaultPayStep()}>
                        Adicionar novo cartão
                    </div>
                </div>

                <CustomLoadingButton
                    onClick={() => sendStep()}
                    loading={stepLoader}
                    variant="contained"
                    sx={{width: '350px'}}
                    color='secondary'
                    size="large"
                >
                    <span>Iniciar assinatura</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepReactivateDefaultPayment;