import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: 'var(--plat-twelve)'
    },
    '& .MuiTabs-scroller': {
        // borderBottom: '1px solid var(--plat-twelve)',
        border: '1px solid var(--plat-eight)',
        background: 'var(--plat-three)',
        borderRadius: '4px'
    },

    '& .MuiTabs-scrollButtons': {
        opacity: '1',
        color: 'var(--plat-six)'
    },
    '& .MuiTabs-scrollButtons.Mui-disabled': {
        opacity: '0.3'
    }
});

const CustomTab = styled(Tab)`
    && {
      color: var(--plat-six);
      /* text-transform: none; */
    }
`;

export { CustomTabs, CustomTab }