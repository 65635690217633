
function Form({children}) {
    return (
        <form 
            encType="multipart/form-data" 
            className='frm_dark'
        >
            {children}
        </form>
    )
}

export default Form;