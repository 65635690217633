import './style.css'
import React from 'react'

function Table({tableColls, tableCollsMobile, tableCells, Cell, screenSize}) {

    const Coll = ({coll}) => {
        return (
            <th data-name={coll.name}>
                {coll.description}
            </th>
        )
    }

    return (
        <div className='admin_table_inside'>
            <table>
                <thead>
                    <tr>
                        {screenSize === 'desktop' ? 
                            <>
                                {/* Screen Desktop */}
                                {tableColls.map((coll, index) => {
                                    return <Coll key={index} coll={coll}/>
                                })}
                            </>
                        :
                            <>
                                {/* Screen Mobile */}
                                {tableCollsMobile.map((coll, index) => {
                                    return <Coll key={index} coll={coll}/>
                                })}
                            </>
                        }
                        
                        <th>Ações</th>
                    </tr>
                </thead>
                
                <tbody>
                    {tableCells.map((cell, index) => {
                        return(
                            <Cell key={index} cell={cell} />
                        )
                    })}
                </tbody>

                {/* <tfoot>
                    <tr>
                        <td>
                            Total de registros: 10
                        </td>
                    </tr>
                </tfoot> */}
            </table>
        </div>
    )
}

export default Table