import '../style.css'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Grid } from '@mui/material'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';

function ModalCourseIntroVideo({modalStatus, setModalStatus, videoEmbed}) {
    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    return (
        <>
            <div className={`modal_box ${modalStatus && 'modal_open'}`}>

                <div className='modal modal_video mdl_blk'>
                    <div className="modal_content">
                        <div className='modal_header mdl_blk'>
                            <div className='modtitbox'>
                                <OndemandVideoOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                <h3 className='mdl_blk'>Introdução do Curso</h3>
                            </div>

                            <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                        </div>

                        <div className="modal_content_middle">
                            <p className='mdl_blk'>Confira uma palinha do conteúdo abordado no curso!</p>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="video">
                                        <iframe src={videoEmbed} className="embed" frameborder="0" allow="autoplay; fullscreen" title='Introdução do Curso' allowfullscreen></iframe>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className="modal_footer">
                           
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalCourseIntroVideo;