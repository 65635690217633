import { toast } from "react-toastify";
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import SectionBeStudent from "../../../components/Website/SectionBeStudent";

function CancelNewsletterSubscribe () {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    const {userKey} = useParams();
    const [spinnerLoader, setSpinnerLoader] = useState(true)
    const history = useHistory()
    
    useEffect(() => {
        window.scrollTo(0, 0)
        setMetaTagsOfPage('Cancelar Newsletter', 'Cancelando sua inscrição na Newsletter!')

        async function sendUserConfirmation() {
            await api.post(`cancelar-newsletter/${userKey}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.message)
                        history.push("/");
                    }
    
                    setSpinnerLoader(false)
                }).catch((error) => {
                    if (error.response.status === 422) {
                        toast.error(error.response.data.message)
                        history.push("/");
                    }
    
                    setSpinnerLoader(false)
                })
        }

        sendUserConfirmation()
    }, [])

    return (
        <>
            <div className={`box_contact ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>
                <div className="contact_content">
                    <div className="contact_inside">
                        {spinnerLoader ?
                            <SpinnerLoader borderColorImp='var(--plat-seven)'/>
                        :
                            <>
                                <h1>Sua inscrição na newsletter foi cancelada!</h1>
                                <p className="descp_sup_prof">A partir de agora você não receberá mais conteúdo informativo no seu e-mail.</p>
                            </>
                        }
                    </div>
                </div>
            </div>

            <SectionBeStudent/>
        </>
    )
}

export default CancelNewsletterSubscribe;