import './style.css'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

function Comment({name, comment, publicationDate, id, handleListDelete}) {
    return (
        <div className='list_comment' data-id={id}>
            <div className='box_comment'>
                <div className='box_comm_main'>
                    <h5>{name}</h5>
                    <span>{publicationDate}</span>
                </div>

                <p>{comment}</p>
            </div>

            <div className='box_icons_smart'>
                <DeleteOutlinedIcon color='neutral' fontSize='large' titleAccess='Remover' onClick={(e) => handleListDelete(e, id)}/>
            </div>
        </div>
    )
}

export default Comment;