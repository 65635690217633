import '../WebsiteReactivateSubscription/style.css'
import Logo from '../../../assets/imgs/logos/logoeduteka.png'
import { Context } from '../../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import StepCreateUser from '../../../components/PaymentStripeSteps/StepCreateUser'
import StepShowPlans from '../../../components/PaymentStripeSteps/StepShowPlans';
import StepAlertPayment from '../../../components/PaymentStripeSteps/StepAlertPayment';
import StepAlertSuccessfullyPaid from '../../../components/PaymentStripeSteps/StepAlertSuccessfullyPaid';
import StepFirstPayment from '../../../components/PaymentStripeSteps/StepFirstPayment';

function WebsiteNewSubscription() {
    const { authenticated, setMetaTagsOfPage } = useContext(Context)
    const history = useHistory()
    const [plans, setPlans] = useState([])
    const [planSelected, setPlanSelected] = useState()
    const [currentStep, setCurrentStep] = useState(1)
    const [user, setUser] = useState('')

    //stripe
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)

    useEffect(() => {
        if (authenticated) {
            history.push('/planos-aviso')
        }

        setMetaTagsOfPage('Complete a sua matrícula na Eduteka', 'Uma plataforma de ensino feita para designers e programadores!')

        //Stripe
        loadPublishableKey()
    }, [])

    useEffect(() => {
        if (user?.id) {
            //Cria uma intenção de pagamento no servidor
            createPaymentIntent()
        }
    }, [user]);
    
    function loadPublishableKey() {
        api.get(`/stripe/config`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setStripePromise(loadStripe(response.data.data.publishable_key))
                } else {
                    history.push("/erro/404")
                }
            })
            .catch((error) => {
                // if (error.response.status === 401) {
                //     handleLogout()
                // }
            })
    }

    //Cria uma intenção de pagamento no servidor
    function createPaymentIntent() {
        api.post('create-payment-intent', {
            // payment_method_type: 'card',
            // currency: 'brl',
            // amount: 3990,
            usrid: user?.id
        }).then((response) => {
                if (response.data.status === 'success') {
                    setClientSecret(response.data.data.client_secret);
                }
        }).catch((error) => {
            // if (error.response.status === 401) {
            //     handleLogout()
            // }
        })
    }

    return (
        <>
            <div className='payment_header'>
                <div className='website_pay_header_container'>
                    {/* <Link to="/"> */}
                        <img className="logo" src={Logo} width={98} alt="Eduteka" title="Eduteka"/>
                    {/* </Link> */}
                    
                    {/* {currentStep === 2 &&
                        <LoadingButton
                            // onClick={() => handleLogout()}
                            // endIcon={<PsychologyAltOutlinedIcon />}
                            // loading={spinnerLoaderBtn}
                            loadingPosition="end"
                            variant="outlined"
                            color="secondary"
                        >
                            <span>Logar</span>
                        </LoadingButton>
                    }
                    
                    {currentStep > 2 &&
                        <LoadingButton
                            onClick={() => handleLogout()}
                            // endIcon={<PsychologyAltOutlinedIcon />}
                            // loading={spinnerLoaderBtn}
                            loadingPosition="end"
                            variant="outlined"
                            color="secondary"
                        >
                            <span>Sair</span>
                        </LoadingButton>
                    } */}
                </div>
            </div>


            {currentStep === 1 &&
                <StepShowPlans 
                    plans={plans}
                    setPlans={setPlans}
                    planSelected={planSelected}
                    setPlanSelected={setPlanSelected}
                    stepNow={1} 
                    stepTotals={4} 
                    nextStep={() => setCurrentStep(2)} 
                />
            }

            {currentStep === 2 &&
                <StepCreateUser 
                    setUser={setUser} 
                    planId={planSelected?.id} 
                    stepNow={2} 
                    stepTotals={4} 
                    nextStep={() => setCurrentStep(3)} 
                />
            }

            {currentStep === 3 &&
                <StepAlertPayment 
                    stepNow={3} 
                    stepTotals={4} 
                    nextStep={() => setCurrentStep(4)} 
                />
            }

            {currentStep === 4 &&
                <StepFirstPayment
                    clientSecret={clientSecret}
                    stripePromise={stripePromise}
                    setPlanSelected={setPlanSelected}
                    plans={plans}
                    planSelected={planSelected}
                    userId={user?.id}
                    nextStep={() => setCurrentStep(5)} 
                />
            }

            {currentStep === 5 &&
                <StepAlertSuccessfullyPaid
                    userName={user.name.split(' ')[0]}
                />
            }
        </>
    )
}

export default WebsiteNewSubscription;