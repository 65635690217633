import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import './style.css'
// import api from "../../../services/api";
// import { toast } from "react-toastify";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import { CustomTextField, inputLabelStyles } from "../../../components/MaterialCustom/Laboratory/TextField/CustomTextField";
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";

export default function CheckoutForm({clientSecret, nextStep, typeCheckout = 'first-payment' /*, userId = null, planSelectedId = null*/}) {
  const stripe = useStripe();
  const elements = useElements();

  const [messageError, setMessageError] = useState(null);
  // const [messageWarning, setMessageWarning] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [cardName, setCardName] = useState('')
  const [cardNameError, setCardNameError] = useState('')

  // function retrieve() {
  //   stripe
  //     .retrievePaymentIntent(clientSecret)
  //     .then(function(result) {
  //       console.log(result)
  //       // Handle result.error or result.paymentIntent
  //     });
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessageError('')
    // setMessageWarning('')

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    //Confirma o pagamento no cliente (taxa única)
    // const { error: stripeError, paymentIntent } = await stripe.confirmPayment({

    //Confirma se os dados do cartão estão aptos a pagamento da assinatura
    const { error: stripeError, setupIntent } = await stripe.confirmSetup({ 
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/sucessed`,

        payment_method_data: {
          billing_details:{
            name: cardName
          }
        }
      },
      redirect: 'if_required', //remove o redirecionamento aplicdo acima
    });

    if (stripeError) {
      //Exibe o erro do cartão para o usuário (ex: saldo insuficiente)
      setMessageError(stripeError.message);

      setIsLoading(false);
      return;
    // } else if (paymentIntent && paymentIntent.status === 'succeeded') {
    } else if (setupIntent && setupIntent?.status === 'succeeded') {
      // setMessageWarning('Aguarde, finalizando assinatura...')

      //Criou com sucesso
      setTimeout(() => {
          nextStep()
          setIsLoading(false);
      },  3000)
      
      
      // if (typeCheckout === 'first-payment') {
        // await createSubscription(setupIntent.payment_method)
        // nextStep()
      // } else {
        // AINDA NAO TESTEI E NAO FIZ WEBHOOK
        // await recreateSubscription(setupIntent.payment_method)
        // nextStep()
      // }
    } else {
      setMessageError('Erro inesperado!')

      setIsLoading(false);
      return;
    }
  };

  //Requisição de criação da assinatura
  // async function createSubscription(paymentMethod) {
  //   setIsLoading(true);

  //   let formData = new FormData();
  //   formData.append('payment_method', paymentMethod);
  //   formData.append('user_id', userId);
  //   formData.append('plan_id', planSelectedId);

  //   await api.post(`create-subscription`, formData)
  //       .then((response) => {
  //           if (response.data.status === 'success') {
  //             // toast.success('Matrícula realiza com sucesso!')
  //             nextStep()
  //             setMessageWarning('')
  //           } else {
  //             toast.error('Falha ao realizar matrícula!')
  //           }

  //           setIsLoading(false);
  //       }).catch((error) => {
  //           // if (error.response.status === 401) {
  //           //     handleLogout()
  //           // }
  //           setIsLoading(false);
  //       })
  // }

  //Requisição para reativar assinatura
  // async function recreateSubscription(paymentMethod) {
  //   setIsLoading(true);

  //   let formData = new FormData();
  //   formData.append('payment_method', paymentMethod);
  //   formData.append('plan_id', planSelectedId);
    
  //   await api.post(`recreate-subscription`, formData)
  //       .then((response) => {
  //           if (response.data.status === 'success') {
  //             // toast.success('Matrícula realiza com sucesso!')
  //             nextStep()
  //             setMessageWarning('')
  //           } else {
  //             toast.error('Falha ao realizar matrícula!')
  //           }

  //           setIsLoading(false);
  //       }).catch((error) => {
  //           // if (error.response.status === 401) {
  //           //     handleLogout()
  //           // }
  //           setIsLoading(false);
  //       })
  // }

  const paymentElementOptions = {
    layout: "tabs", //"accordion"
    defaultCollapsed: false,
  }

  return (
    <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl variant="standard" fullWidth sx={{marginBottom: '15px'}}>
                <label htmlFor="card-holder-name" style={{color: "var(--plat-seven)", marginBottom:"0.25rem", fontSize:"0.93rem", lineHeight: '1.15'}}>Nome impresso no cartão</label>
                <CustomTextField
                    // required
                    label=" "
                    id="card-holder-name" 
                    onChange={(e) => setCardName(e.target.value)}
                    value={cardName} 
                    autoComplete="off"
                    name='account_name'
                    {...(cardNameError.account_name && {error: 'true'})}
                    InputLabelProps={{
                        sx: {
                        ...inputLabelStyles,
                        },
                        // shrink: true
                    }}
                    size="medium"
                    fullWidth
                    inputProps={{ maxLength: 200, style: {textTransform: "uppercase"} }}
                />

                <FormHelperText>
                    {cardNameError.account_name}
                </FormHelperText>
            </FormControl>
        </Grid>
      </Grid>

      <PaymentElement id="payment-element" options={paymentElementOptions}/>

      {/* Show any error or success messages */}
      {messageError && <div id="payment-message">{messageError}</div>}
      {/* {messageWarning && <div id="payment-message-warning">{messageWarning}</div>} */}

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop:"25px"}}>
        {typeCheckout === 'first-payment' ?
          <CustomLoadingButton
              onClick={(e) => handleSubmit(e)}
              loading={isLoading}
              variant="contained"
              fullWidth
              disabled={isLoading || !stripe || !elements}
              color='secondary'
              size="large"
          >
              <span>Assinar Agora</span>
          </CustomLoadingButton>
        :
          <CustomLoadingButton
              onClick={(e) => handleSubmit(e)}
              loading={isLoading}
              variant="contained"
              fullWidth
              disabled={isLoading || !stripe || !elements}
              color='secondary'
              size="large"
          >
              <span>Iniciar assinatura</span>
          </CustomLoadingButton>
        }
      </Grid>
    </form>
  );
}