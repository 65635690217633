import { Switch, Route, Redirect } from "react-router-dom"
import ErrorScreen from "../pages/General/Error/ErrorScreen"
import BlogHome from "../pages/Blog/BlogHome";
import BlogSingle from "../pages/Blog/BlogSingle";
import { Context } from '../context/AuthContext'
import React, { useContext } from 'react'
import LaboratoryHome from "../pages/Laboratory/LaboratoryHome";
import WebsiteCategory from "../pages/Website/WebsiteCategory";
import BlogSubCategory from "../pages/Blog/BlogSubCategory";
import AdminTableTemplate from "../pages/Platform/Template/AdminTableTemplate";
import AdminFormTemplate from "../pages/Platform/Template/AdminFormTemplate";
import HeaderBlog from "../components/Header/Blog"
import HeaderSite from "../components/Header/Website"
import Footer from "../components/Footer";
import BlogCategory from "../pages/Blog/BlogCategory";
import Main from "../components/Loaders/Main";
import MyProfile from "../pages/General/MyProfile";
import WebsiteHome from "../pages/Website/WebsiteHome";
import WebsiteSubCategory from "../pages/Website/WebsiteSubCategory";
import WebsiteCourse from "../pages/Website/WebsiteCourse";
import AdminTemplate from "../pages/Platform/Template/AdminTemplate";
import Support from "../pages/General/Support";
// import ConfirmAccountCreation from "../pages/General/ConfirmAccountCreation";
import ChangePassword from "../pages/General/ChangePassword";
import CancelNewsletterSubscribe from "../pages/General/CancelNewsletterSubscribe";
import HeaderLaboratory from "../components/Header/Laboratory";
import LaboratorySingle from "../pages/Laboratory/LaboratorySingle";
import LaboratoryLogin from "../pages/Laboratory/LaboratoryLogin";
import LaboratoryFavorite from "../pages/Laboratory/LaboratoryFavorite";
import LaboratoryClassVideo from "../pages/Laboratory/LaboratoryClassVideo";
import LaboratoryCourseNotAvailable from "../pages/Laboratory/LaboratoryCourseNotAvailable";
import WebsiteReactivateSubscription from "../pages/Website/WebsiteReactivateSubscription";
import WebsiteNewSubscription from "../pages/Website/WebsiteNewSubscription";
import MyPoints from "../pages/General/MyPoints";
import WebsiteSubscriptionNotAvailable from "../pages/Website/WebsiteSubscriptionNotAvailable";


import WebsiteConstruction from "../pages/Website/WebsiteInConstruction";
import { getCookie, setCookie } from "../helpers";

function CustomRoute({ isPrivate, ...rest }) {
    const { authenticated, loadingMain, reactivateSubscription, setReactivateSubscription } = useContext(Context)

    if (loadingMain === true) {
        return <Main/>
    } else if (isPrivate && !authenticated) {
        return <Redirect to="/"/>
    } else if (reactivateSubscription === true) { //necessário o usuário reativar a assinatura, pois não é mais válida
        setReactivateSubscription(!reactivateSubscription)

        return <Redirect to="/renovar-assinatura"/>
    } else {
        return <Route {...rest} />  // ...rest passa o resto das propriedades que ja existiam, ex:  exact path="/"
    }
}

// Verifica se o site está em construção ou se existe uma "query string com chav" que libere o site completo (ex: ?testmode=46yFc3sd)
const websiteUnderConstruction = () => {
    const keywords = ['EDK_ENABLE_TESTMODE']

    if (getCookie('testmode') && keywords.includes(getCookie('testmode'))) {
        //acesso liberado ao testmode (existe um cookie já criado)
        return false;
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    const testMode = urlParams.get('testmode');

    if (keywords.includes(testMode)) {
        //Cria um cookie que libera a plataforma por 1 dia
        setCookie("testmode", keywords[0], 1);

        return false;
    }

    return true;
}

function Routes () {
    if (window.location.host.split('.')[0] === 'blog') { //Sub-domínio (ex: blog.eduteka.com.br)

        return (
            <Switch>                
                {/******* Rotas dos Administradores *******/}
                <CustomRoute exact isPrivate path="/admin/:pageSlug">
                    <AdminTemplate>
                        <AdminTableTemplate />
                    </AdminTemplate>
                </CustomRoute>

                <CustomRoute exact isPrivate path="/admin/:pageSlug/incluir">
                    <AdminTemplate>
                        <AdminFormTemplate />
                    </AdminTemplate>
                </CustomRoute>

                <CustomRoute exact isPrivate path="/admin/:pageSlug/:regId">
                    <AdminTemplate>
                        <AdminFormTemplate />
                    </AdminTemplate>
                </CustomRoute>


                {/******* Rotas Gerais *******/}
                <CustomRoute exact isPrivate path="/meu-perfil">
                    <HeaderBlog />
                        <MyProfile />
                    <Footer />
                </CustomRoute>

                <CustomRoute exact isPrivate path="/meus-pontos">
                    <HeaderBlog />
                        <MyPoints />
                    <Footer />
                </CustomRoute>

                <CustomRoute exact path="/suporte">
                    <HeaderBlog />
                        <Support whatPage='blog' />
                    <Footer />
                </CustomRoute>

                {/* <CustomRoute exact path="/pesquisa">
                    <HeaderBlog />
                        <BlogSearchResults />
                    <Footer />
                </CustomRoute> */}

                <CustomRoute exact isPrivate path="/renovar-assinatura">
                    <WebsiteReactivateSubscription />
                </CustomRoute>

                <CustomRoute exact path="/erro/:codeHttp">
                    <HeaderBlog />
                        <ErrorScreen />
                    <Footer />
                </CustomRoute>


                {/******* Rotas do Blog *******/}
                <CustomRoute exact path="/">
                    <HeaderBlog />
                        <BlogHome />
                    <Footer />
                </CustomRoute>

                <CustomRoute exact path="/blog">
                    <HeaderBlog />
                        <BlogHome />
                    <Footer />
                </CustomRoute>

                <CustomRoute exact path="/matricule-se">
                    {(websiteUnderConstruction() === true) ? 
                        <>
                            {/******* Rotas do Site em Construção (ROTA TEMPORÁRIA) *******/}
                            <WebsiteConstruction /> 
                        </>
                    :  
                        <WebsiteNewSubscription />
                    }
                </CustomRoute>

                <CustomRoute exact isPrivate path="/planos-aviso">
                    <HeaderBlog />
                        <WebsiteSubscriptionNotAvailable />
                    <Footer/>
                </CustomRoute>

                <CustomRoute exact path="/:categorySlug/:subCategorySlug">
                    <HeaderBlog />
                        <BlogSubCategory />
                    <Footer />
                </CustomRoute> 

                <CustomRoute exact path="/:categorySlug/:subCategorySlug/:articleSlug">
                    <HeaderBlog />
                        <BlogSingle />
                    <Footer />
                </CustomRoute>

                <CustomRoute exact path="/:categorySlug">
                    <HeaderBlog />
                        <BlogCategory />
                    <Footer />
                </CustomRoute>


                {/******* Rotas Gerais *******/}
                <CustomRoute exact path="*">
                    <HeaderBlog />
                        <ErrorScreen codeHttpInline={404}/>
                    <Footer />
                </CustomRoute>
            </Switch>
        )

    } else if (window.location.host.split('.')[0] === 'laboratorio') {

        return (
            <>
                {(websiteUnderConstruction() === true) ?
                    <Switch>
                        {/******* Rotas do Laboratório em Construção (ROTA TEMPORÁRIA) *******/}
                        <CustomRoute exact path="/">
                            <WebsiteConstruction />
                        </CustomRoute>
                        <CustomRoute exact path="*">
                            <WebsiteConstruction />
                        </CustomRoute>
                    </Switch>
                : 
                    <Switch>
                        {/******* Rotas da Área do Aluno *******/}
                        <CustomRoute exact path="/">
                            <LaboratoryLogin />
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/home">
                            <HeaderLaboratory />
                                <LaboratoryHome /> 
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/curso/:categorySlug/:subCategorySlug/:courseSlug">
                            <HeaderLaboratory />
                                <LaboratorySingle />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/curso/:categorySlug/:subCategorySlug/:courseSlug/:classId">
                            <HeaderLaboratory />
                                <LaboratoryClassVideo />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/cursos/:categorySlug/:subCategorySlug">
                            <HeaderLaboratory />
                                <WebsiteSubCategory isLaboratory={true} />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/cursos/:categorySlug">
                            <HeaderLaboratory />
                                <WebsiteCategory isLaboratory={true} />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/contratar-plano">
                            <HeaderLaboratory />
                                <LaboratoryCourseNotAvailable />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/cursos-favoritos">
                            <HeaderLaboratory />
                                <LaboratoryFavorite />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/suporte">
                            <HeaderLaboratory />
                                <Support whatPage='laboratorio'/>
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact path="/matricule-se">
                            <WebsiteNewSubscription />
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/planos-aviso">
                            <HeaderLaboratory />
                                <WebsiteSubscriptionNotAvailable />
                            <Footer/>
                        </CustomRoute>


                        {/******* Rotas dos Administradores *******/}
                        <CustomRoute exact isPrivate path="/admin/:pageSlug">
                            <AdminTemplate>
                                <AdminTableTemplate />
                            </AdminTemplate>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/admin/:pageSlug/incluir">
                            <AdminTemplate>
                                <AdminFormTemplate />
                            </AdminTemplate>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/admin/:pageSlug/:regId">
                            <AdminTemplate>
                                <AdminFormTemplate />
                            </AdminTemplate>
                        </CustomRoute>


                        {/******* Rotas Gerais *******/}
                        <CustomRoute exact isPrivate path="/meu-perfil">
                            <HeaderLaboratory />
                                <MyProfile />
                            <Footer/>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/meus-pontos">
                            <HeaderLaboratory />
                                <MyPoints />
                            <Footer />
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/renovar-assinatura">
                            <WebsiteReactivateSubscription />
                        </CustomRoute>


                        {/******* Rotas da Área do Aluno *******/}
                        <CustomRoute exact path="*"> {/* Como se fosse a página de erro */}
                            <LaboratoryLogin />
                        </CustomRoute>
                    </Switch>
                }
            </>
        )

    } else { //sem subdominínio (ex:eduteka.com.br, www.eduteka.com.br)

        return (
            <>
                {(websiteUnderConstruction() === true) ?
                    <Switch>
                        {/******* Rotas do Site em Construção (ROTA TEMPORÁRIA) *******/}
                        <CustomRoute exact path="/">
                            <WebsiteConstruction />
                        </CustomRoute>
                        <CustomRoute exact path="*">
                            <WebsiteConstruction />
                        </CustomRoute>
                    </Switch>
                :  
                    <Switch>
                        {/******* Rotas do Site *******/}
                        <CustomRoute exact path="/">
                            <HeaderSite />
                                <WebsiteHome />
                            <Footer />
                        </CustomRoute>
                        <CustomRoute exact path="/cursos/:categorySlug/:subCategorySlug">
                            <HeaderSite />
                                <WebsiteSubCategory />
                            <Footer />
                        </CustomRoute>
                        <CustomRoute exact path="/cursos/:categorySlug/:subCategorySlug/:courseSlug">
                            <HeaderSite />
                                <WebsiteCourse />
                            <Footer />
                        </CustomRoute> 
                        <CustomRoute exact path="/cursos/:categorySlug">
                            <HeaderSite />
                                <WebsiteCategory />
                            <Footer />
                        </CustomRoute>
                        <CustomRoute exact path="/matricule-se">
                            <WebsiteNewSubscription />
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/planos-aviso">
                            <HeaderSite />
                                <WebsiteSubscriptionNotAvailable />
                            <Footer/>
                        </CustomRoute>
                        

                        {/******* Rotas dos Administradores *******/}
                        <CustomRoute exact isPrivate path="/admin/:pageSlug">
                            <AdminTemplate>
                                <AdminTableTemplate />
                            </AdminTemplate>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/admin/:pageSlug/incluir">
                            <AdminTemplate>
                                <AdminFormTemplate />
                            </AdminTemplate>
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/admin/:pageSlug/:regId">
                            <AdminTemplate>
                                <AdminFormTemplate />
                            </AdminTemplate>
                        </CustomRoute>


                        {/******* Rotas Gerais *******/}
                        <CustomRoute exact isPrivate path="/meu-perfil">
                            <HeaderSite />
                                <MyProfile />
                            <Footer />
                        </CustomRoute>

                        <CustomRoute exact isPrivate path="/meus-pontos">
                            <HeaderSite />
                                <MyPoints />
                            <Footer />
                        </CustomRoute>

                        <CustomRoute exact path="/suporte">
                            <HeaderSite />
                                <Support whatPage='website' />
                            <Footer />
                        </CustomRoute>

                        {/* <CustomRoute exact path="/confirmar-criacao-conta/:userKey">
                            <HeaderSite />
                                <ConfirmAccountCreation />
                            <Footer />
                        </CustomRoute> */}

                        <CustomRoute exact path="/redefinir-senha/:userKey">
                            <HeaderSite />
                                <ChangePassword />
                            <Footer />
                        </CustomRoute>

                        <CustomRoute exact path="/cancelar-newsletter/:userKey">
                            <HeaderSite />
                                <CancelNewsletterSubscribe />
                            <Footer />
                        </CustomRoute>
                        
                        <CustomRoute exact isPrivate path="/renovar-assinatura">
                            <WebsiteReactivateSubscription />
                        </CustomRoute>

                        <CustomRoute exact path="/erro/:codeHttp">
                            <HeaderSite />
                                <ErrorScreen />
                            <Footer />
                        </CustomRoute>

                        <CustomRoute exact path="*">
                            <HeaderSite />
                                <ErrorScreen codeHttpInline={404}/>
                            <Footer />
                        </CustomRoute>
                    </Switch>
                }
            </>
        )
    }
}

export default Routes;