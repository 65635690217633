import React, { useRef, useState, useContext, useEffect } from 'react'
import Table from '../../../components/Platform/Table'
import TitleTableBox from '../../../components/Platform/TitleBox/TitleTableBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import ModalDelete from '../../../components/Modal/Platform/ModalDelete'
import { Context } from '../../../context/AuthContext'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function CategorysTable({tableColls, tableCollsMobile, tableCells, setTableCells, redirectTo}) {
    const [modalStatus, setModalStatus] = useState(false)
    const { handleLogout } = useContext(Context)
    const regDelId = useRef('')
    const [screenSize, setScreenSize] = useState('')

    const title = useRef({
        name: 'Categorias',
        description: 'Todos as categorias do blog',
        link_to: '/admin/categorias/incluir'
    })

    useEffect(() => {
        function getWidthScreen() {
            let windowWidth = window.innerWidth;

            if (windowWidth >= 990) {
                setScreenSize('desktop')
            } else {
                setScreenSize('mobile') 
            }
        }

        getWidthScreen()

        window.addEventListener('resize', getWidthScreen);

        return () => {
            window.removeEventListener('resize', getWidthScreen);
        }
    }, [])

    function openModal(e, cellId) {
        e.preventDefault()

        regDelId.current = cellId
        setModalStatus(true)
    }

    function handleDelete(e, callbackSuccessOrError) {
        callbackSuccessOrError(true)

        api.delete(`admin/categorias/${regDelId.current}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setTableCells(tableCells.filter((cell) => regDelId.current !== cell.id))

                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                regDelId.current = ''
                setModalStatus(false)
                callbackSuccessOrError(false)
            }).catch((error) => {
                callbackSuccessOrError(false)

                if (error.response.status === 401) {
                    handleLogout()
                }

                toast.error(error.response.data.message)
                regDelId.current = ''
                setModalStatus(false)
            })
    }

    const Cell = ({cell}) => {
        return (
            <tr>
                {screenSize === 'desktop' ?
                    <> {/* Screen Desktop */}
                        <td onClick={(e) => redirectTo(e, `/admin/categorias/${cell.id}`)}>{cell.name}</td>
                        <td onClick={(e) => redirectTo(e, `/admin/categorias/${cell.id}`)}>{cell.status}</td>
                        <td onClick={(e) => redirectTo(e, `/admin/categorias/${cell.id}`)}>{cell.description}</td>
                    </>
                :
                    <> {/* Screen Mobile */}
                        <td onClick={(e) => redirectTo(e, `/admin/categorias/${cell.id}`)}>{cell.name}</td>
                    </>
                }

                <td>
                    <DeleteOutlineOutlinedIcon onClick={(e) => openModal(e, cell.id)} color='fifthcolor' />
                </td>
            </tr>
        )
    }

    return (
        <>
            <TitleTableBox title={title.current} redirectTo={redirectTo} />

            <div className='admin_con_table'>
                <Table tableColls={tableColls} tableCells={tableCells} Cell={Cell} tableCollsMobile={tableCollsMobile} screenSize={screenSize} />
            </div>

            <ModalDelete modalStatus={modalStatus} setModalStatus={setModalStatus} handleDelete={handleDelete} />
        </>
    )
}

export default CategorysTable;