import { Link } from "react-router-dom"
import '../style.css'
import { Grid } from '@mui/material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { CustomLoadingButtonOutlined } from '../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined'

function TitleFormBox({handleSave, handleUpdate, regId, title}) {
    return (
        <div className='admin_cont_title'>
            <div className='admin_tit'>
                <div className="admin_link_back">
                    <Link className="admin_link_from" to='/admin/dashboard'>Dashboard</Link> / <Link className="admin_link_from" to={title.link_from}>{title.name}</Link>  
                </div>
                
                <h1>{title.name}</h1>

                {regId ? 
                    <p>{title.description_update}</p>
                :
                    <p>{title.description_insert}</p>
                }
                
            </div>

            <div className='admin_btns'>
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {regId ?
                            <CustomLoadingButtonOutlined
                                onClick={(e) => handleUpdate(e)}
                                endIcon={<SaveOutlinedIcon />}
                                loadingPosition="end"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                            >
                                <span>Alterar</span>
                            </CustomLoadingButtonOutlined>
                        :
                            <CustomLoadingButtonOutlined
                                onClick={(e) => handleSave(e)}
                                endIcon={<SaveOutlinedIcon />}
                                loadingPosition="end"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                            >
                                <span>Inserir</span>
                            </CustomLoadingButtonOutlined>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default TitleFormBox