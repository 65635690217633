import '../../style.css'
import './style.css'

function SingleLoader() {
    const SingleText = () => {
        return (
            <>
                <div className="loader_a_rectangle" style={{width:"100%", height:'22px', marginBottom:'10px'}}></div>
                <div className="loader_a_rectangle" style={{width:"85%", height:'22px', marginBottom:'10px'}}></div>
                <div className="loader_a_rectangle" style={{width:"70%", height:'22px', marginBottom:'50px'}}></div>
            </>
        )
    }

    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='loader_box_articles_single pulse'>
                    <div className="loader_a_rectangle" style={{marginBottom: "20px"}}></div>
                </div>

                <div className='loader_box_articles_single pulse'>
                    <div className="loader_rectangle_a_mini"></div>
                    <div className="loader_rectangle_a_mini_b"></div>
                </div>            

                <div className='loader_art_center'>
                    <div className='loader_articles_single pulse'>
                        <SingleText />
                        <SingleText />
                        <SingleText />
                        <SingleText />
                        <SingleText />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SingleLoader;