import '../../style.css'
import './style.css'

function HomeLoader() {

    const Article = () => {
        return (
            <div className='loader_course_single'>
                <div className='pulse'>
                    <div className="loader_rectangle_crs"></div>
                </div>
            </div>
        )
    }

    const ArticleTitle = () => {
        return (
            <div className='loader_course_single_tit'>
                <div className='pulse'>
                    <div className="loader_rectangle_crs_sgl"></div>
                </div>
            </div>
        )
    }

    return (
        <div className="placeholder">
            <div className='line_loader'>

                <ArticleTitle />
                <div className='loader_box_home_laboratory'>
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                </div>

                <ArticleTitle />
                <div className='loader_box_home_laboratory'>
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                </div>

                <ArticleTitle />
                <div className='loader_box_home_laboratory'>
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                </div>

                <ArticleTitle />
                <div className='loader_box_home_laboratory'>
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                </div>
                
            </div>
        </div>
    )
}

export default HomeLoader;