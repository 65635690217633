import styled from "styled-components";

const CustomThumb = styled.div`
    background: ${(props) => props.isAvailable === true ? 
        `url(${props.thumb})` 
    : 
        `
            linear-gradient(rgba(21, 21, 21, .7) 60%, var(--plat-one)), 
            url(${props.thumb}) right center / cover;
        `
    };
    background-size: 100% 100%;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    color: var(--first-color);
    font-weight: bold;
`

export { CustomThumb }