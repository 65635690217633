import styled from "styled-components";
import Slider from '@material-ui/core/Slider'

const CustomSlider = styled(Slider)(({ theme }) => ({
    width: 300,
    color: 'var(--first-color) !important',
    '& .MuiSlider-thumb': {
        color: 'var(--first-color)',
        // '&:hover, &.Mui-focusVisible': {
        //     boxShadow: `0px 0px 0px 8px red`,
        // },
        // '&.Mui-active': {
        //     boxShadow: `0px 0px 0px 14px green`,
        // },
    },
}));

export { CustomSlider }