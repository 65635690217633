import React, { createContext, useState } from 'react'
import useAuth from './hooks/useAuth'
import useMetaTags from './hooks/useMetaTags'

const Context = createContext()

function AuthProvider ({ children }) { //equivalente a "props.children"
    const { loading, setLoading, authenticated, handleLogin, handleLogout, loadingMain, setLoadingMain, userPicture, setUserPicture, notifications, setNotifications, notificationsNotViewedCount, setNotificationsNotViewedCount, reactivateSubscription, setReactivateSubscription } = useAuth()
    const [showMenuMain, setShowMenuMain] = useState(false)
    const [showSearchBar, setShowSearchBar] = useState(false)
    const [alertInTopClosed, setAlertInTopClosed] = useState(false)
    const [showAlert, setShowAlert] = useState('')
    const { setMetaTagsOfPage } = useMetaTags() 

    //Tela de Perfil (alteração de foto de perfil via modal)
    const [formProfilePhotoUrl, setFormProfilePhotoUrl] = useState(null)

    return (
        <Context.Provider value={{
            showMenuMain, setShowMenuMain, 
            loading, setLoading, 
            authenticated, 
            handleLogin, handleLogout, 
            setMetaTagsOfPage, 
            showSearchBar, setShowSearchBar, 
            showAlert, setShowAlert,
            loadingMain, setLoadingMain, 
            userPicture, setUserPicture,
            notifications, setNotifications,
            notificationsNotViewedCount, setNotificationsNotViewedCount,
            alertInTopClosed, setAlertInTopClosed,
            reactivateSubscription, setReactivateSubscription,

            formProfilePhotoUrl, setFormProfilePhotoUrl, 
        }}>
            {/* {loading ? null : children} */}
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider } 