import { useEffect } from "react";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { CustomLoadingButtonOutlined } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined";
import Form from "../../../components/Form";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import SectionBeStudent from "../../../components/Website/SectionBeStudent";

function WebsiteSubscriptionNotAvailable () {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
        setMetaTagsOfPage('Acesso aos planos bloqueado', 'Você está logado e já possui um plano. Caso queira trocar ou cancelar seu plano, faça por meio da página "Meu perfil".')
    }, [])

    function redirectTo(link) {
        if (link) {
            history.push(link)
        }
    }

    return (
        <>
            <div className={`box_pg_contact box_contact ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>
                <div className="contact_content">
                    <div className="contact_inside">
                        <h1>Acesso aos planos bloqueado!</h1>
                        <p className="descp_sup_prof">Você está logado e já possui um plano. Caso queira trocar ou cancelar seu plano, faça por meio da página "Meu perfil".</p>
                    
                        <Form>
                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButtonOutlined
                                        onClick={() => redirectTo('/meu-perfil')}
                                        variant="outlined"
                                        size="medium"
                                        color="secondary"
                                    >
                                        <span>Meu Perfil</span>
                                    </CustomLoadingButtonOutlined>
                                </Grid>
                            </Grid>
                        </Form>
                    </div>
                </div>
            </div>

            <SectionBeStudent/>
        </>           
    )
}

export default WebsiteSubscriptionNotAvailable;