import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, Autocomplete } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function NewsletterArticlesForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const [loaderTab3, setLoaderTab3] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)
    const [readersOptions, setReadersOptions] = useState([])
    const [listReaders, setListReaders] = useState([])

    const [formNewsletter, setFormNewsletter] = useState({
        subject: '',
        title: '',
        content: ''
    })

    const [fieldsError, setFieldsError] = useState({
        subject: '',
        title: '',
        content: ''
    })

    const [tableHistrcCell, setTableHistrcCell] = useState([])

    const title = useRef({
        name: 'Artigo na Newsletter',
        description_insert: 'Insira novos artigos na Newsletter',
        description_update: 'Altere artigos na Newsletter',
        link_from: '/admin/newsletter-articles'
    }) 

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)
            setLoaderTab3(true)

            api.get(`admin/newsletter-articles/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setLoaderTab2(false)
                        setFormNewsletter(response.data.data.newsletter_article)
                        setListReaders(response.data.data.subscribers)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            api.get(`admin/newsletter-articles/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca todos os leitores para por no select>options
            api.get('admin/newsletter-articles/readers')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setReadersOptions(response.data.data.subscribers)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        } else {
            setLoaderTab3(true)

            api.get('admin/newsletter-articles/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca todos os leitores para por no select>options
            api.get('admin/newsletter-articles/readers')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setReadersOptions(response.data.data.subscribers)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setSubject(e) {
        setFormNewsletter({...formNewsletter, subject: e.target.value})
    }

    function setTitle(e) {
        setFormNewsletter({...formNewsletter, title: e.target.value})
    }

    function setContent(e) {
        setFormNewsletter({...formNewsletter, content: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        let mountDataSave = {...formNewsletter, ...{readers:listReaders}}

        await api.post('admin/newsletter-articles', mountDataSave)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormNewsletter({email_nwl: '', status: ''})

                    history.push("/admin/newsletter-articles")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({email_nwl: '', status: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleUpdate(e) {
        e.preventDefault()
        // setLoading(true)

        toast.warning('Não é possível realizar alteração de um artigo da newsletter!')

        // let mountDataSave = {...formNewsletter, ...{readers:listReaders}}

        // await api.post(`admin/newsletter-articles/${regId}`, mountDataSave)
        //     .then((response) => {
        //         if (response.data.status === 'success') {
        //             toast.success(response.data.message)
        //             history.push("/admin/newsletter-articles")
        //         } else {
        //             toast.error(response.data.message)
        //         }

        //         setFieldsError({email_nwl: '', status: ''})
        //         setLoading(false)
        //     }).catch((error) => {
        //         setLoading(false)

        //         if (error.response.status === 422) {
        //             setFieldsError(error.response.data.data)
        //             toast.error(error.response.data.message)
        //         } else if (error.response.status === 401) {
        //             handleLogout()
        //         } else {
        //             toast.error(error.response.data.message)
        //         }
        //     })
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<Diversity3OutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Leitores" {...a11yProps(1)}  />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(2)} />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Assunto do e-mail"
                                                            onChange={setSubject}
                                                            value={formNewsletter.subject} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='subject'
                                                            autoComplete='off'
                                                            {...(fieldsError.subject && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.subject}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Título do e-mail"
                                                            onChange={setTitle}
                                                            value={formNewsletter.title} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='title'
                                                            autoComplete='off'
                                                            {...(fieldsError.title && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.title}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Conteúdo do e-mail"
                                                            onChange={setContent}
                                                            value={formNewsletter.content} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='content'
                                                            multiline
                                                            rows={4}
                                                            maxRows={6}
                                                            autoComplete='off'
                                                            {...(fieldsError.content && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 5000 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.content}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_list'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <Autocomplete
                                                            multiple
                                                            options={readersOptions}
                                                            getOptionLabel={(option) => option?.email_nwl}
                                                            // {...(!regId && {defaultValue: readersOptions})}
                                                            {...(regId && {defaultValue: listReaders})}
                                                            {...(regId && {disabled: 'true'})}
                                                            onChange={(event, options) => setListReaders(options)}
                                                            limitTags={3}
                                                            filterSelectedOptions
                                                            sx={{
                                                                "& .MuiChip-root": {
                                                                    backgroundColor: "var(--plat-ten)"
                                                                },
                                                                "& .MuiChip-label": {
                                                                    color: "var(--plat-seven)"
                                                                },
                                                                "& .MuiChip-deleteIcon": {
                                                                    color: "var(--plat-seven) !important"
                                                                },
                                                            }}
                                                            renderInput={(params) => (
                                                                <CustomTextField
                                                                    {...params}
                                                                    label="Quem receberá o artigo por e-mail..."
                                                                    placeholder="Leitores"
                                                                    {...(fieldsError.readers && {error: 'true'})}
                                                                    InputLabelProps={{
                                                                        sx: {
                                                                        ...inputLabelStyles,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.readers}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab3 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default NewsletterArticlesForm;