import MainBanner from "../../../components/Laboratory/MainBanner"
import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState, useContext } from 'react'
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import './style.css'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import {CustomTabs, CustomTab} from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs'
import BannerLoader from '../../../components/Loaders/Divs/Laboratory/BannerLoader';
import api from "../../../services/api";
import { Context } from "../../../context/AuthContext";
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import SingleLoader from '../../../components/Loaders/Divs/Laboratory/SingleLoader';
import { CustomCircularProgressWithLabel } from "../../../components/MaterialCustom/Laboratory/CircularProgress/CostumCircularProgress";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";
import { CustomLoadingButtonOutlined } from  "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { toast } from "react-toastify";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import ModalCourseIntroVideo from "../../../components/Modal/Website/ModalCourseIntroVideo";
// import { base64toBlob } from "../../../helpers";
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import ModalUserBlocked from "../../../components/Modal/Laboratory/ModalUserBlocked";
import apiDownloadPdf from "../../../services/apiDownloadPdf";

function LaboratorySingle () {
    const { setMetaTagsOfPage, handleLogout } = useContext(Context)
    const history = useHistory()
    const {courseSlug} = useParams()
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [certifLoader, setCertifLoader] = useState(false)
    const [singleLoader, setSingleLoader] = useState(true)
    const [courseOnBanner, setCourseOnBanner] = useState({}) //deve vir os dados do curso que foi clicado
    const [quiz, setQuiz] = useState({})
    const [isRight, setIsRight] = useState(null)
    const [questionCurrent, setQuestionCurrent] = useState(0)
    const [quizLoader, setQuizLoader] = useState(false)
    const [rightResponse, setRightResponse] = useState('')
    const [progress, setProgress] = React.useState(0);
    const [progressTwo, setProgressTwo] = React.useState(0);
    const [modalCourseIntroVideoStatus, setModalCourseIntroVideoStatus] = useState(false)
    const [modalBlockedStatus, setModalBlockedStatus] = useState(false)
    const [messageType, setMessageType] = useState()

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [valueB, setValueB] = useState(0);
    const handleChangeB = (event, newValue) => {
        setValueB(newValue);
    };

    useEffect(() => {
        setMetaTagsOfPage('Laboratório', 'Uma plataforma de ensino feita para designers e programadores!')
        // console.log('render component LaboratorySingle')
        loadSingle()

        window.scrollTo(0, 0)
    }, [courseSlug])

    useEffect(() => {
        //Não executa no primeiro load
        if (singleLoader === false) {
            setQuizLoader(true)

            async function loadNextOrPrevQuiz() {
                await api.get(`quiz/${courseOnBanner.id}/skip/${questionCurrent}`)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            setQuizLoader(false)
                            setQuiz(response.data.data.quiz)
                            setIsRight(response.data.data.quiz.is_right)
                            setProgress(response.data.data.quiz.resolved_percent)
                            setRightResponse(response.data.data.quiz.right_response)
                            // setSingleLoader(false)
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            handleLogout()
                        } 
                    })
            }

            loadNextOrPrevQuiz()
        }
    }, [questionCurrent])

    async function loadSingle() {
        setSingleLoader(true)

        await api.get(`single-lab/${courseSlug}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setCourseOnBanner(response.data.data.course)
                    setQuiz(response.data.data.quiz)
                    setIsRight(response.data.data?.quiz?.is_right)
                    setProgress(response.data.data?.quiz?.resolved_percent ?? 0)
                    
                    let percentClassesWatched = 0
                    if (response.data.data.course?.number_of_classes !== 0) {
                        percentClassesWatched = (( 100 / response.data.data.course?.number_of_classes) * (response.data.data.course?.number_of_classes - response.data.data.course?.num_classes_not_watched))
                    }
                    
                    setProgressTwo(percentClassesWatched)
                    setRightResponse(response.data.data.quiz?.right_response)
                    setSingleLoader(false)
                } else {
                    history.push("/erro/404")
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    redirectTo('/contratar-plano')
                }
            })
    }

    function responseChecked(responseId) {
        let newResponses = quiz.question.responses.map((resp) => {
            if (resp.id === responseId) {
                resp.checked = true
            } else {
                resp.checked = false
            }

            return resp
        })

        setQuiz({...{question: {responses: newResponses}}, ...quiz})
    }

    function responseIsRight(isRight) {
        let newResponses = quiz.question.responses.map((resp) => {
            if (resp.checked === true) {
                resp.is_right = isRight
            }

            return resp
        })

        setQuiz({...{question: {responses: newResponses}}, ...quiz})
    }

    async function confirmResponse(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        await api.post(`quiz/${quiz.id}/confirm-response/${quiz.question.id}`, {responses: quiz.question.responses})
            .then((response) => {
                if (response.data.status === 'success') {
                    // toast.success(response.data.message)
                    // setFormSupport({category_support_id: '', message: ''})
                    setIsRight(response.data.data.is_right)
                    responseIsRight(response.data.data.is_right)
                    setProgress(response.data.data.resolved_percent)
                    setRightResponse(response.data.data.right_response)
                } else if (response.data.status === 'warning') {
                    toast.warning(response.data.message)
                }
                // else {
                    // toast.error(response.data.message)
                // }

                setSpinnerBtnLoader(false)
                // setFieldsError({category_support_id: '', message: ''})
            }).catch((error) => {
                // if (error.response.status === 422) {
                    // validateDataBack(error.response.data.data)
                    // setFieldsError(error.response.data.data)

                    // toast.error(error.response.data.message)
                // }

                setSpinnerBtnLoader(false)
            })
    }

    function redirectTo(linkUrl) {
        if (linkUrl) {
            history.push(linkUrl)
        }
    }

    async function certificateGenerate() {
        setCertifLoader(true)

        await apiDownloadPdf.get(`certificate-generate/${courseSlug}`)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'meu-certificado.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                setCertifLoader(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    redirectTo('/contratar-plano')
                }

                setCertifLoader(false)
            })

        //NAO APAGAR POIS UM DIA PODERÁ SER USADO EM OUTRA COISA
        // await api.get(`certificate-generate/${courseSlug}`)
        //     .then((response) => {
        //         if (response.data.status === 'success') {
        //             var blob = base64toBlob(response.data.data.document_pdf);
        //             if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //                 window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        //             } else {
        //                 const blobUrl = URL.createObjectURL(blob);
        //                 window.open(blobUrl);
        //             }
        //         } else {
        //            toast.warning(response.data.message)
        //         }

        //        setCertifLoader(false)
        //     }).catch((error) => {
        //         if (error.response.status === 401) {
        //             handleLogout()
        //         } else if (error.response.status === 403) {
        //             redirectTo('/contratar-plano')
        //         }

        //         setCertifLoader(false)
        //     })
    }

    function showModal(msgType) {
        setMessageType(msgType)
        setModalBlockedStatus(!modalBlockedStatus)
    }
    
    return (
        <div className="course_videos">
            {singleLoader ?
                <BannerLoader/>
            :
                <MainBanner 
                    course={courseOnBanner} 
                    textBtn={`Assistir Aula ${courseOnBanner.current_class_order} do Módulo ${courseOnBanner.current_module_order}`} 
                    disableBtn={!courseOnBanner.current_class_order ? true : false} /* Assistiu todo o curso, portanto não precisa do botão */
                    redirectTo={redirectTo} 
                    linkTo={`${courseOnBanner.slug}/${courseOnBanner.current_class_id}`}
                />
            }
            
            {singleLoader ?
                <SingleLoader/>
            :
                <div className='crs_vid_content_all'>
                    <div className="crs_content_timeline">
                        <Grid container spacing={2} /*className="wb_sec_grid_center"*/>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <CustomTabs 
                                            value={value} 
                                            onChange={handleChange} 
                                            // variant="fullwith" 
                                            // scrollButtons={false} 
                                            variant="scrollable"
                                            allowScrollButtonsMobile
                                            aria-label="scrollable force tabs example"
                                            // centered 
                                            // aria-label="basic tabs example"
                                            sx={{
                                                '& .Mui-selected': { color: 'var(--plat-twelve) !important' },
                                                // '& .MuiTabs-indicator': { backgroundColor: 'var(--plat-twelve)' },
                                                // '& .MuiTab-root': { color: 'var(--plat-twelve)' }
                                            }}
                                        >
                                            <CustomTab icon={<OndemandVideoOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Aulas" {...a11yProps(0)} />
                                            <CustomTab icon={<MovieCreationOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Apresentação" {...a11yProps(1)} />
                                            <CustomTab icon={<InfoOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Informações" {...a11yProps(2)} />
                                            <CustomTab icon={<QuizOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Desafios" {...a11yProps(3)} />
                                            <CustomTab icon={<CardMembershipOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Certificado" {...a11yProps(4)} />
                                            {/* <Tab icon={<DesignServicesOutlinedIcon />} iconPosition="start" label="Pontuação" {...a11yProps(3)} /> */}
                                        </CustomTabs>
                                    </Box>

                                    <TabPanel value={value} index={0}>
                                        {singleLoader ?
                                            <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                        :
                                            <div className="crs_sgl_infos_all">
                                                <div className="crs_sgl_classes">
                                                    <div className="sgl_classes_header">
                                                        <div className="sgl_classes_head_left">
                                                            <CustomCircularProgressWithLabel sx={{color:"var(--first-color)", background: 'var(--plat-five)', borderRadius: '50%'}} value={progressTwo} />

                                                            <div className="sgl_classes_title">
                                                                <h2>Aulas</h2>
                                                                <span>O curso possui {courseOnBanner.number_of_classes} aulas.</span>
                                                            </div>
                                                        </div>

                                                        <div className="sgl_classes_head_right">
                                                            {/* teste */}
                                                        </div>
                                                    </div>

                                                    {courseOnBanner.modules?.length > 0 &&
                                                        <div className="sgl_classes_sub_header">
                                                            <Box sx={{ width: '100%' }}>
                                                                <Box sx={{ /*borderBottom: 1,*/ borderColor: 'divider' }}>
                                                                    <CustomTabs 
                                                                        value={valueB} 
                                                                        onChange={handleChangeB} 
                                                                        variant="fullwith" 
                                                                        scrollButtons={false} 
                                                                        // centered 
                                                                        aria-label="basic tabs example" 
                                                                        sx={{
                                                                            '& .Mui-selected': { color: 'var(--plat-twelve) !important' },
                                                                            '& .MuiTabs-flexContainer': {
                                                                                border: '1px solid var(--plat-three) !important',
                                                                                background: 'transparent !important'
                                                                            },
                                                                        }}
                                                                    >
                                                                        {courseOnBanner.modules.map((module, index) => {
                                                                            return <CustomTab key={index} label={`Módulo ${module.order}`} {...a11yProps(index)} />
                                                                        })}
                                                                    </CustomTabs>
                                                                </Box>
                                                            </Box>
                                                        </div>
                                                    }

                                                    <div className="sgl_classes_content">
                                                        {quizLoader ?
                                                            <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                                        :
                                                            <TabPanel value={valueB} index={valueB} disablePadding={true}>
                                                                {singleLoader ?
                                                                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                                                :
                                                                    <>
                                                                        {!courseOnBanner?.modules[valueB]?.classes ?
                                                                            <div className="plt_class_alert">
                                                                                <p>Nenhuma aula foi encontrada!</p>
                                                                            </div>
                                                                        :
                                                                            <div className="box_tbl_plat">
                                                                                <table className="tbl_plat_classes">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Aula</th>
                                                                                            <th>Título</th>
                                                                                            <th>Descrição</th>
                                                                                            <th>Duração</th>
                                                                                            <th>Assistida</th>
                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody> {/*AQUII SE NAO TIVER NENHUMA UKLA CADASTRADA, MOSTRAR QUE NO EXISTE (CURSO LARVEL MONTANDO API) */}
                                                                                        {courseOnBanner?.modules[valueB]?.classes.map((cls, index) => {
                                                                                            return (
                                                                                                <tr key={index} dataId={cls.id} onClick={() => redirectTo(`${courseOnBanner.slug}/${cls.id}`)}>
                                                                                                    <td>{cls.order}</td>
                                                                                                    <td>{cls.title}</td>
                                                                                                    <td>{cls.description}</td>
                                                                                                    <td>{cls.duration_total}</td>
                                                                                                    <td className="alignment">{cls.is_watched ? <CheckCircleOutlineOutlinedIcon color="fifthcolor"/> : <RadioButtonUncheckedOutlinedIcon color="fifthcolor"/>}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })}
                                                                                    </tbody> 
                                                                                </table>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </TabPanel>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </TabPanel>

                                    <TabPanel value={value} index={1}>
                                        {singleLoader ?
                                            <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                        :
                                            <div className="crs_sgl_infos_all">
                                                <div className="crs_sgl_infos_new_2">
                                                    <h2>Apresentação</h2>
                                                    <p className="intro_crs">Assista ao vídeo de apresentação do curso.</p>

                                                    <CustomLoadingButtonOutlined
                                                        onClick={() => setModalCourseIntroVideoStatus(!modalCourseIntroVideoStatus)}
                                                        // endIcon={<PsychologyAltOutlinedIcon />}
                                                        variant="outlined"
                                                        color="secondary"
                                                    >
                                                        <span>Assistir Vídeo</span>
                                                    </CustomLoadingButtonOutlined>
                                                </div>
                                            </div>
                                        }
                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                            {singleLoader ?
                                                <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                            :
                                                <div className="crs_sgl_infos_all">
                                                    <div className="crs_sgl_infos_new">
                                                        <h2>Sobre o curso</h2>

                                                        <div className="crs_sgl_infos_dscp">
                                                            <div>
                                                                <div className="b_sgl_fdescp" dangerouslySetInnerHTML={{ __html: courseOnBanner.full_description}} />

                                                                <div className="b_sgl_more_infos">
                                                                    <OndemandVideoOutlinedIcon sx={{color: "var(--first-color)", marginRight:"12px"}} />
                                                                    <p><b>{courseOnBanner.number_of_classes}</b> vídeo aulas</p>
                                                                </div>
                                                                <div className="b_sgl_more_infos">
                                                                    <TimerOutlinedIcon sx={{color: "var(--first-color)", marginRight:"12px"}} /> 
                                                                    <p><b>{courseOnBanner.duration_total}</b> de duração total</p>
                                                                </div>
                                                                <div className="b_sgl_more_infos">
                                                                    <MovieCreationOutlinedIcon sx={{color: "var(--first-color)", marginRight:"12px"}} /> 
                                                                    <p>Curso produzido <b>{courseOnBanner.publication_date}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="crs_sgl_infos_new">
                                                        <h2>Sobre o professor(a)</h2>

                                                        <div className="crs_sgl_infos_dscp">
                                                            <img className="pic" src={courseOnBanner.teacher.picture} alt="" title=""/>
                                                            
                                                            <div>
                                                                <p className="teacher_name"><b>{courseOnBanner.teacher.name}</b></p>
                                                                <p>{courseOnBanner.teacher.profession}</p>
                                                            </div>
                                                        </div>

                                                        <div className="crs_sgl_infos_dscp">
                                                            <p>{courseOnBanner.teacher.biography}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                    </TabPanel>

                                    <TabPanel value={value} index={3}>
                                        {singleLoader ?
                                            <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                        :
                                            <>
                                                {quiz ?
                                                    <div className="crs_sgl_infos_all">
                                                        <div className="crs_sgl_quiz">
                                                            <div className="sgl_quiz_header">
                                                                <div className="sgl_quiz_head_left">
                                                                    <CustomCircularProgressWithLabel sx={{color:"var(--first-color)", background: 'var(--plat-five)', borderRadius: '50%'}} value={progress} />

                                                                    <div className="sgl_quiz_title">
                                                                        <h2>Desafio {questionCurrent + 1}</h2>
                                                                        <span>Questão {questionCurrent + 1} de {quiz.total_questions}</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="sgl_quiz_head_right">
                                                                    <button className={`${questionCurrent === 0 && 'btn_disable'}`} onClick={() => setQuestionCurrent(questionCurrent - 1)}>
                                                                        <ChevronLeftOutlinedIcon titleAccess="Anterior" fontSize="large" color="neutral" />
                                                                    </button>

                                                                    <button className={`${questionCurrent === (quiz.total_questions - 1) && 'btn_disable'}`} onClick={() =>setQuestionCurrent(questionCurrent + 1)}>
                                                                        <ChevronRightOutlinedIcon titleAccess="Próxima" fontSize="large" color="neutral" />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="sgl_quiz_content">
                                                                {quizLoader ?
                                                                    <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                                                :
                                                                    <>
                                                                        <div className="sgl_quiz_cont_left">
                                                                            <div>
                                                                                <span>Acertando você ganha {quiz.question.points}pts</span>
                                                                                <h1>{quiz.question.question}</h1>
                                                                            </div>
                                                                            
                                                                            <div className="result_feedback">
                                                                                {isRight === true &&
                                                                                    <p>
                                                                                        <ThumbUpOutlinedIcon color="secondary" fontSize="large"/>
                                                                                        <span>Parabéns, você acertou!</span>
                                                                                    </p>
                                                                                }

                                                                                {isRight === false &&
                                                                                    <>
                                                                                        <p>
                                                                                            <ThumbDownAltOutlinedIcon sx="color: var(--error-color)" fontSize="large"/>
                                                                                            <span className="qst_error">Opss, você errou!</span>
                                                                                        </p>

                                                                                        <div className="qst_warn">
                                                                                            <span><b>Alternativa correta:</b></span>
                                                                                            <span>{rightResponse}</span>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="sgl_quiz_cont_right" style={isRight !== null ? {pointerEvents: 'none'} : {}}>
                                                                            {quiz.question.responses.map((resp, index) => {
                                                                                return <button key={index} className={`question ${resp.checked === true && `qst_checked ${resp.is_right === true ? 'qst_correct' : resp.is_right === false && 'qst_incorrect'}`}`} onClick={() => responseChecked(resp.id)}>{resp.item}</button>
                                                                            })}

                                                                            <CustomLoadingButton
                                                                                onClick={(JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false) ? 
                                                                                    () => showModal(2) 
                                                                                : 
                                                                                    (e) => confirmResponse(e) 
                                                                                }
                                                                                endIcon={<SendOutlinedIcon />}
                                                                                loading={spinnerBtnLoader}
                                                                                disabled={isRight !== null ? true : false}
                                                                                loadingPosition="end"
                                                                                variant="contained"
                                                                                size="large"
                                                                                fullWidth
                                                                                color="secondary"
                                                                            >
                                                                                <span>Confirmar</span>
                                                                            </CustomLoadingButton>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="crs_sgl_infos_all">
                                                        <div className="crs_sgl_infos_new_2">
                                                            <h2>Desafios:</h2>
    
                                                            <div className="crs_sgl_infos_dscp">
                                                                <div>
                                                                    <p>Esse curso ainda não possui desafios!</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </TabPanel>

                                    <TabPanel value={value} index={4}>
                                        {singleLoader ?
                                            <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                        :
                                            <div className="crs_sgl_infos_all">
                                                <div className="crs_sgl_infos_new_2">
                                                    <div className="certificate_title">
                                                        <CustomCircularProgressWithLabel sx={{color:"var(--first-color)", background: 'var(--plat-five)', borderRadius: '50%'}} value={progressTwo} />
                                                        <h2>Certificado</h2>
                                                    </div>
                                                    
                                                    <p className="intro_crs">Complete 100% do curso para ter acesso ao certificado de conclusão.</p>

                                                    <CustomLoadingButtonOutlined
                                                        onClick={(JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false) ? 
                                                            () => showModal(1) 
                                                        : 
                                                            () => certificateGenerate() 
                                                        }
                                                        variant="outlined"
                                                        color="secondary"
                                                        loading={certifLoader}
                                                        disabled={progressTwo !== 100 ? true : false}
                                                        loadingPosition="end"
                                                        sx={{
                                                            "&.Mui-disabled": {
                                                                color: "rgba(255, 255, 255, 0.18)",
                                                                border: '1px solid rgba(255, 255, 255, 0.06)'
                                                            }
                                                        }}
                                                    >
                                                        <span>Gerar Certificado</span>
                                                    </CustomLoadingButtonOutlined>
                                                </div>
                                            </div>
                                        }
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }

            <ModalCourseIntroVideo modalStatus={modalCourseIntroVideoStatus} setModalStatus={setModalCourseIntroVideoStatus} videoEmbed={courseOnBanner.intro_video} />
            <ModalUserBlocked modalStatus={modalBlockedStatus} setModalStatus={setModalBlockedStatus} messageType={messageType} />
        </div>
    )
}

export default LaboratorySingle