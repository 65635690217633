import '../../style.css'

function MenuAdminLoader({quantityItems}) {
    return (
        <div className="placeholder">
            <div className='line_loader'>
                <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    
                    {/* Menu */}
                    {quantityItems.map((item, index) => {
                        return (
                            <div className='pulse' key={index}>
                                <div style={{width:"28px", height: '28px', borderRadius:"4px", margin:"10px 0px"}}></div>
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </div>
    )
}

export default MenuAdminLoader;