import { useState } from 'react';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';

function StepAlertHelloUser ({nextStep, userName, stepNow = null, stepTotals = null, dark = true}) {
    const [stepLoader, setStepLoader] = useState(false)

    async function sendStep() {
        setStepLoader(true)

        setTimeout(() => {
            nextStep()
            setStepLoader(false)
        }, 300)
    }

    return (
        <div className='payment_box_big_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>Que bom te ver de novo, {userName}!</h1>
                <p>Para reativar sua assinatura, basta concluir todos os passos.</p>

                <CustomLoadingButton
                    onClick={() => sendStep()}
                    loading={stepLoader}
                    variant="contained"
                    fullWidth
                    color='secondary'
                    size="large"
                >
                    <span>Próximo</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepAlertHelloUser;