import '../../../style.css'
import './style.css'

function CourseLoader() {
    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='loader_box_crs'>
                    <div className='pulse loader_crs_box_int'>
                        <div className='loader_crs_header'></div>
                    </div>

                    <div className='pulse loader_crs_box_int_b'>
                        <div className='loader_crs_description'></div>
                        <div className='loader_crs_description'></div>
                        <div className='loader_crs_description'></div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default CourseLoader;