import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../../context/AuthContext";

function SectionBeStudent() {
    const history = useHistory()
    const { authenticated } = useContext(Context)

    function redirectTo(link) {
        if (link) {
            history.push(link)
        }
    }

    const openLinkInSameTab = url => {
        window.location.href = url;
    };

    return (
        <>
            {authenticated ?
                <div className="web_home_sec3">
                    <div className="web_home_sec3_inside"> 
                        <div className='bg_img_sec3'></div>

                        <div className='web_home_sec3_inside_content'>
                            <Grid container spacing={0} className="wb_sec_grid_center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h1>Área do aluno</h1>
                                    <p>Acesse nosso laboratório agora mesmo e usufrua os cursos disponíveis para seu plano. </p>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <LoadingButton
                                        onClick={() => openLinkInSameTab(process.env.REACT_APP_URL_BASE_LABORATORY)}
                                        endIcon={<SchoolOutlinedIcon />}
                                        variant="contained"
                                        size="large"
                                        color="neutral"
                                    >
                                        <span>Área do Aluno</span>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            :
                <div className="web_home_sec3">
                    <div className="web_home_sec3_inside"> 
                        <div className='bg_img_sec3'></div>

                        <div className='web_home_sec3_inside_content'>
                            <Grid container spacing={0} className="wb_sec_grid_center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h1>Quero ser aluno</h1>
                                    <p>Matricule-se agora e tenha acesso aos cursos, desafios, fórum e muito mais.</p>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <LoadingButton
                                        onClick={() => redirectTo('/matricule-se')}
                                        endIcon={<SchoolOutlinedIcon />}
                                        variant="contained"
                                        size="large"
                                        color="neutral"
                                    >
                                        <span>Matricule-se</span>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SectionBeStudent;