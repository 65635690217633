import '../style.css'
import React, { useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import { Grid } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';

function ModalDelete({modalStatus, setModalStatus, handleDelete}) {
    const { loading, setLoading } = useContext(Context)

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>
            <div className='modal mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <AutoDeleteOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3>Deseja remover o registro?</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        <p className='mdl_blk'>Tem certeza que deseja remover o registro?</p>
                    </div>

                    <div className="modal_footer">
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={(e) => handleDelete(e, setLoading)}
                                    endIcon={<DeleteOutlineOutlinedIcon />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                >
                                    <span>Confirmar Remoção</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDelete;