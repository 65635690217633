import '../../style.css'
import './style.css'

function HomeLoader() {

    const Article = () => {
        return (
            <div className='loader_article'>
                <div className='pulse'>
                    <div className="loader_rectangle"></div>
                </div>
    
                <div className='pulse' style={{
                    display:"flex", justifyContent:"flex-start", alignItems:"center"
                }}>
                    <div className='loader_title'></div>
                </div>
    
                <div className='pulse'>
                    <div className='loader_description_a'></div>
                    <div className='loader_description_b'></div>
                </div>
            </div>
        )
    }

    return (
        <div className="placeholder">
            <div className='line_loader'>

                <div className='loader_box_articles'>
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                    <Article />
                </div>
                
            </div>
        </div>
    )
}

export default HomeLoader;