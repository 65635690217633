import '../style.css'
import './style.css'
import React, { useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../../helpers/Crop';
import { toast } from 'react-toastify'
import api from '../../../services/api'
import { Grid } from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton'
import { CustomSlider } from '../../MaterialCustom/General/Slider/CustomSlider'

function ModalCropUpdatePhoto({modalStatus, setModalStatus}) {
    const { loading, setLoading, formProfilePhotoUrl, setFormProfilePhotoUrl, setUserPicture, handleLogout } = useContext(Context)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    function onCropComplete(croppedArea, croppedAreaPixels) {
        // console.log(croppedAreaPixels.width / croppedAreaPixels.height)
        setCroppedAreaPixels(croppedAreaPixels)
    }

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    async function handleSaveImage() {
        setLoading(true)
        
        try {
            const { file, url } = await getCroppedImg(formProfilePhotoUrl, croppedAreaPixels);

            setFormProfilePhotoUrl(url);

            //Dispara requisição para alterar foto de perfil
            sendPhoto(file) 

            setModalStatus(false)
        } catch (error) {
            toast.error('Falha ao alterar foto!')
            // console.log(error);
        }

        setLoading(false);
    }

    async function sendPhoto(file){
        let formData = new FormData();
        formData.append('thumb', file)

        await api.post(`meu-perfil/foto`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    let userUpdt = JSON.parse(localStorage.getItem('user'));
                    userUpdt.picture = response.data.data.link

                    //Altero o estado que armazena a foto do usuário logado
                    setUserPicture(userUpdt.picture)

                    //Altero no localstorage a chave 'nome' do usuario, caso tenha sido alterado
                    localStorage.setItem('user', JSON.stringify(userUpdt))

                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                // setSpinnerBtnLoader(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    // validateDataBack(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                }

                // setSpinnerBtnLoader(false)
            })
    }

    return (
        <div className={`modal_box modal_image ${modalStatus && 'modal_open'}`}>

            <div className='modal mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <CameraAltOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3 className='mdl_blk'>Alterar foto de Perfil</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                      
                        <div className="crop-big-container">
                            <div className="crop-container">
                                <Cropper
                                    image={formProfilePhotoUrl}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    cropShape="round"
                                    showGrid={false}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>

                            <div className="controls">
                                <CustomSlider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    // color="secondary"
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    classes={{ container: 'slider' }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="modal_footer">
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={() => handleSaveImage()}
                                    endIcon={<SendOutlinedIcon />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                >
                                    <span>Confirmar Alteração</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

        </div> 
    )
}

export default ModalCropUpdatePhoto;