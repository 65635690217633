import {useState, useEffect} from "react"
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import {setCookie, deleteCookie /*, getCookie*/} from '../../helpers'

export default function useAuth(props) {
    const [authenticated, setAuthenticated] = useState(localStorage.getItem('user') ? true : false)
    const [loading, setLoading] = useState(false) // usado para quando atualizar a page sempre executa o useEffect antes de qualquer outra requisição
    const [loadingMain, setLoadingMain] = useState(true)  //Loading pulsante com o logo da empresa no centro da tela
    const [userPicture, setUserPicture] = useState('')
    const [notifications, setNotifications] = useState([])
    const [reactivateSubscription, setReactivateSubscription] = useState(false)
    const [notificationsNotViewedCount, setNotificationsNotViewedCount] = useState(0)
    const history = useHistory()

    useEffect(() => {
        //Checa se o usuário está ainda logado sempre que for dado refresh ou recém acessou a plataforma
        // setLoadingMain(true)

        async function check() {
            //essa requisição deveria ser feita antes de todas as outras, por que se nao tem varias requests antes disso sendo disperdiçadas
            await api.get("/check").then((response) => { 
                setLoadingMain(false)
    
                //sucesso na request, mas pode ter dado erro  
                if (response.data.status === 'success') {
                    let userObj = response.data.data.user;
    
                    //Armazeno foto do usuário logado no estado
                    setUserPicture(userObj.picture)
    
                    localStorage.setItem('user', JSON.stringify(userObj))
                    setNotifications(response.data.data.notifications)
                    setNotificationsNotViewedCount(response.data.data.notifications_not_viewed)

                    //Se a assinatura do usuário não for mais válida eu deixo ele logar e redireciono ele para a tela de renovação de assinatura (professores nao precisa)
                    if (response.data.data?.subscription_status === false) {
                        setReactivateSubscription(true)
                    }
    
                    setAuthenticated(true)
                    
                    //O token é válido portanto não precisa deslogar o usuário
                    return
                } else {
                    if (authenticated) {
                        // console.log('caiu 1')
                        handleLogout()
                    }
                }
            }).catch((err) => {
                setLoadingMain(false)

                if (err.response.status === 401) {
                    // console.log('caiu 2')
                    if (authenticated) {
                        handleLogout()
                    }
                }
            });
        }

        /************COLOQUEI ESSE 'IF' e 'ELSE', TALVEZ DE ERRO AO ALTERNAR ENTRE O BLOG PRO SITE E VICE VERSA (POSSO TIRAR SE NECESSARIO)**********/
        // if (authenticated) {
            check()
        // } else {
            // setLoadingMain(false)
        // }
    }, [])

    // useEffect(() => {
    //     let verifyIfNeedRefreshToken = async () => {
    //         let baseUrlApi = process.env.REACT_APP_URL_BACKEND
    //         let refreshToken = getCookie('refresh_token')

    //         await fetch(`${baseUrlApi}/refresh-token`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type':'application/json'
    //             },
    //             body: JSON.stringify({
    //                 grant_type : "refresh_token",
    //                 client_id : 1,
    //                 client_secret : "TDtRyvVtunbUdCylrGtvjNfZOvVVSmsHnUGZ3348",
    //                 refresh_token : refreshToken,
    //                 scope : "" 
    //             }),
    //             keepalive: true
    //         })
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log('then', data.status)
    //             console.log('then2', data.data)

    //             if (data.status === 'success') {
    //                 let accessTokenExpireIn = (Date.now() + (data.data.expires_in * 1000))

    //                 setCookie("access_token", data.data.access_token, 30);
    //                 setCookie("expires_in", accessTokenExpireIn, 30);
    //                 setCookie("refresh_token", data.data.refresh_token, 30);
    //             } else {
    //                 handleLogout()
    //             }
    //         })
    //         .catch(function(err) {
    //             console.log(`Catch: ${err}`)
    //         });  
    //     } 

    //     //Verifica a cada 15 segundos se o token está perto de expirar
    //     let fifthySeconds = 1000 * 15
    //     let refreshInterval = setInterval(() => {

    //         if (authenticated) {
    //             let accessTokenExpiresIn = getCookie('expires_in')
    //             let datetimeCurrent = Date.now()

    //             // let accessTokenExpireIn = (Date.now() + (response.data.data.expires_in * 1000))
    //             let diffInMilSec = (accessTokenExpiresIn - datetimeCurrent)
    //             let diffInSec = Math.floor(diffInMilSec / 1000)

    //             //Falta menos de 60 segundos para expirar o token
    //             if (diffInSec < 60) {
    //                 console.log('Falta menos de 60 segundos para expirar o token', diffInSec)
    //                 verifyIfNeedRefreshToken()
    //             } else {
    //                 console.log('Falta mais de 60 segundos para expirar o token', diffInSec)
    //             }
                
    //         }

    //     }, fifthySeconds)

    //     return () => clearInterval(refreshInterval)
    // }, [authenticated])

    function handleLogin(event, userData, callbackSuccess) {
        event.preventDefault()
        setLoading(true)

        //faz requisição de login
        api.post("/login", {
            grant_type : "password",
			client_id : 1,
			client_secret : "TDtRyvVtunbUdCylrGtvjNfZOvVVSmsHnUGZ3348",
            username: userData.username,
            password: userData.password,
            scope : "" 
        }).then((response) => {
            if (response.data.status === 'success') {
                let accessTokenExpireIn = (Date.now() + (response.data.data.expires_in * 1000))
            
                setCookie("access_token", response.data.data.access_token, 30);
                setCookie("refresh_token", response.data.data.refresh_token, 30);
                setCookie("expires_in", accessTokenExpireIn, 30);

                // localStorage.setItem('access_token', response.data.data.access_token)
                // localStorage.setItem('refresh_token', response.data.data.refresh_token)
                // localStorage.setItem('expires_in', accessTokenExpireIn)

                localStorage.setItem('user', JSON.stringify(response.data.data.user))

                setAuthenticated(true)
                setUserPicture(response.data.data.user.picture)
                setNotifications(response.data.data.notifications)
                setNotificationsNotViewedCount(response.data.data.notifications_not_viewed)
                setLoading(false)

                //Se a assinatura do usuário não for mais válida eu deixo ele logar e redireciono ele para a tela de renovação de assinatura (professores nao precisa)
                if (response.data.data?.subscription_status === false) {
                    setReactivateSubscription(true)
                } else { // assinatura ainda está ativa e válida 
                    callbackSuccess(false)
                }
                

                //redireciona o usuário
                // history.push("/admin/dashboard");
            } else {
                setLoading(false)
                toast.error(response.data.message)
            }
        }).catch((err) => {
            // console.error("ops! ocorreu um erro" + err)

            setLoading(false)
            toast.error('Falha na requisição de login!')
        });
    }

    function handleLogout(event = null) {
        if (event) {
            event.preventDefault();
        }

        setLoadingMain(true)

        //faz requisição de logout
        api.get("/logout").then((response) => {
          //sucesso na request, mas pode ter dado erro  
        }).catch((err) => {
            // console.error("ops! ocorreu um erro" + err)
        }).finally(() => {
            deleteCookie('access_token')
            deleteCookie('refresh_token')
            deleteCookie('expires_in')
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('expires_in');
            // localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            setUserPicture('')

            setAuthenticated(false);
            setLoadingMain(false);

            history.push('/')
        });
    }

    return { loading, setLoading, authenticated, handleLogin, handleLogout, loadingMain, setLoadingMain, userPicture, setUserPicture, notifications, setNotifications, notificationsNotViewedCount, setNotificationsNotViewedCount, reactivateSubscription, setReactivateSubscription }
}