import { useEffect, useState, useRef, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import './style.css'
import api from '../../../services/api'
import CourseItem from '../../../components/Website/CourseItem';
import { Grid } from '@mui/material';
import { useParams } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LoadingButton } from '@mui/lab';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import CategoryOrSubcategoryLoader from '../../../components/Loaders/Divs/Website/CategoryOrSubcategoryLoader/Top'
import CategoryOrSubcategoryLoaderContent from '../../../components/Loaders/Divs/Website/CategoryOrSubcategoryLoader/Bottom'
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import SectionBeStudent from '../../../components/Website/SectionBeStudent';

function WebsiteCategory ({isLaboratory = false}) {
    const { showAlert, alertInTopClosed, setMetaTagsOfPage, handleLogout } = useContext(Context);
    const [category, setCategory] = useState({});
    const {categorySlug} = useParams();
    // const [subCategorysTitle, setSubCategorysTitle] = useState([])
    const [subcategorys, setSubcategorys] = useState([])
    const [courses, setCourses] = useState([])
    const [spinnerBtnLoader /*, setSpinnerBtnLoader*/] = useState(false)
    const [showFilterModalMobile, setShowFilterModalMobile] = useState(false)
    const [allCoursesLoader2, setAllCoursesLoader2] = useState(false)
    const [websiteCatSubcatLoader, setWebsiteCatSubcatLoader] = useState(true)
    const isMobile = useRef(false);

    //Filtros e Ordenação
    const [orderBy, setOrderBy] = useState(1);
    const [notFilterBySubCategs, setNotFilterBySubCategs] = useState([]); //Armazena os filtros que foram retirados da consulta
    const [notFilterByLevel, setNotFilterByLevel] = useState([]);

    const handleChange = (event) => {
        setOrderBy(event.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0)

        async function getData() {
            await api.get(`cursos/${categorySlug}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setMetaTagsOfPage(`Cursos de ${response.data.data.category.name}`, `${response.data.data.category.description}`)
                        setCategory(response.data.data.category)
                        setSubcategorys(response.data.data.subcategorys)
                        setCourses(response.data.data.courses)

                        setWebsiteCatSubcatLoader(false)
                    }
                }).catch((error) => {
                    if (isLaboratory && error.response.status === 401) {
                        handleLogout()
                    }
                })
        }

        function getWidthScreen() {
            let windowWidth = window.innerWidth;

            if (windowWidth >= 900) {
                isMobile.current = false
            } else {
                isMobile.current = true
            }
        }

        getWidthScreen()
        getData()

        window.addEventListener('resize', getWidthScreen);

        return () => {
            window.removeEventListener('resize', getWidthScreen);
        }
    }, [])

    useEffect(() => {
        async function getDataWithFilters() {
            setAllCoursesLoader2(true)

            await api.get(`cursos/${categorySlug}`, {params: {
                filter_by_subcategory: notFilterBySubCategs, 
                category_id: category?.id,
                filter_by_level: notFilterByLevel,
                order_by: orderBy
            }}).then((response) => {
                if (response.data.status === 'success') {
                    setCourses(response.data.data.courses)
                    setAllCoursesLoader2(false)
                }
            }).catch((error) => {
                if (isLaboratory && error.response.status === 401) {
                    handleLogout()
                }
            })
        }

        if (category?.id && isMobile.current === false) { //garante que só carrega se o filtro for alterado, e se a tela é grande/desktop
            getDataWithFilters()
        }
    }, [notFilterBySubCategs, notFilterByLevel])

    useEffect(() => {
        async function getDataWithFilters() {
            setAllCoursesLoader2(true)

            await api.get(`cursos/${categorySlug}`, {params: {
                filter_by_subcategory: notFilterBySubCategs, 
                category_id: category?.id,
                filter_by_level: notFilterByLevel,
                order_by: orderBy
            }}).then((response) => {
                if (response.data.status === 'success') {
                    setCourses(response.data.data.courses)
                    setAllCoursesLoader2(false)
                }
            }).catch((error) => {
                if (isLaboratory && error.response.status === 401) {
                    handleLogout()
                }
            })
        }

        if (category?.id) { //garante que só carrega se o filtro for alterado, e se a tela é grande/desktop
            getDataWithFilters()
        }
    }, [orderBy])

    async function handleFilter(e) {
        setShowFilterModalMobile(!showFilterModalMobile)
        setAllCoursesLoader2(true)

        await api.get(`cursos/${categorySlug}`, {params: {
            filter_by_subcategory: notFilterBySubCategs, 
            category_id: category?.id,
            filter_by_level: notFilterByLevel,
            order_by: orderBy
        }}).then((response) => {
            if (response.data.status === 'success') {
                setCourses(response.data.data.courses)
                setAllCoursesLoader2(false)
            }
        }).catch((error) => {
            if (isLaboratory && error.response.status === 401) {
                handleLogout()
            }
        })
    }

    function changeFilterSubcategorys(subCategoryId) {
        const isFound = notFilterBySubCategs.some(id => {
            if (id === subCategoryId) {
                return true;
            }
        
            return false;
        });
        
        if (isFound) { //se já existe então deve remover do state
            setNotFilterBySubCategs(notFilterBySubCategs.filter(id => id !== subCategoryId));
        } else { //se não existir no state, então deve coloca-lo
            setNotFilterBySubCategs([...notFilterBySubCategs, subCategoryId])
        } 
    }

    function changeFilterLevel(levelId) {
        const isFound = notFilterByLevel.some(id => {
            if (id === levelId) {
                return true;
            }
        
            return false;
        });
        
        if (isFound) { //se já existe então deve remover do state
            setNotFilterByLevel(notFilterByLevel.filter(id => id !== levelId));
        } else { //se não existir no state, então deve coloca-lo
            setNotFilterByLevel([...notFilterByLevel, levelId])
        } 
    }

    return (
        <>
            <div className={`wb_sec_4 box_website website_section ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>
                {websiteCatSubcatLoader ?
                    <CategoryOrSubcategoryLoader />
                :
                    <div className="courses_menu">
                        <div className='box_categorys'>
                            <div className='box_categs_tit_ic'>
                                {category.slug === 'front-end' && <ViewQuiltOutlinedIcon fontSize="large" color='neutral' />}
                                {category.slug === 'back-end' && <IntegrationInstructionsOutlinedIcon fontSize="large" color='neutral' />}
                                {category.slug === 'devops' && <SettingsSuggestOutlinedIcon fontSize="large" color='neutral' />}

                                <h6 className='tit_cat_filter'>Cursos de {category.name}</h6>
                            </div>

                            <div className='crs_tit_subct'>
                                <p className='tit_cat_filter'>{category.description}</p>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="wb_sec_5 box_website website_section">
                {websiteCatSubcatLoader ?
                    <CategoryOrSubcategoryLoaderContent />
                :
                    <>
                        <div className="crs_catgs_filters" style={{display: showFilterModalMobile ? "block" : "none"}}>
                            <div className="filter_box_header">
                                <h1>Filtrar</h1>
                                <CloseOutlinedIcon onClick={() => setShowFilterModalMobile(!showFilterModalMobile)} color="neutral" sx={{cursor:"pointer"}} fontSize="large"/>
                            </div>

                            <div className='filter_box_content'>
                                <div className="filter_fields">
                                    <div>
                                        <h5>Filtrar por curso</h5>
                                        
                                        <FormGroup>
                                            {subcategorys.map((subCat, index) => { 
                                                return <FormControlLabel 
                                                    key={index} 
                                                    control={<Checkbox 
                                                        defaultChecked 
                                                        sx={{
                                                            color: "var(--first-color)",
                                                            '&.Mui-checked': {
                                                                color: "var(--first-color)",
                                                            },
                                                        }}
                                                    />} 
                                                    onChange={(e) => changeFilterSubcategorys(subCat.id)}
                                                    label={subCat.subcategory} 
                                                    className='filter_label' 
                                                />
                                            })}
                                        </FormGroup>
                                    </div>

                                    <div>
                                        <h5 className='h5_spacement'>Nível</h5>

                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked sx={{color: "var(--first-color)", '&.Mui-checked': {color: "var(--first-color)"}}} />} onChange={(e) => changeFilterLevel(1)} label="Iniciante" className='filter_label' />
                                            <FormControlLabel control={<Checkbox defaultChecked sx={{color: "var(--first-color)", '&.Mui-checked': {color: "var(--first-color)"}}} />} onChange={(e) => changeFilterLevel(2)} label="Intermediário" className='filter_label' />
                                            <FormControlLabel control={<Checkbox defaultChecked sx={{color: "var(--first-color)", '&.Mui-checked': {color: "var(--first-color)"}}} />} onChange={(e) => changeFilterLevel(3)} label="Avançado" className='filter_label' />
                                        </FormGroup>
                                    </div>
                                </div>

                                <LoadingButton
                                    onClick={(e) => handleFilter(e)}
                                    endIcon={<FilterAltOutlinedIcon />}
                                    loading={spinnerBtnLoader}
                                    loadingPosition="end"
                                    variant="contained"
                                    className="button_filter"
                                    fullWidth
                                    color="secondary"
                                    size='large'
                                >
                                    <span>Aplicar Filtro</span>
                                </LoadingButton>
                            </div>
                        </div>


                        <div style={{width: '100%'}}>
                            <div className="crs_courses_filters">
                                <>
                                    <div className='box_mobile_filters'> {/* Botão disponível apenas no Mobile */}
                                        <h5>Filtrar por</h5>

                                        <LoadingButton
                                            onClick={(e) => setShowFilterModalMobile(!showFilterModalMobile)}
                                            endIcon={<FilterAltOutlinedIcon />}
                                            variant="outlined"
                                            color="secondary"
                                            size="large"
                                        >
                                            <span>Filtrar</span>
                                        </LoadingButton>
                                    </div>

                                    <div className='crs_order'>
                                        <h5>Ordenar por</h5>

                                        <FormControl className="formControlSizeFilter" size='small'>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={orderBy}
                                                sx={{
                                                    color: "var(--plat-twelve)",
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--plat-twelve)',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--plat-twelve)',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--plat-twelve)',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "var(--plat-twelve) !important",
                                                    }
                                                }}
                                                onChange={handleChange}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }
                                                }}
                                                inputProps={{
                                                    MenuProps: {
                                                        MenuListProps: {
                                                            sx: {
                                                                backgroundColor: 'var(--plat-two)',
                                                                color: 'var(--plat-seven)'
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                <MenuItem value={1} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>Últimos lançamentos</MenuItem>
                                                <MenuItem value={2} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>Melhores avaliados</MenuItem>
                                                <MenuItem value={3} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>Gratuitos</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="crs_qtty_results">
                                        {courses?.length === 1 &&
                                            <span>{courses?.length} curso encontrado</span>
                                        }

                                        {courses?.length > 1 &&
                                            <span>{courses?.length} cursos encontrados</span>
                                        }
                                    </div>
                                </>
                            </div>

                            <div className="crs_qtty_results_mobile">
                                {courses?.length === 1 &&
                                    <span>{courses?.length} curso encontrado</span>
                                }

                                {courses?.length > 1 &&
                                    <span>{courses?.length} cursos encontrados</span>
                                }
                            </div>

                            <Grid container spacing={3} alignItems="stretch">
                                {allCoursesLoader2 ?
                                    <SpinnerLoader borderColorImp='var(--plat-seven)' />
                                :
                                    <> 
                                        {courses?.length === 0 ?
                                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <p>Nenhum curso encontrado!</p>
                                            </Grid>
                                        :
                                            <>
                                                {courses.map((course, index) => { 
                                                    return (
                                                        <Grid container item xs={12} sm={12} md={12} lg={6} xl={4} key={index}>
                                                            <CourseItem name={course.name} isFree={course.is_free} evaluation={course.avg_evaluation} level={course.level} durationTotal={course.duration_total} quantityOfClasses={course.number_of_classes} slugCategory={course.link_category} slugSubCategory={course.link_subcategory} slugCourse={course.slug} category={course.category} subcategory={course.subcategory} description={course.description} teacher={course.author} subcategoryBgColor={course.subcat_bg_color} publicationDate={course.publication_date} isLaboratory={isLaboratory} /*primary_color={'var(--first-color)'} secondary_color={'var(--secondary-color)'} characteristics={'asdasdas'} number_of_classes={'14'} duration_total={'2'}*/ />
                                                        </Grid>
                                                    )
                                                })}
                                            </>
                                        }
                                    </>
                                }
                            </Grid>
                        </div>
                    </>
                }
            </div>

            {isLaboratory !== true &&
                <SectionBeStudent/>
            }
        </>
    )   
}

export default WebsiteCategory;