import '../style.css'
import './style.css'
import React, { useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import Form from '../../Form'
import { Grid, FormControl, InputAdornment, IconButton, FormHelperText } from '@mui/material'
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import KeyIcon from '@mui/icons-material/Key';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton'
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField';
import { CustomTooltipDark } from '../../MaterialCustom/General/Tooltip/CustomTooltip'

function ModalChangePassword({modalStatus, setModalStatus}) {
    const { loading, setLoading, handleLogout } = useContext(Context)
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    const [passwordData, setPasswordData] = useState({
        password_current: '',
        password_new: ''
    })

    const [fieldsError, setFieldsError] = useState({
        password_current: '',
        password_new: ''
    })

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    async function handleChangePassword(e) {
        e.preventDefault()
        setLoading(true)

        await api.post('alterar-senha', passwordData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setPasswordData({password_current: '', password_new: ''})
                    setModalStatus(false)
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({password_current: '', password_new: ''})
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                }

                setLoading(false)
            })
    }

    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleClickShowPassword2() {
        setShowPassword2(!showPassword2)
    }

    function handleMouseDownPassword(event) {
        event.preventDefault();
    }

    return (
        <>
            <div className={`modal_box ${modalStatus && 'modal_open'}`}>

                <div className='modal mdl_blk'>
                    <div className="modal_content">
                        <div className='modal_header mdl_blk'>
                            <div className='modtitbox'>
                                <PasswordOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                <h3 className='mdl_blk'>Alterar senha</h3>
                            </div>

                            <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                        </div>

                        <div className="modal_content_middle">

                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <CustomTextField 
                                                id="outlined-adornment-password" 
                                                autoComplete='off'
                                                type={showPassword ? 'text' : 'password'} 
                                                label="Senha Atual"
                                                size="medium"
                                                {...(fieldsError.password_current && {error: 'true'})}
                                                fullWidth
                                                name='password_current'
                                                onChange={(e) => setPasswordData({...passwordData, password_current: e.target.value})} 
                                                value={passwordData.password_current}
                                                InputLabelProps={{
                                                    sx: {
                                                    ...inputLabelStyles,
                                                    },
                                                }}
                                                InputProps={{ 
                                                    maxLength: 200,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                                {showPassword ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    
                                                }}
                                            />

                                            <FormHelperText>
                                                {fieldsError.password_current}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <CustomTextField 
                                                id="outlined-adornment-password2" 
                                                autoComplete='off'
                                                type={showPassword2 ? 'text' : 'password'} 
                                                label="Senha Nova"
                                                size="medium"
                                                {...(fieldsError.password_new && {error: 'true'})}
                                                fullWidth
                                                name='password_new'
                                                onChange={(e) => setPasswordData({...passwordData, password_new: e.target.value})} 
                                                value={passwordData.password_new}
                                                InputLabelProps={{
                                                    sx: {
                                                    ...inputLabelStyles,
                                                    },
                                                }}
                                                InputProps={{ 
                                                    maxLength: 200,
                                                    endAdornment:
                                                        <>
                                                            <CustomTooltipDark
                                                                placement="top-end"
                                                                title={
                                                                    <React.Fragment>
                                                                        <span className="tooltiptext">
                                                                            <h6>A senha deve ter: </h6>

                                                                            <ul>
                                                                                <li>no mínimo 6 caracteres</li>
                                                                                <li>no mínimo um número</li>
                                                                                <li>letras maiúsculas e minúsculas</li>
                                                                                <li>caractere especial</li>
                                                                            </ul>
                                                                        </span>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton>
                                                                    <NotListedLocationOutlinedIcon color="sixthcolor" />
                                                                </IconButton>
                                                            </CustomTooltipDark>

                                                            <InputAdornment position="end">
                                                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword} edge="end">
                                                                    {showPassword2 ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        </>
                                                }}
                                            />

                                            <FormHelperText>
                                                {fieldsError.password_new}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Form>

                        </div>

                        <div className="modal_footer">
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={(e) => handleChangePassword(e)}
                                        endIcon={<KeyIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                        fullWidth
                                        color="secondary"
                                    >
                                        <span>Alterar Senha</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalChangePassword;