import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, MenuItem, Autocomplete } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function NotificationsForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const [loaderTab3, setLoaderTab3] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)

    const [formNotifications, setFormNotifications] = useState({
        title: '',
        message: '',
        notification_date: '',
        type: '',
        link: '',
        status: '',
    })

    const [fieldsError, setFieldsError] = useState({
        title: '',
        message: '',
        notification_date: '',
        type: '',
        link: '',
        status: '',

        profiles: '',
        users: ''
    })

    const [typeOptions, setTypeOptions] = useState([
        {id: 1, name: 'Aviso'},
        {id: 2, name: 'Importante'}
    ])

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const [tableHistrcCell, setTableHistrcCell] = useState([])

    const [usersOptions, setUsersOptions] = useState([])
    const [profilesOptions, setProfilesOptions] = useState([])

    const [listProfiles, setListProfiles] = useState([])
    const [listUsers, setListUsers] = useState([])

    const title = useRef({
        name: 'Notificações',
        description_insert: 'Insira novas notificações no sistema',
        description_update: 'Altere notificações do sistema',
        link_from: '/admin/notificacoes'
    }) 

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)
            setLoaderTab3(true)
            
            api.get(`admin/notificacoes/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setFormNotifications(response.data.data.notification)
                        setListUsers(response.data.data.users)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            api.get(`admin/notificacoes/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca usuários e perfis existentes para por no select>options
            api.get('admin/notificacoes/options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setUsersOptions(response.data.data.users)
                        setProfilesOptions(response.data.data.profiles)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        } else {
            setLoaderTab2(true)
            setLoaderTab3(true)

            api.get('admin/notificacoes/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca usuários e perfis existentes para por no select>options
            api.get('admin/notificacoes/options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setUsersOptions(response.data.data.users)
                        setProfilesOptions(response.data.data.profiles)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setTitle(e) {
        setFormNotifications({...formNotifications, title: e.target.value})
    }

    function setMessage(e) {
        setFormNotifications({...formNotifications, message: e.target.value})
    }

    function setNotificationDate(e) {
        setFormNotifications({...formNotifications, notification_date: e.target.value})
    }

    function setLink(e) {
        setFormNotifications({...formNotifications, link: e.target.value})
    }

    function setType(e) {
        setFormNotifications({...formNotifications, type: e.target.value})
    }

    function setStatus(e) {
        setFormNotifications({...formNotifications, status: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        let mountDataSave = {...formNotifications, ...{profiles:listProfiles}, ...{users:listUsers}}

        await api.post('admin/notificacoes', mountDataSave)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormNotifications({title: '', message: '', notification_date: '', type: '', link: '', status: ''})

                    history.push("/admin/notificacoes")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({title: '', message: '', notification_date: '', type: '', link: '', status: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        // let mountDataSave = {...formNotifications, ...{profiles:listProfiles}, ...{users:listUsers}}

        await api.post(`admin/notificacoes/${regId}`, formNotifications)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/notificacoes")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({title: '', message: '', notification_date: '', type: '', link: '', status: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<HearingOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Ouvintes" {...a11yProps(1)}  />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(2)} />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Título"
                                                            onChange={setTitle}
                                                            value={formNotifications.title} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='title'
                                                            autoComplete='off'
                                                            {...(fieldsError.title && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 150 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.title}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={8}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Mensagem"
                                                            onChange={setMessage}
                                                            value={formNotifications.message} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='message'
                                                            autoComplete='off'
                                                            {...(fieldsError.message && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 300 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.message}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formNotifications.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Tipo de notificação"
                                                            {...(fieldsError.type && {error: 'true'})}
                                                            {...(regId && {disabled: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="type"
                                                            onChange={setType} 
                                                            value={formNotifications.type} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {typeOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.type}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Data da notificação"
                                                            type="datetime-local"
                                                            name="notification_date"
                                                            size="medium"
                                                            // defaultValue="2017-05-24"
                                                            {...(fieldsError.notification_date && {error: 'true'})}
                                                            onChange={setNotificationDate}
                                                            value={formNotifications.notification_date}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.notification_date}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            label="Link"
                                                            onChange={setLink}
                                                            value={formNotifications.link} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='link'
                                                            {...(!regId && {helperText: "Exemplo: https://site.com/teste"})}
                                                            autoComplete='off'
                                                            {...(fieldsError.link && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    color: 'var(--plat-twelve)'
                                                                },
                                                              }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.link}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_list'>
                                        {regId ?
                                            <Form>
                                                <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Autocomplete
                                                                multiple
                                                                disabled
                                                                options={usersOptions}
                                                                getOptionLabel={(option) => option.name}
                                                                // {...(!regId && {defaultValue: [usersOptions[2], usersOptions[3]]})}
                                                                {...(regId && {defaultValue: listUsers})}
                                                                onChange={(event, options) => setListUsers(options)}
                                                                limitTags={3}
                                                                filterSelectedOptions
                                                                sx={{
                                                                    "& .MuiChip-root": {
                                                                        backgroundColor: "var(--plat-ten)"
                                                                    },
                                                                    "& .MuiChip-label": {
                                                                        color: "var(--plat-seven)"
                                                                    },
                                                                    "& .MuiChip-deleteIcon": {
                                                                        color: "var(--plat-seven) !important"
                                                                    },
                                                                }}
                                                                renderInput={(params) => (
                                                                    <CustomTextField
                                                                        {...params}
                                                                        label="Usuários(as)"
                                                                        placeholder="Ouvintes"
                                                                        {...(fieldsError.users && {error: 'true'})}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        :
                                            <Form>
                                                <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Autocomplete
                                                                multiple
                                                                options={profilesOptions}
                                                                getOptionLabel={(option) => option.name}
                                                                // {...(!regId && {defaultValue: [profilesOptions[2], profilesOptions[3]]})}
                                                                {...(regId && {defaultValue: listProfiles})}
                                                                onChange={(event, options) => setListProfiles(options)}
                                                                limitTags={3}
                                                                filterSelectedOptions
                                                                sx={{
                                                                    "& .MuiChip-root": {
                                                                        backgroundColor: "var(--plat-ten)"
                                                                    },
                                                                    "& .MuiChip-label": {
                                                                        color: "var(--plat-seven)"
                                                                    },
                                                                    "& .MuiChip-deleteIcon": {
                                                                        color: "var(--plat-seven) !important"
                                                                    },
                                                                }}
                                                                renderInput={(params) => (
                                                                    <CustomTextField
                                                                        {...params}
                                                                        label="Perfis"
                                                                        {...(fieldsError.profiles && {error: 'true'})}
                                                                        placeholder="Ouvintes"
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.profiles}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Autocomplete
                                                                multiple
                                                                options={usersOptions}
                                                                getOptionLabel={(option) => option.name}
                                                                // {...(!regId && {defaultValue: [usersOptions[2], usersOptions[3]]})}
                                                                {...(regId && {defaultValue: listUsers})}
                                                                onChange={(event, options) => setListUsers(options)}
                                                                limitTags={3}
                                                                filterSelectedOptions
                                                                sx={{
                                                                    "& .MuiChip-root": {
                                                                        backgroundColor: "var(--plat-ten)"
                                                                    },
                                                                    "& .MuiChip-label": {
                                                                        color: "var(--plat-seven)"
                                                                    },
                                                                    "& .MuiChip-deleteIcon": {
                                                                        color: "var(--plat-seven) !important"
                                                                    },
                                                                }}
                                                                renderInput={(params) => (
                                                                    <CustomTextField
                                                                        {...params}
                                                                        label="Usuários(as)"
                                                                        placeholder="Ouvintes"
                                                                        {...(fieldsError.users && {error: 'true'})}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.users}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        }
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab3 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default NotificationsForm;