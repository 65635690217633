import '../style.css'
import './style.css'
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import Form from '../../Form'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { Grid, FormControl, FormHelperText } from '@mui/material'
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import TopicIcon from '@mui/icons-material/Topic';
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function ModalCreateTopic({modalStatus, setModalStatus, setForumTopics, forumTopics, setForumTotalTopics, forumTotalTopics}) {
    const { loading, setLoading, handleLogout } = useContext(Context)
    const {classId} = useParams()
    const history = useHistory()
    
    const [topic, setTopic] = useState({
        subject: '',
        message: '',
    })

    const [fieldsError, setFieldsError] = useState({
        subject: '',
        message: '',
    })

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    async function handleCreateTopic() {
        setLoading(true)

        //disparar request de criação de topico
        await api.post(`create-topic/${classId}`, topic)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    setForumTopics([...[response.data.data.topic], ...forumTopics])
                    setForumTotalTopics(forumTotalTopics + 1)

                    setTopic({subject: '', message: ''})
                    setModalStatus(false)
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({subject: '', message: ''});
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data);
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    toast.error(error.response.data.message)
                }

                setLoading(false)
            })
    }

    function redirectTo(link) {
        if (link) {
            history.push(link)
        }
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal_big mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            {JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false ?
                                <>
                                    <LockOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                    <h3 className='mdl_blk'>Bloqueado</h3>
                                </>
                            :
                                <>
                                    <TopicIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                    <h3 className='mdl_blk'>Criar novo tópico</h3>
                                </>
                            }
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        {/* Verifica se o aluno é do plano gratuito, pois ele nao pode criar novos topicos */}
                        {JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false ?
                            <p className='mdl_blk'>A criação de um novo tópico não está disponível no plano <b>Free</b>.</p>
                        :
                            <>
                                <p className='mdl_blk'>Informe sua dúvida preenchendo os campos abaixo.</p>

                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <CustomTextField
                                                    required
                                                    label="Assunto" 
                                                    onChange={(e) => setTopic({...topic, subject: e.target.value})} 
                                                    autoComplete='off'
                                                    value={topic.subject}
                                                    name='subject'
                                                    {...(fieldsError.subject && {error: 'true'})}
                                                    {...((JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false) && {disabled: 'true'})}
                                                    size="medium"
                                                    fullWidth
                                                    inputProps={{ maxLength: 200 }}
                                                    InputLabelProps={{
                                                        sx: {
                                                        ...inputLabelStyles,
                                                        },
                                                    }}
                                                />

                                                <FormHelperText>
                                                    {fieldsError.subject}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <CustomTextField
                                                    required
                                                    label="Mensagem" 
                                                    onChange={(e) => setTopic({...topic, message: e.target.value})} 
                                                    autoComplete='off'
                                                    value={topic.message}
                                                    name='message'
                                                    {...((JSON.parse(localStorage?.getItem('user')).plan !== 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false) && {multiline: 'true'})}
                                                    maxRows={10}
                                                    {...(fieldsError.message && {error: 'true'})}
                                                    {...((JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false) && {disabled: 'true'})}
                                                    size="medium"
                                                    fullWidth
                                                    inputProps={{ maxLength: 2500 }}
                                                    InputLabelProps={{
                                                        sx: {
                                                        ...inputLabelStyles,
                                                        },
                                                    }}
                                                />

                                                <FormHelperText>
                                                    {fieldsError.message}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </>
                        }
                    </div>

                    <div className="modal_footer">
                        {JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false ?
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={() => redirectTo('/meu-perfil')}
                                        variant="outlined"
                                        size="medium"
                                        color="secondary"
                                        fullWidth
                                    >
                                        <span>Escolher novo Plano</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        :
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={() => handleCreateTopic()}
                                        endIcon={<ScheduleSendOutlinedIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                        {...((JSON.parse(localStorage?.getItem('user')).plan === 'Free' && Boolean(JSON.parse(localStorage?.getItem('user')).plan_downgrade) === false) && {disabled: 'true'})}
                                        fullWidth
                                        color="secondary"
                                        size="medium"
                                    >
                                        <span>Criar tópico</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </div>
            </div>

        </div> 
    )
}

export default ModalCreateTopic;